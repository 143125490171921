import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type Props = {
  translationKey: 'emptyState' | 'error';
};

export const EmptyList = ({ translationKey }: Props) => {
  const intl = useIntl();

  return (
    <Stack
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{ height: 250 }}
      data-cy="logs-empty"
    >
      <Typography variant="h5" color="text.primary">
        {intl.formatMessage({
          id: `containers.settings.extensions.logs.${translationKey}.title`,
        })}
      </Typography>
      <Typography variant="subtitle2" fontWeight={700} color="text.disabled">
        {intl.formatMessage({
          id: `containers.settings.extensions.logs.${translationKey}.text`,
        })}
      </Typography>
    </Stack>
  );
};
