import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import {
  setLeavingModalOpened,
  setShoulShowLeavingModal,
} from '../../../../../redux/modules/ui/actions';
import { State } from '../../../../../types/state';

/** @deprecated use LeavingDialog */
export const useLeavingModal = ({
  shouldShowLeavingModal,
}: {
  shouldShowLeavingModal: boolean;
}) => {
  const dispatch = useDispatch();
  const { isOpen, onLeave } = useSelector((state: State) => ({
    isOpen: state.ui.leavingModal.isOpen,
    onLeave: state.ui.leavingModal.onLeave,
  }));

  useEffect(() => {
    dispatch(setShoulShowLeavingModal(shouldShowLeavingModal));
  }, [dispatch, shouldShowLeavingModal]);

  return {
    isOpen,
    onLeave: () => {
      dispatch(setShoulShowLeavingModal(false));
      dispatch(setLeavingModalOpened(false, undefined));
      onLeave?.();
    },
    closeLeavingModal: () => {
      dispatch(setLeavingModalOpened(false, undefined));
    },
  };
};

export const useLeavingModalCallback = () => {
  const dispatch = useDispatch();
  const shouldShow = useSelector(
    (state: State) => state.ui.leavingModal.shouldShow
  );

  return (onLeave: () => void) => {
    if (shouldShow) {
      return dispatch(setLeavingModalOpened(true, { onLeave }));
    }
    return onLeave();
  };
};

export const useLeavingModalDispatch = () => {
  const dispatch = useDispatch();
  const callback = useLeavingModalCallback();
  return (onLeave: AnyAction) => callback(() => dispatch(onLeave));
};
