// Generated by ts-to-zod
import { z } from 'zod';
import { schemaRuleActionSchema } from '../../../rules/models/schemaRule.schema';
import { schemaSectionSchema } from '../../../schemas/models/schemaSection.schema';

export const suggestRulePayloadSchema = z.object({
  userQuery: z.string(),
  schemaContent: z.array(schemaSectionSchema),
});

export const suggestRuleResultSchema = z.object({
  description: z.string().optional(),
  enabled: z.boolean().optional(),
  id: z.string().optional(),
  triggerCondition: z.string().optional(),
  triggerConditionSummary: z.string().optional(),
  name: z.string().optional(),
  ruleActions: z.array(schemaRuleActionSchema.partial()).optional(),
});

export const suggestRuleResponseSchema = z.object({
  results: z.array(suggestRuleResultSchema),
});
