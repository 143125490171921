import * as R from 'remeda';

import { createTheme, ThemeOptions } from '../material';
import { commonThemeOptions } from './common';
import { muiAlert } from './component-overrides/alert';
import { muiAutocomplete } from './component-overrides/autocomplete';
import { muiButton } from './component-overrides/button';
import { muiChip } from './component-overrides/chip';
import { muiCssBaseline } from './component-overrides/cssBaseline';
import { muiDrawer } from './component-overrides/drawer';
import { muiMenu, muiMenuItem } from './component-overrides/menu';
import { muiPaper } from './component-overrides/paper';
import { muiPopover } from './component-overrides/popover';
import {
  muiStepConnector,
  muiStepIcon,
  muiStepLabel,
  muiStepper,
} from './component-overrides/stepper';
import { muiTableCell } from './component-overrides/tableCell';
import { muiTableRow } from './component-overrides/tableRow';
import { muiSelect } from './component-overrides/textField';
import { muiTooltip } from './component-overrides/tooltip';
import { darkThemeOptions } from './dark';
import { lightThemeOptions } from './light';
import { muiDialog } from './component-overrides/dialog';

export const resolveTheme = (
  mode: 'light' | 'dark',
  customOptions?: ThemeOptions
) => {
  const resolvedOptions = R.pipe(
    commonThemeOptions as Record<string, unknown>,
    R.mergeDeep(
      (mode === 'dark' ? darkThemeOptions : lightThemeOptions) as Record<
        string,
        unknown
      >
    ),
    R.mergeDeep(customOptions as Record<string, unknown>)
  );

  const theme = createTheme(resolvedOptions);

  // TODO: `theme` is now available inside `styleOverrides`, these no longer need to be functions
  // TODO: ^^^ Rewrite this so it's better organized & type-safe
  theme.components = R.mergeDeep(
    (theme.components ?? {}) as Record<string, unknown>,
    {
      MuiCssBaseline: muiCssBaseline(theme),
      MuiSelect: muiSelect(theme),
      MuiAutocomplete: muiAutocomplete(theme),
      MuiChip: muiChip(theme),
      MuiButton: muiButton(theme),
      MuiDrawer: muiDrawer(theme),
      MuiPaper: muiPaper(theme),
      MuiStepper: muiStepper(theme),
      MuiStepLabel: muiStepLabel(theme),
      MuiStepIcon: muiStepIcon(theme),
      MuiStepConnector: muiStepConnector(theme),
      MuiTableCell: muiTableCell(theme),
      MuiTableRow: muiTableRow(theme),
      MuiTooltip: muiTooltip(theme),
      MuiMenu: muiMenu(theme),
      MuiMenuItem: muiMenuItem(theme),
      MuiPopover: muiPopover(theme),
      MuiAlert: muiAlert(theme),
      MuiDialog: muiDialog(theme),
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;

  // Adapted from https://www.figma.com/file/AaBwZVqcfaoe476bCiLqmJ/Rossum-Design-System-%E2%80%94-Dark?node-id=6785%3A43358
  // we need to do this here for accessing theme utilities like `pxToRem`
  // TODO: Resolve this better + document ^^
  theme.typography = R.mergeDeep(
    theme.typography as unknown as Record<string, unknown>,
    {
      h1: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(48),
        lineHeight: 1.21,
        letterSpacing: -0.25,
      },
      h2: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(40),
        lineHeight: 1.5,
        letterSpacing: -0.1,
      },
      h3: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(32),
        lineHeight: 1.31,
        letterSpacing: 0,
      },
      h4: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(24),
        lineHeight: 1.33,
        letterSpacing: 0.1,
      },
      h5: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(20),
        lineHeight: 1.3,
        letterSpacing: 0.15,
      },
      h6: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.37,
        letterSpacing: 0.2,
      },
      subtitle1: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.75,
        letterSpacing: 0.15,
      },
      subtitle2: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 1.57,
        letterSpacing: 0.2,
      },
      body1: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.56,
        letterSpacing: 0.15,
      },
      body2: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 1.57,
        letterSpacing: 0.15,
      },
      button: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(15),
        lineHeight: 1.73,
        letterSpacing: 0.3,
        textTransform: 'none',
      },
      caption: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1.5,
        letterSpacing: 0.4,
      },
      overline: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 2.66,
        letterSpacing: 1,
      },
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;

  return theme;
};
