import { SchemaRuleAction, SchemaSection } from '@rossum/api-client/schemas';
import {
  alpha,
  Box,
  Button,
  List,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { button } from '../../../lib/formaterValues';
import { ContentGroup } from '../../../ui/content-group/ContentGroup';
import { ActionForm } from './ActionForm';

type ThenSectionProps<T extends FieldValues> = {
  control: Control<T, unknown>;
  schemaContent: SchemaSection[];
};

export const ThenSection = <T extends { ruleActions: SchemaRuleAction[] }>({
  control: parentControl,
  schemaContent,
}: ThenSectionProps<T>) => {
  const intl = useIntl();

  // @ts-expect-error Parent control can have more fields but we will ignore them
  const control = parentControl as Control<
    { ruleActions: SchemaRuleAction[] },
    unknown
  >;

  const {
    fields: actions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'ruleActions',
  });

  const onAddAction = () => {
    append({
      id: uuidv4(),
      enabled: true,
      action: undefined,
    });
  };

  return (
    <ContentGroup
      isFullWidthLayout
      description={intl.formatMessage({
        id: 'features.queueSettings.rules.detail.then.description',
      })}
      title={intl.formatMessage({
        id: 'features.queueSettings.rules.detail.then.title',
      })}
    >
      {actions.length ? (
        <List
          dense
          sx={{
            py: 0,
            borderTop: theme => `1px solid ${theme.palette.divider}`,
          }}
        >
          {actions.map((action, index) => (
            <ActionForm
              remove={remove}
              control={control}
              key={action.id}
              action={action}
              index={index}
              schemaContent={schemaContent}
            />
          ))}
        </List>
      ) : (
        <Box
          p={2}
          textAlign="center"
          sx={{
            width: '100%',
            border: theme => `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 1,
            backgroundColor: theme => alpha(theme.palette.grey[500], 0.1),
          }}
        >
          <Stack sx={{ opacity: 0.7 }}>
            <Typography variant="h6">
              {intl.formatMessage({
                id: 'features.queueSettings.rules.detail.then.empty.title',
              })}
            </Typography>
            <Typography variant="body2">
              {intl.formatMessage(
                {
                  id: 'features.queueSettings.rules.detail.then.empty.description',
                },
                {
                  button: button(onAddAction),
                }
              )}
            </Typography>
          </Stack>
        </Box>
      )}
      <Stack direction="column" alignItems="flex-end">
        <Button variant="outlined" color="secondary" onClick={onAddAction}>
          {intl.formatMessage({
            id: 'features.queueSettings.rules.detail.then.addAction',
          })}
        </Button>
      </Stack>
    </ContentGroup>
  );
};
