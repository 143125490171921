import { camelCase } from 'lodash';
import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { defaultWorkspaceSorting } from '../../../constants/values';
import { convertKeys } from '../../../lib/keyConvertor';
import { GroupOrganizationUser } from '../../../types/groupOrganizationUser';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import { fetchOrganizationGroupOrganizationsFulfilled } from '../organization/actions';
import {
  fetchUserFulfilled,
  updateUiSettings,
  updateUser,
  updateUserPasswordRejected,
} from './actions';
import { excludeUserUISettingsKeysFromConversion } from './epics';

const initialState = Immutable<GroupOrganizationUser>({
  authType: undefined,
  dateJoined: undefined,
  email: '',
  firstName: '',
  groups: [],
  id: -1,
  isActive: false,
  lastLogin: undefined,
  lastName: '',
  oidcId: undefined,
  organization: '',
  pk: -1,
  queues: [],
  uiSettings: {
    dashboard: {
      workspacesSorting: defaultWorkspaceSorting,
    },
    productTours: {},
    showConfidenceScore: false,
  },
  url: '',
  username: '',
  timestamp: '',
  passwordErrors: {},
  organizationGroupOrganizations: [],
  deleted: false,
  phoneNumber: null,
  emailVerified: false,
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchUserFulfilled):
      return state.merge(action.payload, { deep: true });

    case getType(fetchMembershipTokenFulfilled):
    case getType(signOut):
      return initialState;

    case getType(updateUser):
      return state.merge(convertKeys(camelCase)(action.payload), {
        deep: true,
      });

    case getType(updateUiSettings):
      return state.update('uiSettings', uiSettings =>
        uiSettings.merge(
          convertKeys(
            camelCase,
            excludeUserUISettingsKeysFromConversion
          )(action.payload),
          {
            deep: true,
          }
        )
      );

    case getType(updateUserPasswordRejected):
      return state.set(
        'passwordErrors',
        convertKeys(camelCase)(action.payload)
      );

    case getType(fetchOrganizationGroupOrganizationsFulfilled): {
      const allGroupOrgs = [
        ...state.organizationGroupOrganizations,
        ...action.payload.results,
      ];

      return state.set('organizationGroupOrganizations', allGroupOrgs);
    }

    default:
      return state;
  }
};

export default reducer;
