// Generated by ts-to-zod
import { z } from 'zod';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const workspaceListQuerySchema = z
  .object({
    fields: z.array(z.string()),
    id: z.array(z.number()),
    ordering: z.array(z.string()),
    name: z.string(),
  })
  .partial()
  .and(paginationQuerySchema);
