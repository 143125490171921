import {
  isSchemaDatapoint,
  isSchemaDatapointButton,
  isSchemaSimpleMultiValue,
  isSchemaTableMultiValue,
  isSchemaTuple,
  SchemaItem,
  SchemaSection,
} from '@rossum/api-client/schemas';
import { getUITypeFromSchema } from '../../../../redux/modules/schema/helpers';
import { SchemaFieldIconType } from '../../../../ui/schema-field-icon/SchemaFieldIcon';

type SchemaItemAndSection = SchemaSection | SchemaItem;

// TODO: reuse logic from form transformers by big boy Bro'
export const resolveIconType = (
  schemaObject: SchemaItemAndSection
): SchemaFieldIconType | null => {
  if (isSchemaSimpleMultiValue(schemaObject)) {
    return 'multiValue';
  }

  if (isSchemaTableMultiValue(schemaObject)) {
    return 'lineItem';
  }

  // @ts-expect-error redux vs api-client type mismatch
  const uiConfigurationType = getUITypeFromSchema(schemaObject);

  if (uiConfigurationType) {
    return uiConfigurationType;
  }

  if (schemaObject.category === 'section' || isSchemaTuple(schemaObject)) {
    return null;
  }

  if (isSchemaDatapointButton(schemaObject)) {
    return 'button';
  }

  if (isSchemaDatapoint(schemaObject)) {
    return 'captured';
  }

  return null;
};

export const getSchemaIconTypeMap = <
  I extends { id: string; label: string },
  O extends (I & { children?: Array<O> }) | I,
>(
  obj: O | Array<O>
): Record<string, ReturnType<typeof resolveIconType>> => {
  if (Array.isArray(obj)) {
    return obj.reduce(
      (acc, value) => ({ ...acc, ...getSchemaIconTypeMap(value) }),
      {}
    );
  }

  const nested =
    'children' in obj && obj.children
      ? Array.isArray(obj.children)
        ? obj.children.reduce(
            (acc, value) => ({ ...acc, ...getSchemaIconTypeMap(value) }),
            {}
          )
        : getSchemaIconTypeMap(obj.children)
      : {};
  return {
    [obj.id]: resolveIconType(obj as SchemaItemAndSection),
    ...nested,
  };
};
