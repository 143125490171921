import { Typography, TypographyProps } from '@rossum/ui/material';
import { forwardRef } from 'react';

export const ExtensionText = forwardRef<
  HTMLParagraphElement,
  {
    primaryText: string;
    secondaryText: string;
    inTooltip?: boolean;
  } & TypographyProps
>(({ primaryText, secondaryText, inTooltip, sx, ...props }, ref) => (
  <Typography
    ref={ref}
    variant="body2"
    sx={{
      fontSize: 12,
      color: t => t.palette.text.secondary,
      span: {
        color: t => t.palette.text.primary,
      },
      ...sx,
    }}
    {...props}
  >
    {secondaryText} - <span>{primaryText}</span>
  </Typography>
));
