import { Paper, Typography } from '@rossum/ui/material';

export const KeyboardShortcutChip = ({ value }: { value: string }) => {
  return (
    <Paper
      elevation={8}
      sx={{
        boxShadow: 0,
        px: 0.5,
        borderRadius: '2px',
        border: '1px #ffffff50 solid',
      }}
    >
      <Typography variant="inherit">{value}</Typography>
    </Paper>
  );
};
