import { getIDFromUrl } from '@rossum/api-client';
import { EngineField } from '@rossum/api-client/engineFields';
import { Button, CircularProgress, Tooltip } from '@rossum/ui/material';
import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AutomationBreadcrumbs } from '../../../containers/Automation/AutomationBreadcrumbs';
import { automationEnginesPath } from '../../../containers/Automation/helpers';
import { restrictedEngineEditEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { DeleteConfirmationDialog } from '../../../ui/delete-confirmation-dialog/DeleteConfirmationDialog';
import { Header, HeaderProps } from '../../../ui/header/Header';
import { FIELD_FORM_ID } from '../constants';
import { useDeleteEngineField } from '../hooks/useDeleteEngineField';
import { useEngine } from '../hooks/useEngine';
import { useEngineFields } from '../hooks/useEngineFields';
import { engineDetailPath } from '../paths';

type FieldDetailHeaderProps = { field: EngineField; isSaving: boolean } & Pick<
  HeaderProps,
  'scrollableDivRef'
>;

export const FieldDetailHeader = ({
  field,
  isSaving,
  ...rest
}: FieldDetailHeaderProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const isRestrictedEngineEditEnabled = useSelector(
    restrictedEngineEditEnabledSelector
  );

  const intl = useIntl();

  const { mutate: deleteField, isLoading: isDeletingField } =
    useDeleteEngineField();

  const history = useHistory();

  const { data: unusedData } = useEngineFields({
    id: [field.id],
    used: false,
    pageSize: 1,
  });

  const deleteDisabled = !(unusedData && unusedData.pagination.total > 0);

  const engineId = getIDFromUrl(field.engine);
  const { data } = useEngine(engineId);

  return (
    <Header
      {...rest}
      breadcrumbs={
        <AutomationBreadcrumbs
          breadcrumbs={[
            {
              label: intl.formatMessage({
                id: 'components.appBar.menu.automation.aiEngines',
              }),
              to: automationEnginesPath(),
            },
            {
              to: engineDetailPath(engineId),
              label: data?.name ?? `${engineId}`,
            },
            {
              label: field.label,
            },
          ]}
        />
      }
      title={field.label}
      description={field.name}
      buttons={
        isRestrictedEngineEditEnabled
          ? []
          : [
              <Fragment key="delete_field">
                <Tooltip
                  title={
                    deleteDisabled
                      ? intl.formatMessage({
                          id: 'features.engines.header.buttons.deleteField.disabledTooltip',
                        })
                      : ''
                  }
                >
                  <span>
                    <Button
                      variant="outlined"
                      color="secondary"
                      data-cy="delete-button"
                      disabled={deleteDisabled || isDeletingField}
                      onClick={() => setDeleteDialogOpen(true)}
                      startIcon={
                        isDeletingField && (
                          <CircularProgress color="inherit" size={20} />
                        )
                      }
                    >
                      {intl.formatMessage({
                        id: 'features.engines.header.buttons.deleteField',
                      })}
                    </Button>
                  </span>
                </Tooltip>
                <DeleteConfirmationDialog
                  title={intl.formatMessage({
                    id: `features.engines.dialog.deleteEngineField.title`,
                  })}
                  description={intl.formatMessage({
                    id: `features.engines.dialog.deleteEngineField.text`,
                  })}
                  open={deleteDialogOpen}
                  onCancel={() => setDeleteDialogOpen(false)}
                  onConfirm={() => {
                    setDeleteDialogOpen(false);
                    deleteField(field.id, {
                      onSuccess: () =>
                        history.push(
                          engineDetailPath(getIDFromUrl(field.engine))
                        ),
                    });
                  }}
                />
              </Fragment>,
              <Button
                key="save_field"
                variant="contained"
                type="submit"
                form={FIELD_FORM_ID}
                startIcon={
                  isSaving && <CircularProgress color="inherit" size={20} />
                }
                data-cy="submit-form"
                disabled={isSaving}
              >
                {intl.formatMessage({
                  id: 'features.engines.header.buttons.save',
                })}
              </Button>,
            ]
      }
      onBackButtonClicked={() =>
        history.push(engineDetailPath(getIDFromUrl(field.engine)))
      }
    />
  );
};
