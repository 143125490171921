import { Components, Theme } from '../../material';

export const muiPopover = (_theme: Theme): Components['MuiPopover'] => ({
  defaultProps: {
    slotProps: {
      paper: {
        elevation: 6,
      },
    },
  },
  styleOverrides: {},
});
