import { Components, Theme } from '../../material';

export const muiMenu = (_theme: Theme): Components['MuiMenu'] => ({
  defaultProps: {
    slotProps: {
      paper: {
        elevation: 6,
      },
    },
  },
  styleOverrides: {},
});

export const muiMenuItem = (_theme: Theme): Components['MuiMenuItem'] => ({
  styleOverrides: {},
});
