import { get, includes, isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { defaultWorkspaceSorting } from '../../../constants/values';
import createDeepEqualSelector from '../../../lib/createDeepEqualSelector';
import { GroupRole } from '../../../types/group';
import { ProductTourName } from '../../../types/productTour';
import { State } from '../../../types/state';
import { FooterLayout, SortFooterColumns } from '../../../types/user';
import { getRoleName, isApproverRole, roleNames } from '../groups/helpers';
import { organizationSelector } from '../organization/selectors';

export const userSelector = (state: State) => state.user;
export const groupSelector = (state: State) => state.groups;
export const firstTimeUserSelector = (state: State) =>
  isEqual(state.user.uiSettings.hints, {}) &&
  !state.user.uiSettings.onboardingAcknowledged;

export const displayExtensionsIntroBannerSelector = (state: State) =>
  !state.user.uiSettings.hideExtensionsIntroBanner;

export const displayDAGIntroBannerSelector = (state: State) =>
  !state.user.uiSettings.hideDagIntroBanner;

export const displayFirstUploadWarningSelector = (state: State) =>
  !state.user.uiSettings.hideFirstUploadWarning;

export const allDocumentsDashboardCustomizationSelector = (state: State) =>
  state.user.uiSettings.documentsDashboard;

export const confidenceIndicatorEnabledSelector = (state: State) =>
  state.user.uiSettings.showConfidenceScore;

export const userRoleNameSelector = createDeepEqualSelector(
  userSelector,
  groupSelector,
  (user, groups) => getRoleName(get(user, 'groups'), groups)
);

export const isUserViewer = (state: State) =>
  isUserOfRoleSelector(state)('viewer') || isUserRoleUnknownSelector(state);

export const isUserAdmin = (state: State) =>
  isUserOfRoleSelector(state)('admin') ||
  isUserOfRoleSelector(state)('organization_group_admin');

export const isUserManager = (state: State) =>
  isUserOfRoleSelector(state)('manager');

export const isUserAnnotatorBetaSelector = (state: State) =>
  isUserOfRoleSelector(state)('annotator_limited');

export const sortingSelector = (state: State) =>
  state.user.uiSettings.dashboard.workspacesSorting || defaultWorkspaceSorting;

export const isUserOfRoleSelector = createSelector(
  userSelector,
  groupSelector,
  (user, groups) => (desiredGroup: GroupRole) =>
    groups
      .filter(group => user.groups.includes(group.url))
      .some(userGroup => userGroup.name === desiredGroup)
);

const isUserRoleUnknownSelector = createSelector(
  userRoleNameSelector,
  (roleName: string) => !includes(roleNames, roleName)
);

export const tourResultSelector =
  (tourName: ProductTourName) => (state: State) =>
    state.user.uiSettings?.productTours?.[tourName]?.result ?? null;

// support access or membership access - user from different organization
export const userIsSupportSelector = createSelector(
  userSelector,
  organizationSelector,
  (user, organization) => {
    return user.organization !== organization.url;
  }
);

export const canUserPurgeSelector = createSelector(
  isUserAdmin,
  isUserAdmin => isUserAdmin
);

export const canUserApproveSelector = createSelector(
  isUserAdmin,
  userSelector,
  groupSelector,
  (isUserAdmin, user, group) =>
    isApproverRole(user.groups, group) || isUserAdmin
);

export const footerLayoutSelector = (state: State): FooterLayout => {
  const value = state.user?.uiSettings.footerLayout;
  return value ?? 'table-per-page';
};

export const sortFooterColumnsSelector = createSelector(
  footerLayoutSelector,
  (state: State) => state.user?.uiSettings.sortFooterColumns,
  (layout, sorting): SortFooterColumns =>
    // handle previous state without sorting based on layout preference
    sorting === undefined
      ? layout === 'table-per-page'
        ? 'automatically'
        : 'queue-settings'
      : sorting
);
