import { SchemaAction } from '@rossum/api-client/schemas';
import { Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

export const ActionCell = ({
  actions,
  renderAction,
}: {
  actions: SchemaAction[];
  renderAction?: (action: SchemaAction) => ReactNode;
}) => {
  return (
    <Stack direction="row" spacing={1} width="100%">
      {renderAction ? actions.map(renderAction) : null}
    </Stack>
  );
};
