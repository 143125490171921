import { State } from '../../../../types/state';
import { addValueActiveSelector } from '../../router/selectors';
import { sortFooterColumnsSelector } from '../../user/selectors';
import {
  areDatapointsValidSelector,
  currentDatapointSelector,
  getGridsSelector,
  hiddenSchemaDictSelector,
  messagesSelector,
  schemaSelector,
} from '../selector';
import { findNextNavigationStop } from './datapointNavigation';
import { defaultOrderBackward, defaultOrderForward } from './defaultOrder';
import { gridColumnOrderBackward, gridColumnOrderForward } from './gridOrder';
import {
  isButtonDatapoint,
  isSkippedAddValue,
  NavigationStop,
  toNavigationStop,
} from './navigationStop';
import { resolveAnyDatapointPath } from './resolvedDatapointPath';
import { isUnvalidated } from './utils';

export const currentNavigationStopSelector = (state: State) =>
  toNavigationStop(
    resolveAnyDatapointPath(
      state.datapoints.content,
      currentDatapointSelector(state)
    ),
    addValueActiveSelector(state)
  );

export const nextNavigationStopSelector =
  (state: State) =>
  ({
    currentStop,
    isForward,
    // this is `true` when navigating with `Enter`, false when with `Tab`
    // likely meaning is "skip to next unvalidated"
    unvalidated,
    skipButtons,
  }: {
    currentStop: NavigationStop;
    isForward: boolean;
    unvalidated: boolean;
    skipButtons: boolean;
  }): NavigationStop => {
    const hiddenDict = hiddenSchemaDictSelector(state);
    const messages = messagesSelector(state);
    const isAnnotationValid = areDatapointsValidSelector(state);
    const schema = schemaSelector(state);
    const getGrids = getGridsSelector(state);

    const sortFooterColumns = sortFooterColumnsSelector(state);

    // Hopefully sensible default?
    if (!schema) {
      return {
        kind: 'none',
      };
    }

    const stepInOrder =
      sortFooterColumns === 'queue-settings'
        ? isForward
          ? defaultOrderForward
          : defaultOrderBackward
        : isForward
          ? gridColumnOrderForward
          : gridColumnOrderBackward;

    const next = findNextNavigationStop(
      currentStop,
      {
        datapoints: state.datapoints.content,
        isAnnotationValid,
        schema,
        getGrids,
      },
      {
        stepInOrder,
        shouldSkip: stop => {
          const isDatapointHidden = stop.path[1].hidden;
          const lastDatapointInPath = stop.path[stop.path.length - 1];
          const isHiddenBySchema = hiddenDict[lastDatapointInPath.schemaId];
          const isHidden = isDatapointHidden ?? isHiddenBySchema;

          const isSkippedButton = skipButtons && isButtonDatapoint(stop);
          const isSkippedValidatedDatapoint =
            unvalidated && !isUnvalidated(stop, messages);

          return (
            isSkippedAddValue(stop, state) ||
            isSkippedButton ||
            isHidden ||
            isSkippedValidatedDatapoint
          );
        },

        shouldWrap: () => !isAnnotationValid,
      }
    );

    return next;
  };
