import { Message } from '@rossum/api-client/shared';
import { Stack, styled } from '@rossum/ui/material';
import clsx from 'clsx';
import equal from 'fast-deep-equal/es6/react';
import { createRef, PureComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { fromEvent, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter } from 'rxjs/operators';
import AnnotationInformation from '../../components/AnnotationInformation';
import {
  LastInteractionT,
  scrollToDatapoint,
} from '../../components/Datapoint';
import { snakeToCamel } from '../../lib/keyConvertor';
import { when } from '../../lib/streams';
import { hasDataAvailable } from '../../redux/modules/annotation/helpers';
import { annotationSelector } from '../../redux/modules/annotation/selectors';
import { selectDatapoint } from '../../redux/modules/datapoints/actions';
import {
  datapointPathSelector,
  firstDatapointMessageSelector,
  getVisibleSections,
} from '../../redux/modules/datapoints/selector';
import { organizationSelector } from '../../redux/modules/organization/selectors';
import { Annotation, ModifierType } from '../../types/annotation';
import {
  AnyDatapointDataST,
  MatchedTriggerRulesPerLevel,
  SectionDatapointDataST,
} from '../../types/datapoints';
import { State, State as StateT } from '../../types/state';
import { Workspace } from '../../types/workspace';
import { DrawerConfig } from '../DocumentValidation/ValidationEmailDrawer';
import Footer from './components/Footer';
import Middle from './components/Middle';
import MiddleSkeletonWrapper from './components/MiddleSkeletonWrapper';
import UnableToAnnotate from './components/UnableToAnnotate';
import styles from './style.module.sass';

const MiddleContainer = styled(Stack)({
  overflowX: 'hidden',
  overflowY: 'auto',
  position: 'relative',
  scrollBehavior: 'smooth',
  flex: '1 1 auto',
  pb: '70px',
});

type StateProps = {
  annotation: Annotation | undefined;
  backLink: string;
  datapointMessage?: Message | null;
  datapointPath: Array<number>;
  datapoints: AnyDatapointDataST[];
  editingDatapointValue: boolean;
  fieldAutomationBlockersVisible: boolean;
  messages: {
    [key: number]: Message;
    all?: Message;
  };
  matchedTriggerRules: MatchedTriggerRulesPerLevel;
  modifier: ModifierType;
  readOnly: boolean;
  searchPanelShown: boolean;
  sections: SectionDatapointDataST[];
  showModifier: boolean;
  workspacesList: Workspace[];
};

type LocalState = {
  lastInteractionState: LastInteractionT;
};

type OwnProps = {
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
};

type Props = StateProps & DispatchProp & OwnProps;

class Sidebar extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      lastInteractionState: 'navigation',
    };
  }

  // TODO: Refactor this so it doesn't use refTracker
  currentDatapointRef: HTMLElement | null = null;

  scrollableRef = createRef<HTMLDivElement>();

  initScrollSubscription: Subscription | null = null;

  wheelObserver: Observable<Event> | null = null;

  mouseDownObserver: Observable<MouseEvent> | null = null;

  mouseUpObserver: Observable<Event> | null = null;

  keydownTabUpObserver: Observable<Event> | null = null;

  scrollDownWithMouseSubscription: Subscription | null = null;

  wheelObserverMouseSubscription: Subscription | null = null;

  scrollToDatapointSubscription: Subscription | null = null;

  handleLastInteraction = (lastInteraction: LastInteractionT) => {
    this.setState({ lastInteractionState: lastInteraction });
  };

  componentDidMount() {
    this.keydownTabUpObserver = fromEvent<KeyboardEvent>(window, 'keydown');

    if (this.scrollableRef.current) {
      this.wheelObserver = fromEvent(this.scrollableRef.current, 'wheel', {
        // We don't call preventDefault(), so the browser can optimize scrolling
        passive: true,
      });
      this.mouseDownObserver = fromEvent<MouseEvent>(
        this.scrollableRef.current,
        'mousedown'
      );
      this.mouseUpObserver = fromEvent(this.scrollableRef.current, 'mouseup');

      // Allow sticky when user is scrolling with SCROLL BAR
      this.scrollDownWithMouseSubscription = this.mouseDownObserver
        .pipe(
          filter(e => {
            const target = e.target as HTMLDivElement;
            return e.offsetX > target.clientWidth;
          })
        )
        .subscribe(() => this.handleLastInteraction('scroll'));

      // Allow sticky when user is scrolling with MOUSE WHEEL
      this.wheelObserverMouseSubscription = this.wheelObserver.subscribe(() =>
        this.handleLastInteraction('scroll')
      );
    }

    this.initScrollSubscription = when(
      () => !!this.scrollableRef.current && !!this.currentDatapointRef,
      50
    ).subscribe(() => this.scrollToCurrentDatapoint());

    this.scrollToDatapointSubscription = scrollToDatapoint.subscribe(() =>
      this.scrollToCurrentDatapoint()
    );
  }

  componentDidUpdate(prevProps: Props) {
    // Remove sticky when datapoint was changed (possible only via mouse click or keyboard)
    // Second item of datapointPath array is related to datapoint
    if (prevProps.datapointPath[1] !== this.props.datapointPath[1]) {
      this.handleLastInteraction('navigation');
    }
  }

  componentWillUnmount() {
    if (this.initScrollSubscription) this.initScrollSubscription.unsubscribe();
    if (this.scrollToDatapointSubscription)
      this.scrollToDatapointSubscription.unsubscribe();

    if (this.scrollDownWithMouseSubscription)
      this.scrollDownWithMouseSubscription.unsubscribe();

    if (this.wheelObserverMouseSubscription)
      this.wheelObserverMouseSubscription.unsubscribe();
  }

  scrollToCurrentDatapoint() {
    if (this.scrollableRef.current && this.currentDatapointRef) {
      this.scrollableRef.current.scrollTop =
        this.currentDatapointRef.offsetTop - 100;
    }
  }

  selectDatapointWithMessage = () => {
    const { datapointMessage, datapointPath } = this.props;
    if (!datapointMessage) return false;

    const datapointId = Number(datapointMessage.id);

    if (datapointPath.includes(datapointId)) this.scrollToCurrentDatapoint();
    return this.props.dispatch(selectDatapoint([datapointId]));
  };

  render() {
    const {
      annotation,
      backLink,
      datapoints,
      editingDatapointValue,
      fieldAutomationBlockersVisible,
      messages,
      matchedTriggerRules,
      modifier,
      readOnly,
      searchPanelShown,
      sections,
      showModifier,
      workspacesList,
    } = this.props;

    const isScrollable = !!(
      this.scrollableRef.current &&
      this.scrollableRef.current.offsetHeight <
        this.scrollableRef.current.scrollHeight
    );

    const hasAvailableData = (annotation: Annotation) =>
      hasDataAvailable({
        status: snakeToCamel(annotation.status),
        pages: annotation.pages,
        restrictedAccess: annotation.restrictedAccess,
      });

    return (
      <div className={styles.Sidebar}>
        <AnnotationInformation
          backLink={backLink}
          messages={annotation?.messages}
          modifier={modifier}
          annotation={annotation}
          showModifier={showModifier}
          key={annotation?.id}
          matchedTriggerRules={matchedTriggerRules}
          workspacesList={workspacesList}
          currentDatapointRef={null}
        />
        <MiddleContainer
          data-tourstep="dataCaptureProductTour-sidebar"
          // TODO: Get rid of SASS outside of hotfix
          className={clsx(
            styles.Middle,
            isScrollable && styles.MiddleWithExtraSpace
          )}
          ref={this.scrollableRef}
        >
          <MiddleSkeletonWrapper
            datapointsAreReady={
              datapoints.length > 0 ||
              (!!annotation &&
                (annotation.status === 'failed_import' ||
                  annotation.restrictedAccess))
            }
          >
            {!annotation || hasAvailableData(annotation) ? (
              <Middle
                datapoints={datapoints}
                editingDatapointValue={editingDatapointValue}
                messages={messages}
                matchedTriggerRules={matchedTriggerRules}
                readOnly={readOnly}
                searchPanelShown={searchPanelShown}
                sections={sections}
                setCurrentDatapointRef={(ref: HTMLElement) => {
                  this.currentDatapointRef = ref;
                }}
                lastInteraction={this.state.lastInteractionState}
                handleLastInteraction={this.handleLastInteraction}
              />
            ) : (
              <UnableToAnnotate
                // @ts-expect-error Should have correct status because of hasAvailableData
                status={snakeToCamel(annotation.status)}
                annotationMessages={annotation.messages}
                restrictedAccess={annotation.restrictedAccess}
              />
            )}
          </MiddleSkeletonWrapper>
        </MiddleContainer>
        <Footer
          fieldAutomationBlockersVisible={fieldAutomationBlockersVisible}
          readOnly={readOnly}
          selectDatapointWithMessage={this.selectDatapointWithMessage}
          shouldRenderGradient={isScrollable}
          onEmailThreadOpen={this.props.onEmailThreadOpen}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: StateT): StateProps => {
  const {
    datapoints: { messages, content: datapoints, matchedTriggerRules },
  } = state;
  const annotation = annotationSelector(state);

  // show modifier name always when available, except in the case when user turned on
  // the read only mode during review === automation blockers mode
  const showModifier =
    state.ui.readOnly && annotation && annotation.status === 'reviewing'
      ? state.annotation.sideloads.modifier?.user?.url !== state.user.url
      : !!state.annotation.sideloads.modifier?.user?.username;

  return {
    annotation,
    backLink: state.router.location.pathname,
    datapointMessage: firstDatapointMessageSelector(state),
    datapointPath: datapointPathSelector(state),
    workspacesList: state.workspaces.list,
    datapoints,
    editingDatapointValue: state.ui.editingDatapointValue,
    fieldAutomationBlockersVisible: state.ui.fieldAutomationBlockersVisible,
    matchedTriggerRules,
    messages,
    modifier: state.annotation.sideloads.modifier,
    readOnly: state.ui.readOnly,
    searchPanelShown: state.search.shouldShow,
    sections: getVisibleSections(state),
    showModifier,
  };
};

export default connect<StateProps, DispatchProp, OwnProps, State>(
  mapStateToProps,
  null,
  null,
  {
    areStatesEqual: (next, prev) =>
      next.workspaces.list.length === prev.workspaces.list.length &&
      next.annotation.id === prev.annotation.id &&
      next.annotation.sideloads.document?.id ===
        prev.annotation.sideloads.document?.id &&
      prev.datapoints.updatedTimestamp === next.datapoints.updatedTimestamp &&
      prev.datapoints.sidebarDatapointUpdatedTimestamp ===
        next.datapoints.sidebarDatapointUpdatedTimestamp &&
      prev.datapoints.content.length === next.datapoints.content.length &&
      prev.datapoints.initiallyValidated ===
        next.datapoints.initiallyValidated &&
      equal(prev.datapoints.messages, next.datapoints.messages) &&
      equal(
        prev.datapoints.matchedTriggerRules,
        next.datapoints.matchedTriggerRules
      ) &&
      next.stack.length === prev.stack.length &&
      next.ui.readOnly === prev.ui.readOnly &&
      next.ui.fieldAutomationBlockersVisible &&
      prev.ui.fieldAutomationBlockersVisible &&
      equal(
        organizationSelector(next).uiSettings?.features,
        organizationSelector(prev).uiSettings?.features
      ),
  }
)(Sidebar);
