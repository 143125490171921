import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { SchemaSection } from '@rossum/api-client/schemas';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';
import { FORMULA_PREVIEW_KEY } from '../../keys';

export const useCalculatePreview = (
  annotationUrls: Url[],
  schemaContent: SchemaSection[],
  disabled?: boolean
) => {
  const queryClient = useQueryClient();

  const queries = annotationUrls.map(annotationUrl => {
    const queryKey = [
      FORMULA_PREVIEW_KEY,
      'annotation-content',
      annotationUrl,
      schemaContent,
    ] as const;

    return {
      queryKey,
      queryFn: async () => {
        const { results: annotationContent } = await api.request(
          endpoints.annotations.getContent(getIDFromUrl(annotationUrl))
        );
        return api
          .request(
            endpoints.internal.schemas.evaluateFormulas({
              annotationContent,
              schemaContent,
            })
          )
          .then(response => ({
            // adding the annotation id to the response for easier matching
            ...response,
            annotationUrl,
          }));
      },
      enabled: schemaContent.length > 0 && !disabled,
      cacheTime: 0, // clears data on closing drawer,
      onError: () => {
        // since we do not have whole schema content object in query key,
        // we need to invalidate it manually by setting annotationContent to empty array
        queryClient.setQueryData(queryKey, <O>(prev: O) => ({
          ...prev,
          annotationContent: [],
        }));
      },
    };
  });

  return useQueries({ queries });
};
