import { SchemaAction } from '@rossum/api-client/schemas';
import { VisibilityOffOutlined, VisibilityOutlined } from '@rossum/ui/icons';
import { Autocomplete, TextField } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { getSchemaFields } from '../../formulas/FormulaEditor/helpers';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const visibilityActionPayloadSchema = z.object({
  schema_id: z.string().default(''),
});

const VisibilityActionForm = createForm(
  ({ value, onChange, schemaContent }) => {
    const intl = useIntl();
    const schemaFieldOptions = useMemo(
      () => getSchemaFields(schemaContent),
      [schemaContent]
    );
    const schemaField = useMemo(
      () =>
        schemaFieldOptions.find(f => f.id === value.schema_id) ??
        schemaFieldOptions[0],
      [schemaFieldOptions, value.schema_id]
    );

    return (
      <Autocomplete
        size="small"
        options={schemaFieldOptions}
        getOptionLabel={option => `${option.label} (${option.id})`}
        value={schemaField}
        onChange={(_e, v) => {
          if (v) {
            onChange({ ...value, schema_id: v.id });
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'features.queueSettings.rules.actions.visibility.form.fields',
            })}
            placeholder={intl.formatMessage({
              id: 'features.queueSettings.rules.actions.visibility.form.chooseFields',
            })}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    );
  },
  visibilityActionPayloadSchema
);

const VisibilityActionHeader = createHeader(
  ({ value }) => (
    <ActionHeader
      entries={[
        {
          key: 'schema_id',
          value: value.schema_id,
        },
      ]}
    />
  ),
  visibilityActionPayloadSchema
);

const showFieldAction = {
  icon: VisibilityOutlined,
  intlKey: 'showField' as const,
  resolveAction: (a: SchemaAction) => a.type === 'show_field',
  serialized: { type: 'show_field' },
  groupIntlKey: 'visibility' as const,
  form: VisibilityActionForm,
  header: VisibilityActionHeader,
};

const hideFieldAction = {
  icon: VisibilityOffOutlined,
  intlKey: 'hideField' as const,
  resolveAction: (a: SchemaAction) => a.type === 'hide_field',
  serialized: { type: 'hide_field' },
  groupIntlKey: 'visibility' as const,
  form: VisibilityActionForm,
  header: VisibilityActionHeader,
};

export const visibilityActions = [showFieldAction, hideFieldAction];
