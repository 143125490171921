import { SchemaRuleAction } from '@rossum/api-client/schemas';
import {
  IconChevronDown,
  IconChevronUp,
  IconToggleLeft,
  IconToggleRight,
  IconTrash,
} from '@rossum/ui/icons/tabler';
import { IconButton, Stack, SvgIcon } from '@rossum/ui/material';
import { Control, Controller } from 'react-hook-form';

type ActionFormActionsProps = {
  index: number;
  control: Control<{ ruleActions: SchemaRuleAction[] }, unknown>;
  remove: (index: number) => void;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>> | undefined;
};

export const ActionFormActions = ({
  control,
  remove,
  index,
  expanded,
  setExpanded,
}: ActionFormActionsProps) => {
  return (
    <Stack direction="row" spacing={1}>
      <IconButton size="small" onClick={() => remove(index)}>
        <SvgIcon>
          <IconTrash />
        </SvgIcon>
      </IconButton>
      <Controller
        control={control}
        name={`ruleActions.${index}.enabled`}
        render={({ field: { value, onChange } }) => (
          <IconButton size="small" onClick={() => onChange(!value)}>
            {value ? (
              <SvgIcon color="success">
                <IconToggleRight />
              </SvgIcon>
            ) : (
              <SvgIcon>
                <IconToggleLeft />
              </SvgIcon>
            )}
          </IconButton>
        )}
      />
      <IconButton
        size="small"
        disabled={setExpanded === undefined}
        onClick={() => setExpanded?.(expanded => !expanded)}
      >
        <SvgIcon>{expanded ? <IconChevronUp /> : <IconChevronDown />}</SvgIcon>
      </IconButton>
    </Stack>
  );
};
