import { ThemeOptions } from '@mui/material';

export const commonThemeOptions: ThemeOptions = {
  palette: {
    other: {
      muted: '#403e46',
    },
    grey: {
      50: '#FFFFFF',
      100: '#F8F8F8',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#24252B',
      800: '#151519',
      900: '#000000',
    },
  },
  shape: {
    borderRadius: 4,
    borders: {
      small: 4,
      medium: 8,
      large: 12,
      full: 240,
    },
  },
};
