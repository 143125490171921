import { ExtensionEvent } from '@rossum/api-client/hooks';
import { Chip, Stack, Tooltip } from '@rossum/ui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { convertEvents, getExtensionChipText } from '../../../lib/helpers';
import eventBadgesStyles from '../../../style.module.sass';
import { ExtensionText } from './ExtensionText';
import ExtensionTextWithTooltip from './ExtensionTextWithTooltip';

type Props = {
  events: Array<ExtensionEvent>;
  id: string | number;
  displayPlaceholder?: boolean;
};

const EventsBadges = ({ events = [], id, displayPlaceholder }: Props) => {
  const intl = useIntl();

  if (events.length === 0) {
    return displayPlaceholder ? (
      <div className={eventBadgesStyles.EventsPlaceholder}>
        <FormattedMessage id="containers.settings.extensions.events.placeholder" />
      </div>
    ) : null;
  }

  const convertedEvents = convertEvents(events);
  const eventValues = Object.values(convertedEvents);
  const [firstEvent, ...restOfEvents] = eventValues;

  const { eventsText, actionsText } = getExtensionChipText({
    event: firstEvent.name,
    actions: firstEvent.actions,
    intl,
  });

  return (
    <Stack
      direction="row"
      alignItems="center"
      px={1}
      spacing={1}
      sx={{ maxWidth: 'calc(100% - 24px)' }}
    >
      <Chip
        size="tiny"
        label={
          <ExtensionTextWithTooltip
            secondaryText={eventsText}
            primaryText={actionsText}
          />
        }
        sx={{ minWidth: 0 }}
      />
      {!!restOfEvents.length && (
        <Tooltip
          placement="top"
          disableInteractive
          title={restOfEvents.map(({ actions, name }) => {
            const {
              eventsText: tooltipEventsText,
              actionsText: tooltipActionsText,
            } = getExtensionChipText({
              event: name,
              actions,
              intl,
            });

            return (
              <ExtensionText
                key={`${name}-${id}`}
                primaryText={tooltipActionsText}
                secondaryText={tooltipEventsText}
              />
            );
          })}
        >
          <Chip label={`+${restOfEvents.length}`} size="tiny" />
        </Tooltip>
      )}
    </Stack>
  );
};

export default EventsBadges;
