import { create } from './create';
import { deleteEndpoint } from './delete';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';

export const rules = {
  get,
  create,
  list,
  patch,
  delete: deleteEndpoint,
};
