import { Button, Dialog, DialogContent, Stack } from '@rossum/ui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  automaticSuggestionsEnabledSelector,
  complexLineItemsEnabledSelector,
  complexTablesEnabledOnOrganization,
} from '../../../redux/modules/ui/selectors';
import { updateUiSettings } from '../../../redux/modules/user/actions';
import {
  footerLayoutSelector,
  sortFooterColumnsSelector,
} from '../../../redux/modules/user/selectors';
import DialogTitle from '../../../ui/dialog-title/DialogTitle';
import ConditionalAuroraChip from '../../conditional-aurora-chip/ConditionalAuroraChip';
import { LineItemSettingsControl } from './LineItemSettingsControl';

const annotationMethodTypes = [
  'annotationMethod.gridDesign',
  'annotationMethod.suggestions',
] as const;

const showSuggestionsTypes = [
  'showSuggestions.automatically',
  'showSuggestions.onDemand',
] as const;

const sortFooterColumnsTypes = [
  'sortColumns.automatically',
  'sortColumns.queueSettings',
] as const;

const footerLayoutTypes = [
  'footerLayout.table-per-page',
  'footerLayout.one-table',
] as const;

type ValueType = (
  | typeof annotationMethodTypes
  | typeof showSuggestionsTypes
  | typeof sortFooterColumnsTypes
  | typeof footerLayoutTypes
)[number];

type FormValues = {
  annotationMethod: (typeof annotationMethodTypes)[number];
  showSuggestions: (typeof showSuggestionsTypes)[number];
  sortFooterColumns: (typeof sortFooterColumnsTypes)[number];
  footerLayout: (typeof footerLayoutTypes)[number];
};

const getOption = <T extends ValueType>(intl: IntlShape, value: T) => ({
  title: intl.formatMessage({
    id: `components.lineItemsSettings.${value}.title`,
  }),
  description: intl.formatMessage({
    id: `components.lineItemsSettings.${value}.description`,
  }),
  value,
});

type LineItemSettingsDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const LineItemSettingsDialog = ({
  open,
  onClose,
}: LineItemSettingsDialogProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cliEnabled = useSelector(complexTablesEnabledOnOrganization);

  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);
  const automaticSuggestionsEnabled = useSelector(
    automaticSuggestionsEnabledSelector
  );
  const footerLayout = useSelector(footerLayoutSelector);
  const sortFooterColumns = useSelector(sortFooterColumnsSelector);

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      annotationMethod: complexLineItemsEnabled
        ? 'annotationMethod.suggestions'
        : 'annotationMethod.gridDesign',
      showSuggestions: automaticSuggestionsEnabled
        ? 'showSuggestions.automatically'
        : 'showSuggestions.onDemand',
      footerLayout:
        footerLayout === 'table-per-page'
          ? 'footerLayout.table-per-page'
          : 'footerLayout.one-table',
      sortFooterColumns:
        sortFooterColumns === 'automatically'
          ? 'sortColumns.automatically'
          : 'sortColumns.queueSettings',
    },
  });

  const annotationMethodOptions = annotationMethodTypes.map(type => {
    const option = getOption(intl, type);

    // custom title for complex tables
    if (type === 'annotationMethod.suggestions') {
      return {
        ...option,
        chip: <ConditionalAuroraChip size="small" />,
      };
    }
    return option;
  });

  const showSuggestionsOptions = showSuggestionsTypes.map(type =>
    getOption(intl, type)
  );

  const sortColumnsOptions = sortFooterColumnsTypes.map(type =>
    getOption(intl, type)
  );

  const footerLayoutOptions = footerLayoutTypes.map(type =>
    getOption(intl, type)
  );

  const onSubmit = (values: FormValues) => {
    const newFooterLayout =
      values.footerLayout === 'footerLayout.table-per-page'
        ? 'table-per-page'
        : 'one-table';
    const newSortFooterColumns =
      values.sortFooterColumns === 'sortColumns.automatically'
        ? 'automatically'
        : 'queue-settings';

    const newComplexLineItems =
      values.annotationMethod === 'annotationMethod.suggestions';

    const newAutomaticSuggestions =
      values.showSuggestions === 'showSuggestions.automatically';

    dispatch(
      updateUiSettings({
        footerLayout: newFooterLayout,
        sortFooterColumns: newSortFooterColumns,
        automaticSuggestions: newAutomaticSuggestions,

        // To prevent reload.
        ...(newComplexLineItems !== complexLineItemsEnabled
          ? { complexLineItems: newComplexLineItems }
          : {}),
      })
    );
    onClose();
  };

  const values = useWatch({
    control,
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        title={intl.formatMessage({ id: 'components.lineItemsSettings.title' })}
        onClose={onClose}
      />
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={3} spacing={2}>
            {cliEnabled && (
              <Controller
                control={control}
                name="annotationMethod"
                render={({ field }) => (
                  <LineItemSettingsControl
                    name={intl.formatMessage({
                      id: 'components.lineItemsSettings.annotationMethod.title',
                    })}
                    id="annotation-method"
                    options={annotationMethodOptions}
                    field={field}
                  />
                )}
              />
            )}
            {cliEnabled && (
              <Controller
                control={control}
                name="showSuggestions"
                render={({ field }) => (
                  <LineItemSettingsControl
                    name={intl.formatMessage({
                      id: 'components.lineItemsSettings.showSuggestions.title',
                    })}
                    id="show-suggestions"
                    options={showSuggestionsOptions}
                    field={field}
                    disabled={
                      values.annotationMethod === 'annotationMethod.gridDesign'
                    }
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="footerLayout"
              render={({ field }) => (
                <LineItemSettingsControl
                  name={intl.formatMessage({
                    id: 'components.lineItemsSettings.footerLayout.title',
                  })}
                  id="footer-layout"
                  options={footerLayoutOptions}
                  field={field}
                />
              )}
            />
            <Controller
              control={control}
              name="sortFooterColumns"
              render={({ field }) => (
                <LineItemSettingsControl
                  name={intl.formatMessage({
                    id: 'components.lineItemsSettings.sortColumns.title',
                  })}
                  id="sort-columns"
                  options={sortColumnsOptions}
                  field={field}
                />
              )}
            />
            <Stack direction="row" spacing={2} justifyContent="end">
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={onClose}
              >
                {intl.formatMessage({
                  id: 'components.lineItemsSettings.close',
                })}
              </Button>
              <Button type="submit" variant="contained">
                {intl.formatMessage({
                  id: 'components.lineItemsSettings.save',
                })}
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
