import { WithEtag } from '@rossum/api-client';
import { Schema } from '@rossum/api-client/schemas';
import { Control, useController, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import { ContentGroup } from '../../../../ui/content-group/ContentGroup';
import { ThenSection } from '../../../rules/ThenSection';
import { RuleFormType } from '../../../rules/types';
import { TriggerSection } from '../../../rules/WhenSection/TriggerSection';

type RuleFormProps = {
  control: Control<RuleFormType>;
  schema: WithEtag<Schema>;
  enabled: boolean;
};

export const RuleForm = ({ control, schema }: RuleFormProps) => {
  const intl = useIntl();

  const triggerConditionField = useController({
    control,
    name: 'triggerCondition',
  });

  // @ts-expect-error This can be partial in RHF.
  const currentRuleConcept: RuleFormType = useWatch({ control });

  return (
    <>
      <ContentGroup
        title={intl.formatMessage({
          id: 'features.queueSettings.rules.detail.identification.title',
        })}
        description={intl.formatMessage({
          id: 'features.queueSettings.rules.detail.identification.description',
        })}
      >
        <TextFieldControl
          ControllerProps={{ control, name: 'name' }}
          label={intl.formatMessage({
            id: 'features.queueSettings.rules.detail.identification.name.label',
          })}
          FieldLabelProps={{
            layout: 'floating',
          }}
        />
        <TextFieldControl
          ControllerProps={{ control, name: 'description' }}
          label={intl.formatMessage({
            id: 'features.queueSettings.rules.detail.identification.description.label',
          })}
          FieldLabelProps={{
            layout: 'floating',
          }}
          multiline
          maxRows={3}
        />
      </ContentGroup>
      <TriggerSection
        schemaContent={schema.content ?? []}
        schemaUrl={schema.url}
        queueUrls={schema.queues}
        triggerConditionField={triggerConditionField}
        currentRuleConcept={currentRuleConcept}
      />
      <ThenSection control={control} schemaContent={schema.content ?? []} />
    </>
  );
};
