import { Url } from '@rossum/api-client';
import { Workspace } from '@rossum/api-client/workspaces';
import { ExpandLess, ExpandMore, FolderOutlined } from '@rossum/ui/icons';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  PaperProps,
  TextField,
} from '@rossum/ui/material';
import { forwardRef, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useWorkspacesWithQueues } from '../hooks/useWorkspacesWithQueues';

const PaperComponent = (props: PaperProps) => (
  <Paper elevation={4} {...props} />
);

const RenderGroup = ({
  params,
  workspace,
}: {
  params: AutocompleteRenderGroupParams;
  workspace: Workspace;
}) => {
  const [open, setOpen] = useState(true);

  return (
    <List key={params.key} dense sx={{ py: 0 }}>
      <ListItemButton onClick={() => setOpen(open => !open)}>
        <ListItemIcon sx={{ minWidth: 32 }}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <FolderOutlined />
        </ListItemIcon>
        <ListItemText primary={workspace?.name ?? 'N/A'} />
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {params.children}
        </List>
      </Collapse>
    </List>
  );
};

type QueueSelectProps = {
  value: Url | null;
  onChange: (values: Url | null) => void;
};

export const QueueSelect = forwardRef(
  ({ value, onChange }: QueueSelectProps, ref) => {
    const [inputValue, setInputValue] = useState<string>('');
    const intl = useIntl();

    const { isLoading, workspaces, workspacesWithQueues } =
      useWorkspacesWithQueues({
        enableQueries: true,
      });

    const stableQueues = useMemo(
      () =>
        workspacesWithQueues?.flatMap(ws =>
          ws.queues.map(queue => ({ ...queue, workspaceName: ws.name }))
        ) ?? [],
      [workspacesWithQueues]
    );

    const stableValue = useMemo(
      () => stableQueues?.find(queue => value === queue.url) ?? null,
      [stableQueues, value]
    );

    return !isLoading ? (
      <Autocomplete
        data-cy="queue-select"
        PaperComponent={PaperComponent}
        ref={ref}
        size="small"
        options={stableQueues}
        getOptionLabel={option => `${option.workspaceName} - ${option.name}`}
        groupBy={option => option.workspace ?? ''}
        value={stableValue}
        onChange={(_e, value) => {
          onChange(value?.url ?? null);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'features.queues.queueSelect.label',
            })}
            placeholder={intl.formatMessage({
              id: 'features.queues.queueSelect.placeholder',
            })}
            InputLabelProps={{ shrink: true }}
          />
        )}
        inputValue={inputValue}
        onInputChange={(_e, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderGroup={params => {
          const workspace = workspaces?.find(ws => ws.url === params.group);
          return workspace ? (
            <RenderGroup params={params} workspace={workspace} />
          ) : null;
        }}
        renderOption={(params, queue) => (
          <ListItemButton
            component="li"
            {...params}
            key={queue.url}
            data-cy="queue-option"
          >
            <ListItemText secondary={queue.name} />
          </ListItemButton>
        )}
      />
    ) : null;
  }
);
