import { UniqueIdentifier } from '@dnd-kit/core';
import { getEtag, WithEtag } from '@rossum/api-client';
import { HttpError, isElisClientError } from '@rossum/api-client/errors';
import { Queue } from '@rossum/api-client/queues';
import { Schema } from '@rossum/api-client/schemas';
import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, RouteChildrenProps, useHistory } from 'react-router';
import * as R from 'remeda';
import { v4 as uuidv4 } from 'uuid';
import { usePatchSchema } from '../../../../business/schema/usePatchSchema';
import {
  throwError,
  throwInfo,
} from '../../../../redux/modules/messages/actions';
import { formulaFieldsEnabledSelector } from '../../../../redux/modules/organizationGroup/selectors';
import { userSelector } from '../../../../redux/modules/user/selectors';
import { HelmetComponent } from '../../../../routes/HelmetComponent';
import { ItemContent, ItemContentProps } from '../components/ItemContent';
import {
  SectionContent,
  SectionContentProps,
} from '../components/SectionContent';
import { RELEASE_DATE_OF_NEW_FIELDS_TYPE } from '../constants';
import { toSchemaPatchPayload } from '../form-model/transformations/toSchema';
import { SchemaActionHandler } from '../form-model/transformations/toSchema.utils';
import { useResolveSchemaField } from '../hooks/useResolveSchemaField';
import { FieldRouteParams } from '../types';

type FieldPageProps = RouteChildrenProps<FieldRouteParams> & {
  schema: WithEtag<Schema>;
  queue: Queue;
};

// replace the last non-undefined value with fieldId
const redirectToRouteParams = (
  params: FieldRouteParams,
  fieldId: string
): FieldRouteParams =>
  R.pipe(
    params,
    R.entries(),
    entries =>
      R.map(entries, ([key, value], index) =>
        value !== undefined && entries[index + 1]?.[1] === undefined
          ? ([key, fieldId] as const)
          : ([key, value] as const)
      ),
    val => val,
    R.fromEntries()
  ) as FieldRouteParams;

export const FieldPage = ({ match, schema, queue }: FieldPageProps) => {
  const user = useSelector(userSelector);
  const formulaFieldsEnabled = useSelector(formulaFieldsEnabledSelector);

  const { path, field: currentField } = useResolveSchemaField({
    schema,
    fieldsRoute: match?.params ?? {
      sectionId: '',
    },
  });

  const history = useHistory();

  const newField = !currentField && !!path;

  const key = useRef('initial-key');

  // TODO: Can we get this a little more clearly?
  // is it true now that `parentId` is exactly the segment of `match.params` that is 2 indexes above the first `undefined` occurence?
  // (assuming that if all three are defined, there is a "fourth undefined")
  // could be more transparent
  const parentId = path
    ? newField
      ? path.at(-1)?.id ?? null
      : path.at(-2)?.id ?? null
    : null;

  const { mutate: patchSchema } = usePatchSchema({
    etag: getEtag(schema),
  });

  const dispatch = useDispatch();

  const executeSchemaAction: SchemaActionHandler = useCallback(
    options => {
      const payload = toSchemaPatchPayload(schema, options);
      if (payload) {
        patchSchema(
          { id: payload.id, payload },
          {
            onSuccess: () => {
              dispatch(throwInfo('schemaUpdated'));
              if (match) {
                // when deleting currently displayed item, always redirect
                if (
                  options.op === 'delete' &&
                  options.id === currentField?.id
                ) {
                  // when on LI child, go to the parent
                  if (match.params.schemaItemChildId) {
                    history.replace(
                      generatePath(match.path, {
                        ...match.params,
                        schemaItemChildId: undefined,
                      })
                    );

                    return;
                  }

                  // otherwise go to fields route root
                  history.replace(
                    generatePath(match?.path ?? '', {
                      ...(match?.params ?? {}),
                      sectionId: undefined,
                      schemaItemId: undefined,
                      schemaItemChildId: undefined,
                    })
                  );

                  return;
                }

                // if adding or editing, always* redirect
                if (options.op === 'add' || options.op === 'edit') {
                  // after successful submit, we change key which FORCES forms to remount
                  // this their _dirty_ state and `skipPrompt` is reset
                  const toPath = generatePath(
                    match.path,
                    redirectToRouteParams(
                      match.params,
                      options.formModel.field.id
                    )
                  );

                  history.replace(toPath);
                  key.current = uuidv4();
                }
              }
            },
            onError: error => {
              if (error instanceof HttpError && error.code === 412) {
                dispatch(throwError('schemaUpdateConflict'));
              } else if (isElisClientError(error)) {
                dispatch(throwError('schemaUpdateFailed'));
              } else {
                dispatch(throwError('clientError'));
              }
            },
          }
        );
      }
    },
    [currentField?.id, dispatch, history, match, patchSchema, schema]
  );

  // TODO: These could be unified, but since they are going to two different components
  // I kept them separate for now
  const handleSectionSubmit: SectionContentProps['onSubmit'] = useCallback(
    formModel => {
      if (!currentField) {
        executeSchemaAction({
          op: 'add',
          parentId,
          formModel,
        });
      } else {
        executeSchemaAction({
          op: 'edit',
          id: currentField.id,
          formModel,
        });
      }
    },
    [currentField, executeSchemaAction, parentId]
  );

  const handleSectionDelete: SectionContentProps['onDelete'] = useCallback(
    sectionId => {
      executeSchemaAction({
        op: 'delete',
        id: sectionId,
      });
    },
    [executeSchemaAction]
  );

  const handleItemSubmit: ItemContentProps['onSubmit'] = useCallback(
    formModel => {
      if (!currentField) {
        executeSchemaAction({
          op: 'add',
          parentId,
          formModel,
        });
      } else {
        executeSchemaAction({
          op: 'edit',
          id: currentField.id,
          formModel,
        });
      }
    },
    [currentField, executeSchemaAction, parentId]
  );

  const handleItemDelete: ItemContentProps['onDelete'] = useCallback(
    itemId => {
      executeSchemaAction({
        op: 'delete',
        id: itemId,
      });
    },
    [executeSchemaAction]
  );

  const handleChildrenReorder: ItemContentProps['onChildrenReorder'] =
    useCallback(
      (from, to) => {
        executeSchemaAction({
          op: 'move',
          from,
          to,
        });
      },
      [executeSchemaAction]
    );

  const handleQuickAction = useCallback(
    (
      parentId: UniqueIdentifier,
      items: Record<
        UniqueIdentifier,
        {
          prop: 'hidden' | 'canExport' | 'required';
          value: boolean;
        }
      >
    ) => {
      executeSchemaAction({
        op: 'quick-action',
        parentId,
        items,
      });
    },
    [executeSchemaAction]
  );

  const dateJoined = user.dateJoined ? new Date(user.dateJoined) : new Date();
  const releaseDate = new Date(RELEASE_DATE_OF_NEW_FIELDS_TYPE);
  const supportLegacyUser = dateJoined < releaseDate;

  if (currentField || newField) {
    const isSection =
      currentField?.category === 'section' || (newField && path?.length === 0);

    return (
      <>
        {currentField ? (
          <HelmetComponent
            dynamicName={currentField.label}
            translationKey="features.routes.pageTitles.queueSettings.editField"
          />
        ) : null}
        {isSection ? (
          <SectionContent
            key={key.current}
            schema={schema}
            data={currentField}
            onSubmit={handleSectionSubmit}
            onDelete={handleSectionDelete}
            onChildrenReorder={handleChildrenReorder}
            onQuickAction={handleQuickAction}
          />
        ) : parentId ? (
          <ItemContent
            key={key.current}
            data={currentField}
            queues={[queue]}
            onSubmit={handleItemSubmit}
            onDelete={handleItemDelete}
            onChildrenReorder={handleChildrenReorder}
            onQuickAction={handleQuickAction}
            schema={schema}
            parentId={parentId}
            supportLegacyUser={supportLegacyUser}
            formulaFieldsEnabled={formulaFieldsEnabled}
          />
        ) : null}
      </>
    );
  }

  return null;
};
