import { OndemandVideo, RocketLaunch } from '@rossum/ui/icons';
import { IconHelpCircle } from '@rossum/ui/icons/tabler';
import { IconButton, SvgIcon, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import CodeTagsIcon from 'mdi-react/CodeTagsIcon';
import KeyboardIcon from 'mdi-react/KeyboardIcon';
import LifebuoyIcon from 'mdi-react/LifebuoyIcon';
import LinkIcon from 'mdi-react/LinkVariantIcon';
import MessageAlertIcon from 'mdi-react/MessageAlertIcon';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  DEVELOPER_HUB_LINK,
  HELP_CENTER_LINK,
  KEYBOARD_SHORTCUTS_LINK,
  QUICK_START_GUIDE,
  ROSSUM_VIDEOS_LINK,
} from '../../constants/values';
import {
  organizationSelector,
  supportMenuItemsSelector,
} from '../../redux/modules/organization/selectors';
import { reportProblem } from '../../redux/modules/report/actions';
import { SupportMenuItem } from '../../types/organization';
import { State } from '../../types/state';
import Dropdown, { ItemPropsT } from '../UI/Dropdown';
import styles from './styles.module.sass';

type StateProps = {
  organizationLoaded: boolean;
  supportMenuItems?: Array<SupportMenuItem>;
};

type DispatchProps = {
  openDialog: () => void;
};

type Props = StateProps & DispatchProps;

const HelpPanel = ({
  organizationLoaded,
  supportMenuItems,
  openDialog,
}: Props) => {
  const intl = useIntl();
  const hideSupportMenu =
    !organizationLoaded || (supportMenuItems && supportMenuItems.length === 0);

  if (hideSupportMenu) {
    return null;
  }

  return (
    <Dropdown
      className={styles.DropdownMenu}
      items={
        supportMenuItems
          ? supportMenuItems.map(({ url, label }) => (props: ItemPropsT) => (
              <a
                key={url + label}
                {...props}
                className={clsx(props.className, styles.CustomLink)}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkIcon />
                <span>{label}</span>
              </a>
            ))
          : [
              (props: ItemPropsT) => (
                <a
                  target="_blank"
                  key="quick-start-guide"
                  rel="noreferrer noopener"
                  {...props}
                  href={QUICK_START_GUIDE}
                >
                  <RocketLaunch />
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'components.helpPanel.quickStart',
                    })}
                  </Typography>
                </a>
              ),
              (props: ItemPropsT) => (
                <a
                  target="_blank"
                  key="videos"
                  rel="noreferrer noopener"
                  {...props}
                  href={ROSSUM_VIDEOS_LINK}
                >
                  <OndemandVideo />
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'components.helpPanel.videos',
                    })}
                  </Typography>
                </a>
              ),
              (props: ItemPropsT) => (
                <a
                  target="_blank"
                  key="help-panel-help-center"
                  rel="noreferrer noopener"
                  {...props}
                  href={HELP_CENTER_LINK}
                >
                  <LifebuoyIcon />
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'components.helpPanel.helpCenter',
                    })}
                  </Typography>
                </a>
              ),
              (props: ItemPropsT) => (
                <a
                  target="_blank"
                  key="help-panel-dev-hub"
                  rel="noreferrer noopener"
                  {...props}
                  href={DEVELOPER_HUB_LINK}
                >
                  <CodeTagsIcon />
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'components.helpPanel.devHub',
                    })}
                  </Typography>
                </a>
              ),
              (props: ItemPropsT) => (
                <a
                  target="_blank"
                  key="help-panel-shortcuts"
                  rel="noreferrer noopener"
                  {...props}
                  href={KEYBOARD_SHORTCUTS_LINK}
                >
                  <KeyboardIcon />
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'components.helpPanel.keyboardShortcuts',
                    })}
                  </Typography>
                </a>
              ),
              (props: ItemPropsT) => (
                <a
                  {...props}
                  key="help-panel-report"
                  data-cy="report-problem-button"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    openDialog();
                  }}
                >
                  <MessageAlertIcon />
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'components.helpPanel.report',
                    })}
                  </Typography>
                </a>
              ),
            ]
      }
    >
      <IconButton size="medium" color="secondary" data-cy="help-panel-button">
        <SvgIcon fontSize="small">
          <IconHelpCircle />
        </SvgIcon>
      </IconButton>
    </Dropdown>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  organizationLoaded: !!organizationSelector(state).id,
  supportMenuItems: supportMenuItemsSelector(state),
});

export default connect(mapStateToProps, {
  openDialog: reportProblem,
})(HelpPanel);
