import './polyfills';
import { initSentry } from 'apps/elis/src/lib/sentry.ts';
import syncLocaleStorage from 'localstorage-vcs';
import { get } from 'lodash';
import {
  beamerProductId,
  beamerSelector,
  currentCodeVersion,
  sentryEnvironment,
  shouldRollbar,
} from './constants/config';
import { localStorageVersion } from './constants/values';
import { checkStatus } from './lib/apiHelpers';
import { initCSPReportingObserver } from './lib/initCSPReportingObserver';
import {
  isBeamerRelatedError,
  isErrorWithoutAnErrorObject,
  isReactAceRelatedError,
} from './lib/rollbar';
import { throwError, throwInfo } from './redux/modules/messages/actions';
import { alertNetworkOutage } from './redux/modules/ui/actions';

export default store => {
  // Synchronize Local Storage
  syncLocaleStorage({
    version: localStorageVersion,
    remove: [
      'defaultQueries',
      'annotationsQuery',
      'annotationStack',
      'shouldHideBanner_v1',
      'shouldHideBanner',
    ],
  });

  // Rollbar Initialization
  if (shouldRollbar) {
    const sentry = initSentry();
    window.Sentry = sentry;

    if (sentryEnvironment?.includes(':prod')) {
      initCSPReportingObserver(sentry)?.observe();
    }
  }

  // https://www.getbeamer.com/docs
  window.beamer_config = {
    button: false,
    product_id: beamerProductId,
    selector: beamerSelector,
    counter: false,
    lazy: true,
  };

  // Error Handler setup
  window.onerror = (...errors) => {
    // Handling of an error from react-ace editor
    // Solve it with hooks when some hook equivalent for componentDidCatch exists
    const errorFilePath = get(errors, '1');

    if (errorFilePath && isReactAceRelatedError(errorFilePath)) return true;

    if (errorFilePath && isBeamerRelatedError(errorFilePath)) return true;

    if (window.Rollbar) {
      const errorMessage = get(errors, '0');

      window.Rollbar.configure({
        payload: {
          title: errorMessage,
          fingerprint: errorMessage,
        },
      });

      window.Rollbar.error(errorMessage, {
        errors,
        recent_actions: window.recent_actions || [],
      });
    }

    // For the errors without an Error object, it would be good to not inform user with toast messsage
    // just keep the error in the console (f.e. GTM script errors, etc.)
    if (isErrorWithoutAnErrorObject(errors)) return false;

    store.dispatch(throwError('clientError'));

    return false;
  };

  // New version check
  setInterval(() => {
    fetch(`/code_version.json`)
      .then(checkStatus)
      .then(r => r.json())
      .then(({ code_version: latestCodeVersion }) => {
        if (currentCodeVersion !== latestCodeVersion) {
          store.dispatch(
            throwInfo('newVersion', {
              buttonFunction: () => window.location.reload(),
              buttonType: 'link',
              timeOut: 0,
              removeOnHover: false,
              dismissible: false,
            })
          );
        }
      })
      .catch(() => {
        store.dispatch(throwError('clientError'));
      });
  }, 300000);

  // Network stability check
  let isOnline = true;
  setInterval(() => {
    if (isOnline && !window.navigator.onLine) {
      store.dispatch(alertNetworkOutage());
    }
    if (!isOnline && window.navigator.onLine) {
      window.location.reload();
    }
    isOnline = window.navigator.onLine;
  }, 1000);
};
