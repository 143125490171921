import { darken, Stack } from '@rossum/ui/material';
import { LocationDescriptor } from 'history';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { push, replace as replacePath } from 'redux-first-history';
import { useLeavingModalDispatch } from '../../containers/Queue/containers/QueueSchema/lib/leavingModalHooks';
import { LocalizationKeys } from '../../i18n';

const ACTIVE_CLASS = 'active_class';

type Props = {
  text: LocalizationKeys;
  to: LocationDescriptor;
  icon?: ReactNode;
  replace?: boolean;
  dataCy?: string;
  isActive?: NavLinkProps['isActive'];
};

const SidebarNavLink = ({
  isActive,
  replace,
  icon,
  text,
  to,
  dataCy,
}: Props) => {
  const intl = useIntl();
  const leaveSafely = useLeavingModalDispatch();

  const handleNavigation = (e: React.MouseEvent) => {
    if (e.metaKey || e.ctrlKey) return;

    e.preventDefault();
    leaveSafely(replace ? replacePath(to) : push(to));
  };
  return (
    <Stack
      component={NavLink}
      onClick={handleNavigation}
      to={to}
      data-cy={dataCy}
      isActive={isActive}
      exact
      px={3}
      py={1}
      sx={{
        color: t => t.palette.text.primary,
        transition: theme => theme.transitions.create('background-color'),
        '&:hover': {
          color: t => t.palette.text.primary,
          backgroundColor: t => t.palette.action.hover,
          textDecoration: 'none',
        },
        [`&.${ACTIVE_CLASS}`]: {
          color: t => t.palette.primary.contrastText,
          backgroundColor: t => t.palette.primary.main,
          '&:hover': {
            backgroundColor: t => darken(t.palette.primary.main, 0.1),
          },
        },
      }}
      direction="row"
      alignItems="center"
      gap={1}
      activeClassName={ACTIVE_CLASS}
    >
      {icon}
      {intl.formatMessage({ id: text })}
    </Stack>
  );
};

export default SidebarNavLink;
