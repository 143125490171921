import { SchemaAction } from '@rossum/api-client/schemas';
import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconInfoSquareRounded,
} from '@rossum/ui/icons/tabler';
import { Autocomplete, createSvgIcon, TextField } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { getSchemaFields } from '../../formulas/FormulaEditor/helpers';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const messageActionPayloadSchema = z.object({
  type: z.string().default('warning'),
  content: z.string().default(''),
  schema_id: z.string().optional(),
});

const MessageActionForm = createForm(({ value, onChange, schemaContent }) => {
  const intl = useIntl();
  const schemaFieldOptions = useMemo(
    () => getSchemaFields(schemaContent),
    [schemaContent]
  );
  const schemaField = useMemo(
    () => schemaFieldOptions.find(f => f.id === value.schema_id),
    [schemaFieldOptions, value.schema_id]
  );

  return (
    <>
      <TextField
        multiline
        maxRows={10}
        label={intl.formatMessage({
          id: 'features.queueSettings.rules.actions.message.form.content',
        })}
        size="small"
        value={value.content}
        onChange={e => onChange({ ...value, content: e.currentTarget.value })}
      />
      <Autocomplete
        size="small"
        options={schemaFieldOptions}
        getOptionLabel={option => `${option.label} (${option.id})`}
        value={schemaField}
        onChange={(_e, v) => {
          if (v) {
            onChange({ ...value, schema_id: v.id });
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'features.queueSettings.rules.actions.message.form.placement',
            })}
            placeholder={intl.formatMessage({
              id: 'features.queueSettings.rules.actions.message.form.placementPlaceholder',
            })}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </>
  );
}, messageActionPayloadSchema);

const MessageActionHeader = createHeader(({ value }) => {
  return (
    <ActionHeader
      entries={[
        {
          key: 'content',
          value: value.content,
          sx: {
            maxWidth: 200,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
      ]}
    />
  );
}, messageActionPayloadSchema);

const infoAction = {
  icon: createSvgIcon(<IconInfoSquareRounded />, 'IconInfoSquareRounded'),
  intlKey: 'message.info' as const,
  groupIntlKey: 'messages' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'show_message' &&
    messageActionPayloadSchema.parse(a.payload).type === 'info',
  serialized: { type: 'show_message', payload: { type: 'info' } },
  form: MessageActionForm,
  header: MessageActionHeader,
};

const warningAction = {
  icon: createSvgIcon(<IconAlertTriangle />, 'IconAlertTriangle'),
  intlKey: 'message.warning' as const,
  groupIntlKey: 'messages' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'show_message' &&
    messageActionPayloadSchema.parse(a.payload).type === 'warning',
  serialized: { type: 'show_message', payload: { type: 'warning' } },
  form: MessageActionForm,
  header: MessageActionHeader,
};

const errorAction = {
  icon: createSvgIcon(<IconAlertOctagon />, 'IconAlertOctagon'),
  intlKey: 'message.error' as const,
  groupIntlKey: 'messages' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'show_message' &&
    messageActionPayloadSchema.parse(a.payload).type === 'error',
  serialized: { type: 'show_message', payload: { type: 'error' } },
  form: MessageActionForm,
  header: MessageActionHeader,
};

export const messageActions = [infoAction, warningAction, errorAction] as const;
