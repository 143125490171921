import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@rossum/ui/material';
import { Location } from 'history';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Prompt, useHistory } from 'react-router';
import DialogTitle from '../dialog-title/DialogTitle';

type LeavingDialogProps = {
  when: boolean;
};

// Although this is not a proper UI component, let's put it to UI right now.
export const LeavingDialog = ({ when }: LeavingDialogProps) => {
  const [locationState, setLocationState] = useState<
    Location<unknown> | undefined
  >(undefined);

  const history = useHistory();
  const intl = useIntl();

  return (
    <>
      <Dialog
        data-cy="leaving-dialog"
        open={!!locationState}
        onClose={() => setLocationState(undefined)}
        sx={{ transition: 'smooth' }}
        PaperProps={{
          sx: { width: 480, minHeight: 213, position: 'fixed' },
          elevation: 2,
        }}
      >
        {locationState ? (
          <>
            <DialogTitle
              title={intl.formatMessage({
                id: 'components.leavingDialog.title',
              })}
            />
            <DialogContent sx={{ mx: 4, mt: 4, p: 0 }}>
              {intl.formatMessage({
                id: 'components.leavingDialog.description',
              })}
            </DialogContent>
            <DialogActions sx={{ pb: 4, pt: 2.5, mr: 4, pr: 0 }}>
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                data-cy="leaving-dialog-confirm"
                onClick={() => history.push(locationState)}
              >
                {intl.formatMessage({
                  id: 'components.leavingDialog.discard',
                })}
              </Button>
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                data-cy="leaving-dialog-cancel"
                onClick={() => setLocationState(undefined)}
              >
                {intl.formatMessage({
                  id: 'components.leavingDialog.keepEditing',
                })}
              </Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
      <Prompt
        message={location => {
          setLocationState(locationState ? undefined : location);
          return locationState !== undefined;
        }}
        when={when}
      />
    </>
  );
};
