export const extensionFunctionType = 'function';
export const extensionWebhookType = 'webhook';

export const rossumStore = 'rossum_store';
export const customExtension = 'custom';

// This does not seem to be used
export const runtimes = [
  'nodejs22.x',
  'nodejs18.x',
  'python3.12',
  'python3.8',
] as const;
