import { SchemaRule } from '../models/schemaRule';
import { schemaRuleSchema } from '../models/schemaRule.schema';
import { ruleCreatePayloadSchema } from './create.schema';

export type RuleCreatePayload = Partial<SchemaRule>;

export const create = (payload: RuleCreatePayload) => {
  return {
    endpoint: `/rules`,
    method: 'POST',
    responseSchema: schemaRuleSchema,
    payloadSchema: ruleCreatePayloadSchema,
    payload,
  } as const;
};
