import { endpoints, ID } from '@rossum/api-client';
import { PatchSchemaPayload } from '@rossum/api-client/schemas';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../lib/apiClient';
import { SCHEMA_QUERY_KEY } from './useSchema';

export const MUTATION_KEY_PATCH_SCHEMA = ['patchSchemaMutation'] as const;

type SchemaMutationVariables = {
  id: ID;
  payload: PatchSchemaPayload;
};
export const usePatchSchema = ({ etag }: { etag?: string }) => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: MUTATION_KEY_PATCH_SCHEMA,
    mutationFn: ({ id, payload }: SchemaMutationVariables) =>
      api.request(endpoints.schemas.patch(id, payload), {
        additionalHeaders: { 'If-Match': etag },
      }),

    onMutate: async ({ payload }) => {
      await queryClient.cancelQueries({
        queryKey: [SCHEMA_QUERY_KEY, payload.url],
      });

      const oldSchema = queryClient.getQueryData([
        SCHEMA_QUERY_KEY,
        payload.url,
      ]);

      queryClient.setQueryData([SCHEMA_QUERY_KEY, payload.url], () => payload);

      return { oldSchema };
    },

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [SCHEMA_QUERY_KEY, variables.payload.url],
      });
    },

    onError: (_, variables, context) => {
      queryClient.setQueryData(
        [SCHEMA_QUERY_KEY, variables.payload.url],
        context?.oldSchema
      );
    },
  });
};
