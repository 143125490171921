import { SchemaSection } from '@rossum/api-client/schemas';
import { Box, Stack, Typography } from '@rossum/ui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { z, ZodObject, ZodRawShape } from 'zod';

type InputFormComponentProps<T> = {
  value: T;
  onChange: (v: T) => void;
  schemaContent: SchemaSection[];
};

type OutputFormComponentProps<T> = {
  value?: unknown;
  onChange: (v: T) => void;
  schemaContent: SchemaSection[];
};

export const createForm =
  <T extends ZodObject<ZodRawShape>>(
    Form: FC<InputFormComponentProps<z.TypeOf<T>>>,
    formSchema: T
  ) =>
  ({
    value,
    onChange,
    schemaContent,
  }: OutputFormComponentProps<z.TypeOf<T>>) => {
    const intl = useIntl();
    const result = formSchema.safeParse(value ?? {});
    return result.success ? (
      <Form
        onChange={onChange}
        value={result.data}
        schemaContent={schemaContent}
      />
    ) : (
      <Stack spacing={1}>
        <Typography color="error">
          {intl.formatMessage({
            id: 'features.queueSettings.rules.actionForm.payloadError',
          })}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {intl.formatMessage({
            id: 'features.queueSettings.rules.actionForm.payload',
          })}
        </Typography>
        <Box sx={{ fontFamily: 'Monospace', whiteSpace: 'pre' }}>
          {JSON.stringify(value, null, 2)}
        </Box>
      </Stack>
    );
  };
