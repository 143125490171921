import { useIntl } from 'react-intl';
import { TrialLimitedBox } from '../../../../ui/trial-limited-box/TrialLimitedBox';

export const TrialLimited = () => {
  const intl = useIntl();

  return (
    <TrialLimitedBox elevation={8} direction="row">
      {intl.formatMessage({
        id: 'containers.settings.queues.email.trialLimited.editTemplateBanner',
      })}
    </TrialLimitedBox>
  );
};
