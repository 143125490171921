import { Chip, Paper, Radio, Stack, Typography } from '@rossum/ui/material';
import { Control, useController } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Queue } from '../../../../types/queue';

type Props = {
  control: Control<Pick<Queue, 'defaultScoreThreshold' | 'automationLevel'>>;
};

const AUTOMATION_LEVELS = ['confident', 'always', 'never'] as const;

// TODO this is kind of similar to ControlledRadioGroup which is used currently only for User authentication type,
// so it should be possible to unify it
const AutomationLevels = ({ control }: Props) => {
  const intl = useIntl();
  const {
    field: { value, onChange },
  } = useController({
    name: 'automationLevel',
    control,
  });

  return (
    <Stack>
      {AUTOMATION_LEVELS.map((level, index) => {
        const firstItem = index === 0;
        const lastItem = index === AUTOMATION_LEVELS.length - 1;
        const isActive = level === value;

        return (
          <Typography
            variant="body1"
            component="label"
            htmlFor={`automationLevel-${level}`}
            key={level}
            data-cy={`automation-level-${level}`}
            sx={{ zIndex: isActive ? 1 : 0, cursor: 'pointer' }}
          >
            <Stack
              component={Paper}
              elevation={4}
              p={2}
              mb="-2px"
              sx={{
                border: t =>
                  `2px solid ${isActive ? t.palette.primary.main : t.palette.divider}`,
                borderTopLeftRadius: firstItem ? '10px' : 0,
                borderTopRightRadius: firstItem ? '10px' : 0,
                borderBottomLeftRadius: lastItem ? '10px' : 0,
                borderBottomRightRadius: lastItem ? '10px' : 0,
                boxShadow: 'none',
                '&:hover': {
                  backgroundImage: 'none',
                  backgroundColor: t => t.palette.action.hover,
                },
              }}
            >
              <Stack direction="row" alignItems="center">
                <Radio
                  value={level}
                  checked={level === value}
                  onChange={() => onChange(level)}
                  id={`automationLevel-${level}`}
                />
                <Typography variant="body2" fontWeight="bold">
                  <FormattedMessage
                    id={`containers.settings.automation.documentAutomation.${level}.title`}
                  />
                  {level === 'confident' ? (
                    <Chip
                      sx={{ ml: 1 }}
                      size="tiny"
                      color="primary"
                      label={intl.formatMessage({
                        id: 'containers.settings.automation.documentAutomation.recommended',
                      })}
                    />
                  ) : null}
                </Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage
                  id={`containers.settings.automation.documentAutomation.${level}.description`}
                />
              </Typography>
            </Stack>
          </Typography>
        );
      })}
    </Stack>
  );
};

export default AutomationLevels;
