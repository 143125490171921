import { Typography } from '@rossum/ui/material';
import { GridColDef } from '@rossum/ui/x-data-grid-pro';

type ColumnHeaderProps = {
  colDef: GridColDef;
};

const ColumnHeader = ({ colDef }: ColumnHeaderProps) => {
  // when switching tabs we're adding/removing columns and at some point getting colDef === undefined
  return colDef ? (
    <Typography
      align="left"
      flexDirection="row"
      data-cy={`all-document-dashboard-sorting-${colDef.field}`}
      variant="subtitle2"
    >
      {colDef.headerName || colDef.field}
    </Typography>
  ) : null;
};

export default ColumnHeader;
