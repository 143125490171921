import { Paper, Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';

type OptionsGroupProps = {
  title: string;
  helperText: string;
  children: ReactNode;
  isDisabled?: boolean;
  icon?: ReactNode;
};

export const ContentOption = ({
  title,
  helperText,
  children,
  icon,
  isDisabled,
}: OptionsGroupProps) => {
  return (
    <Stack component={Paper} direction="row" px={4} py={3} gap={2} width="33%">
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography
            variant="h6"
            color={isDisabled ? 'text.disabled' : 'inherit'}
          >
            {title}
          </Typography>
          {icon && icon}
        </Stack>
        <Typography
          variant="caption"
          color={isDisabled ? 'text.disabled' : 'text.secondary'}
        >
          {helperText}
        </Typography>
      </Stack>
      <Stack alignItems="flex-end">{children}</Stack>
    </Stack>
  );
};
