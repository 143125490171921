import { endpoints } from '@rossum/api-client';
import { SuggestRuleResponse } from '@rossum/api-client/internal';
import {
  schemaActionSchema,
  SchemaRuleAction,
  schemaRuleActionSchema,
  SchemaSection,
} from '@rossum/api-client/schemas';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { throwError } from '../../../redux/modules/messages/actions';

export type RuleTemplate = {
  name: string;
  enabled: boolean;
  description: string;
  triggerCondition: string;
  triggerConditionSummary: string;
  ruleActions: SchemaRuleAction[];
};

const parseRuleActions = (ruleActions: unknown): SchemaRuleAction[] => {
  if (!Array.isArray(ruleActions)) return [];

  return ruleActions
    .map(action => {
      const parsedRuleAction = schemaRuleActionSchema.safeParse(action);

      if (parsedRuleAction.success) {
        const parsedAction = schemaActionSchema.safeParse(
          parsedRuleAction.data.action
        );
        if (parsedAction.success) {
          return parsedRuleAction.data;
        }
      }

      return undefined;
    })
    .filter((action): action is SchemaRuleAction => action !== undefined);
};

export const narrowRuleSuggestionResultToTemplate = (
  data: SuggestRuleResponse
): RuleTemplate[] =>
  data.results.map(r => ({
    name: r.name ?? '',
    enabled: r.enabled ?? true,
    description: r.description ?? '',
    triggerCondition: r.triggerCondition ?? '',
    triggerConditionSummary: r.triggerConditionSummary ?? '',
    ruleActions: parseRuleActions(r.ruleActions),
  }));

export const useSuggestRule = () => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: ({
      search,
      schemaContent,
    }: {
      search: string;
      schemaContent: SchemaSection[];
    }) =>
      api.request(
        endpoints.internal.rules.suggestRule({
          userQuery: search,
          schemaContent,
        })
      ),
    onError: () => {
      dispatch(throwError('clientError'));
    },
  });

  return mutation;
};
