import { SectionData } from '../../../annotations/models/annotationData';
import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  evaluateFormulasPayloadSchema,
  evaluateFormulasResponseSchema,
} from './evaluateFormulas.schema';
import { Message } from '../../../shared/models/message';
import { SchemaAction, SchemaRule } from '../../../rules/models/schemaRule';

export type EvaluateFormulasPayload = {
  schemaContent: SchemaSection[];
  annotationContent: Array<SectionData>;
  schemaRules?: Array<Partial<SchemaRule>>;
};

export type AutomationBlocker = { content: string; id: string };

export type EvaluateFormulasResponse = {
  annotationContent: Array<SectionData>;
  automationBlockers: Array<AutomationBlocker>;
  messages: Array<Message>;
  actions?: Array<SchemaAction>;
};

export const evaluateFormulas = (payload: EvaluateFormulasPayload) => {
  return {
    endpoint: `internal/schemas/evaluate_formulas`,
    method: 'POST',
    responseSchema: evaluateFormulasResponseSchema,
    payloadSchema: evaluateFormulasPayloadSchema,
    payload,
  } as const;
};
