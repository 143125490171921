import { SchemaRule, SchemaRuleAction } from '../../../rules/models/schemaRule';
import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  suggestTriggerConditionSummaryPayloadSchema,
  suggestTriggerConditionSummaryResponseSchema,
} from './suggestTriggerConditionSummary.schema';

export type SuggestTriggerConditionSummaryPayload = {
  userQuery?: string;
  schemaContent: SchemaSection[];
  schemaRule: Partial<SchemaRule>;
};

export type SuggestTriggerConditionSummaryResult = {
  description?: string;
  enabled?: boolean;
  triggerCondition?: string;
  triggerConditionSummary?: string;
  name?: string;
  ruleActions?: Partial<SchemaRuleAction>[];
};

export type SuggestTriggerConditionSummaryResponse = {
  results: Array<SuggestTriggerConditionSummaryResult>;
};

export const suggestTriggerConditionSummary = (
  payload: SuggestTriggerConditionSummaryPayload
) =>
  ({
    endpoint: `/internal/rules/trigger_condition_summary `,
    method: 'POST',
    responseSchema: suggestTriggerConditionSummaryResponseSchema,
    payloadSchema: suggestTriggerConditionSummaryPayloadSchema,
    payload,
  }) as const;
