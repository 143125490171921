import { addValidationSourceAction } from './AddValidationSourceAction';
import { automationBlockerAction } from './AutomationBlockerAction';
import { documentStateActions } from './DocumentStateAction';
import { emailAction } from './EmailAction';
import { labelAction } from './LabelAction';
import { messageActions } from './MessageAction';
import { moveDocumentAction } from './MoveDocumentAction';
import { visibilityActions } from './VisibilityAction';

export const knownActions = [
  ...messageActions,
  automationBlockerAction,
  emailAction,
  labelAction,
  ...visibilityActions,
  moveDocumentAction,
  ...documentStateActions,
  addValidationSourceAction,
] as const;

const implementedActions = [
  'show_message',
  'add_automation_blocker',
  'change_queue',
  'hide_field',
  'send_email',
  'show_field',
  'change_status',
];

export const visibleActions = knownActions.flatMap(action =>
  implementedActions.includes(action.serialized.type) ? [action] : []
);

export const knownActionGroups = knownActions.map(
  action => action.groupIntlKey
);
