import { Grid, Link } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { LocalizationKeys } from '../../../i18n';
import { StatCard } from './StatCard';

export type StatsType = {
  icon: ReactNode;
  title: LocalizationKeys;
  value: string | number;
  type: string;
  link?: string;
};

type Props = {
  stats: StatsType[];
};

const Stats = ({ stats }: Props) => {
  const intl = useIntl();
  return (
    <>
      {stats.map(({ icon, title, value, type, link }) => (
        <Grid key={title} item xs={4}>
          <StatCard
            icon={icon}
            title={`${value} ${intl.formatMessage(
              {
                id: title,
              },
              { count: value }
            )}`}
            titleDataCy={`stats-value-${type}`}
            description={
              link && (
                <Link
                  component={RouterLink}
                  variant="caption"
                  to={link}
                  color="text.disabled"
                  sx={{
                    textDecorationColor: 'text.disabled',
                    '&:hover': {
                      color: 'text.disabled',
                      textDecoration: 'none',
                    },
                  }}
                >
                  {intl.formatMessage({
                    id: 'containers.settings.queues.engine.linkMore',
                  })}
                </Link>
              )
            }
          />
        </Grid>
      ))}
    </>
  );
};

export default Stats;
