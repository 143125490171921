import React, { useMemo, useState } from 'react';

type QuickSearchContextValue = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const QuickSearchContext = React.createContext<QuickSearchContextValue | null>(
  null
);

export const QuickSearchContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  const contextValue = useMemo(() => ({ open, setOpen }), [open, setOpen]);

  return (
    <QuickSearchContext.Provider value={contextValue}>
      {children}
    </QuickSearchContext.Provider>
  );
};

export const useQuickSearchContext = () => {
  const context = React.useContext(QuickSearchContext);

  if (!context) {
    throw new Error(
      'useQuickSearchContext must be used within a QuickSearchContextProvider'
    );
  }

  return context;
};
