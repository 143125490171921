import { Check } from '@rossum/ui/icons';
import { Button, Paper, Slide, Stack } from '@rossum/ui/material';
import { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../../components/UI/Tooltip/TranslatedTooltip';

type Props = {
  hideCondition: boolean;
  onClickCancel: MouseEventHandler;
  onClickApply: MouseEventHandler;
  disabled?: boolean;
};

const HidingButtons = ({
  hideCondition,
  onClickCancel,
  onClickApply,
  disabled,
}: Props) => (
  <Slide in={!hideCondition} direction="up" appear unmountOnExit>
    <Paper
      elevation={6}
      sx={{
        p: 2,
        position: 'fixed',
        bottom: 21,
        left: '50%',
        zIndex: theme => theme.zIndex.speedDial,
      }}
    >
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClickCancel}
          data-cy="cancel-changes-button"
        >
          <FormattedMessage id="containers.statistics.sidebar.buttons.cancel" />
        </Button>
        <Tooltip
          translationId="containers.statistics.sidebar.buttons.applyTooltip"
          disabled={!disabled}
        >
          <Button
            variant="contained"
            startIcon={<Check />}
            onClick={onClickApply}
            disabled={disabled}
            data-cy="apply-changes-button"
          >
            <FormattedMessage id="containers.statistics.sidebar.buttons.apply" />
          </Button>
        </Tooltip>
      </Stack>
    </Paper>
  </Slide>
);

export default HidingButtons;
