import { Skeleton, Stack, Typography, useTheme } from '@rossum/ui/material';
import { GridRenderCellParams, GridRowModel } from '@rossum/ui/x-data-grid-pro';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { confidenceIndicatorEnabledSelector } from '../../../redux/modules/user/selectors';
import { appendSchemaColumns } from '../helpers';

type DatapointFieldData = {
  annotationId: number;
  datapointId: number;
  value: string | null | undefined;
  validationSources: undefined | string[];
  confidenceScore: number | null | undefined;
  scoreTreshold: number;
};

const RenderDatapointField = (
  props: GridRenderCellParams<
    GridRowModel<ReturnType<typeof appendSchemaColumns>>,
    DatapointFieldData
  >
) => {
  const {
    value: {
      value = '',
      validationSources = [],
      confidenceScore = null,
      scoreTreshold,
    } = {
      annotationId: -1,
      datapointId: -1,
      value: '',
      validationSources: [],
      confidenceScore: null,
      scoreTreshold: 0,
    },

    row,
  } = props;

  const theme = useTheme();

  const intl = useIntl();

  const isConfidenceIndicatorEnabled = useSelector(
    confidenceIndicatorEnabledSelector
  );

  const confidenceColor =
    confidenceScore && confidenceScore >= scoreTreshold
      ? theme.palette.success.light
      : theme.palette.text.disabled;

  const shouldShowScore =
    !!value &&
    typeof confidenceScore === 'number' &&
    confidenceScore > 0 &&
    !validationSources.includes('human');

  const skeletonWidth = useRef(Math.random() * 30 + 80);

  if (row.restricted_access)
    // no need to translate this text here as it is not inteded to be seen
    return (
      <Stack sx={{ filter: 'blur(3px)' }}>
        <Typography>
          {intl.formatMessage({
            id: 'containers.allDocuments.row.restrictedAccess',
          })}
        </Typography>
      </Stack>
    );

  if (!row.datapointsReady) {
    return <Skeleton width={skeletonWidth.current} />;
  }

  if (!isConfidenceIndicatorEnabled) {
    return <span>{value ?? ''}</span>;
  }

  return (
    <Stack>
      <Typography color="text.secondary" variant="body2">
        {value}
      </Typography>

      {shouldShowScore && (
        <Typography color={confidenceColor} variant="body2">
          {`${Math.floor((confidenceScore ?? 0) * 100)}%`}
        </Typography>
      )}
    </Stack>
  );
};

export default RenderDatapointField;
