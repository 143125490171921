import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

export const RULES_QUERY_KEY = 'rules';

export const useListRules = (schemaUrl: Url) => {
  const api = useApiClient();

  return useQuery({
    queryKey: [RULES_QUERY_KEY, 'list', schemaUrl],
    queryFn: () =>
      api.request(endpoints.rules.list({ schema: getIDFromUrl(schemaUrl) })),
  });
};
