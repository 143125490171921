import { createReactComponent } from '@tabler/icons-react';

export const IconCustomButton = createReactComponent(
  'filled',
  'customButton',
  'CustomButton',
  [
    [
      'path',
      {
        key: 'customButton-path',
        d: 'M8.4,10.8c-0.72,0-1.2,0.48-1.2,1.2c0,0.72,0.48,1.2,1.2,1.2h7.2c0.72,0,1.2-0.48,1.2-1.2c0-0.72-0.48-1.2-1.2-1.2H8.4z M21.6,6H2.4C1.08,6,0,7.08,0,8.4v7.2c0,1.32,1.08,2.4,2.4,2.4h19.2c1.32,0,2.4-1.08,2.4-2.4V8.4C24,7.08,22.92,6,21.6,6z M21.6,15.6H2.4V8.4h19.2V15.6z',
      },
    ],
  ]
);
