// Generated by ts-to-zod
import { z } from 'zod';
import { schemaRuleSchema } from '../../../rules/models/schemaRule.schema';
import { schemaSectionSchema } from '../../../schemas/models/schemaSection.schema';

export const suggestTriggerConditionPayloadSchema = z.object({
  userQuery: z.string(),
  schemaContent: z.array(schemaSectionSchema),
  schemaRule: schemaRuleSchema.partial(),
});

export const suggestTriggerConditionResultSchema = z.object({
  triggerCondition: z.string().optional(),
  triggerConditionSummary: z.string().optional(),
});

export const suggestTriggerConditionResponseSchema = z.object({
  results: z.array(suggestTriggerConditionResultSchema),
});
