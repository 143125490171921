import { SchemaAction } from '@rossum/api-client/schemas';
import { LabelOutlined } from '@rossum/ui/icons';
import { MenuItem, TextField } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { LabelsAutocomplete } from '../../labels/components/LabelsAutocomplete';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const actions = ['assign', 'remove'] as const;

const labelActionPayloadSchema = z.object({
  action: z.enum(actions).default('assign'),
  labels: z.array(z.string()).default([]),
});

const LabelActionForm = createForm(({ value, onChange }) => {
  const intl = useIntl();
  return (
    <>
      <TextField
        select
        label={intl.formatMessage({
          id: 'features.queueSettings.rules.actions.label.form.action',
        })}
        size="small"
        value={value.action}
        onChange={e => {
          const action = actions.find(a => a === e.target.value);

          if (action) {
            onChange({ ...value, action });
          }
        }}
      >
        {actions.map(action => (
          <MenuItem key={action} value={action}>
            {intl.formatMessage({
              id: `features.queueSettings.rules.actions.label.form.actions.${action}`,
            })}
          </MenuItem>
        ))}
      </TextField>
      <LabelsAutocomplete
        label={intl.formatMessage({
          id: 'features.queueSettings.rules.actions.label.form.labels',
        })}
        placeholder={intl.formatMessage({
          id: 'features.queueSettings.rules.actions.label.form.labelsPlaceholder',
        })}
        value={value.labels}
        onChange={labels => onChange({ ...value, labels })}
      />
    </>
  );
}, labelActionPayloadSchema);

const LabelActionHeader = createHeader(
  ({ value }) => (
    <ActionHeader entries={[{ key: 'action', value: value.action }]} />
  ),
  labelActionPayloadSchema
);

export const labelAction = {
  icon: LabelOutlined,
  intlKey: 'label' as const,
  resolveAction: (a: SchemaAction) => a.type === 'label',
  serialized: { type: 'label' },
  groupIntlKey: 'labels' as const,
  form: LabelActionForm,
  header: LabelActionHeader,
};
