import { SchemaAction } from '@rossum/api-client/schemas';
import { EmailOutlined } from '@rossum/ui/icons';
import { TextField } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const emailActionPayloadSchema = z.object({
  to: z.array(z.string()).default([]),
  body: z.string().default(''),
  subject: z.string().default(''),
});

const EmailActionForm = createForm(({ value, onChange }) => {
  const intl = useIntl();
  return (
    <>
      <TextField
        label={intl.formatMessage({
          id: 'features.queueSettings.rules.actions.sendEmail.form.to',
        })}
        size="small"
        value={value.to}
        onChange={e => onChange({ ...value, to: [e.currentTarget.value] })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'features.queueSettings.rules.actions.sendEmail.form.subject',
        })}
        size="small"
        value={value.subject}
        onChange={e => onChange({ ...value, subject: e.currentTarget.value })}
      />
      <TextField
        multiline
        maxRows={10}
        label={intl.formatMessage({
          id: 'features.queueSettings.rules.actions.sendEmail.form.body',
        })}
        size="small"
        value={value.body}
        onChange={e => onChange({ ...value, body: e.currentTarget.value })}
      />
    </>
  );
}, emailActionPayloadSchema);

const EmailActionHeader = createHeader(
  ({ value }) => (
    <ActionHeader
      entries={[
        { key: 'to', value: value.to?.[0] ?? '' },
        {
          key: 'subject',
          value: value.subject,
        },
      ]}
    />
  ),
  emailActionPayloadSchema
);

export const emailAction = {
  icon: EmailOutlined,
  intlKey: 'sendEmail' as const,
  groupIntlKey: 'emails' as const,
  resolveAction: (a: SchemaAction) => a.type === 'send_email',
  serialized: { type: 'send_email' },
  form: EmailActionForm,
  header: EmailActionHeader,
};
