import { every } from 'lodash';
import { ReactNode } from 'react';
import EmptySeries from '../../containers/Statistics/components/EmptySeries';
import GraphsContainer from './components/GraphContainer';
import StackedBarGraphContent, {
  BarT,
  DataType,
} from './components/StackedBarContent';
import { StackedBarGraphNames } from './types';

export type StackedBarGraphProps<K extends string> = {
  name: StackedBarGraphNames;
  bars: Array<BarT<K>>;
  data: Array<DataType<K>>;
  children?: ReactNode;
  formatLabel?: ((s: K) => string) | undefined;
  fullWidth?: boolean;
};

const StackedBarGraph = <K extends string>({
  data,
  name,
  bars,
  children,
  fullWidth,
  formatLabel,
}: StackedBarGraphProps<K>) => {
  const displayPlaceholder = every(
    bars.map(({ name: barName }) =>
      every(data, dataEl => dataEl[barName] === 0)
    )
  );
  return (
    <GraphsContainer graphName={name} fullWidth={fullWidth}>
      <span style={{ opacity: displayPlaceholder ? 0.1 : 1 }}>{children}</span>
      {displayPlaceholder ? (
        <EmptySeries inGraph graphName={name} />
      ) : (
        <StackedBarGraphContent
          data={data}
          bars={bars}
          formatLabel={formatLabel}
        />
      )}
    </GraphsContainer>
  );
};

export default StackedBarGraph;
