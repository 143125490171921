import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import DialogTitle from '../../../../ui/dialog-title/DialogTitle';

type DialogProps = {
  onClose: () => void;
  children: ReactNode;
  open: boolean;
  title: ReactNode;
  actions?: ReactNode;
  width?: number;
};

export const Dialog = ({
  actions,
  title,
  open,
  onClose,
  children,
  width,
}: DialogProps) => (
  <MUIDialog
    onClose={onClose}
    open={open}
    PaperProps={{
      sx: {
        width: width ?? 480,
      },
      elevation: 2,
    }}
    keepMounted={false}
  >
    <DialogTitle title={title} onClose={onClose} />
    <DialogContent>
      <Stack spacing={1} py={3} sx={{ color: 'text.primary' }}>
        {children}
      </Stack>
    </DialogContent>
    {actions && (
      <DialogActions sx={{ pb: 2, pr: 2, pt: 0 }}>{actions}</DialogActions>
    )}
  </MUIDialog>
);
