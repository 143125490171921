import { Button, Stack, Typography } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../ui/Dialog';

type SafelyDialogState = {
  key: 'notSavedChanges';
  onConfirm: () => void;
};

/** @deprecated use LeavingDialog */
export const useLeaveSafelyDialog = () => {
  const [dialogState, setDialogState] = useState<SafelyDialogState | null>(
    null
  );
  const intl = useIntl();

  const leaveSafelyDialog = (
    <Dialog
      open={!!dialogState}
      width={400}
      title="Discard changes"
      onClose={() => setDialogState(null)}
      actions={
        !!dialogState && (
          <Stack direction="row" spacing={1}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setDialogState(null)}
              data-cy="fm-unsaved-changes-continue-button"
            >
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.hooks.useLeaveSafelyDialog.button.continueEditing',
              })}
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={dialogState.onConfirm}
              data-cy="fm-unsaved-changes-discard-button"
            >
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.hooks.useLeaveSafelyDialog.button.discard',
              })}
            </Button>
          </Stack>
        )
      }
    >
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.hooks.useLeaveSafelyDialog.info',
        })}
      </Typography>
    </Dialog>
  );

  return { leaveSafelyDialog, dialogState, setDialogState };
};
