import {
  Box,
  Card,
  CardContent,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  title: string;
  titleDataCy: string;
  description?: ReactNode;
};

export const StatCard = ({ title, description, icon, titleDataCy }: Props) => (
  <Card>
    <CardContent>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Stack
          component={Paper}
          justifyContent="center"
          alignItems="center"
          elevation={6}
          p={1}
          sx={{
            boxShadow: 'none',
            borderRadius: '50%',
          }}
        >
          {icon}
        </Stack>
        <Box>
          <Typography variant="h6" fontSize={14} data-cy={titleDataCy}>
            {title}
          </Typography>
          {description}
        </Box>
      </Stack>
    </CardContent>
  </Card>
);
