import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import { DedicatedEngineSchema } from '@rossum/api-client/dedicatedEngineSchema';
import { FieldLabel } from '@rossum/rossum-ui/FieldLabel';
import { Add, Check } from '@rossum/ui/icons';
import {
  Button,
  Card,
  Chip,
  FormHelperText,
  Grid,
  Stack,
} from '@rossum/ui/material';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import * as yup from 'yup';
import SortableWrapper from '../../../../../../../../../components/Dnd/SortableWrapper';
import SingleCheckboxControl from '../../../../../../../../../components/ReactHookForm/controls/SingleCheckboxControl';
import TextFieldControl from '../../../../../../../../../components/ReactHookForm/controls/TextFieldControl';
import { headerFieldFormDefaultValues, LineItemFormModel } from '../utils';
import { HeaderFieldFormSchema } from './HeaderFieldForm';
import LineItemColumnCard from './LineItemColumnCard';
import TableColumnItem, { EditModeState } from './TableColumnItem';

const LineItemFormSchema = (intl: IntlShape) =>
  yup.object({
    label: yup
      .string()
      .required(
        intl.formatMessage({ id: 'components.lineItemForm.fieldErrors.label' })
      ),
    engineOutputId: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'components.lineItemForm.fieldErrors.engineOutputId.required',
        })
      )
      .matches(
        /^[-a-zA-Z0-9_]+$/,
        intl.formatMessage({
          id: 'components.lineItemForm.fieldErrors.engineOutputId.slug',
        })
      ),
    description: yup.string(),
    freeForm: yup.boolean().nullable(),
    columns: yup.array(HeaderFieldFormSchema(intl)),
  });

type LineItemFormProps = {
  engineSchema: DedicatedEngineSchema;
  index?: number;
  onSubmit: (value: LineItemFormModel, index?: number) => void;
  onCancel?: () => void;
  defaultValues: LineItemFormModel;
  // is form considered a control?
  disabled?: boolean;
  submissionError?: boolean;
};

const LineItemForm = ({
  engineSchema,
  index,
  defaultValues,
  onSubmit,
  onCancel,
  submissionError,
  disabled,
}: LineItemFormProps) => {
  const intl = useIntl();

  const [draggedField, setDraggedField] = useState<
    (typeof fields)[number] | null
  >(null);

  // managing edit mode of columns
  const [editing, setEditing] = useState<EditModeState>(null);

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { isValid, isSubmitting },
    resetField,
  } = useForm<LineItemFormModel>({
    mode: 'onChange',
    resolver: yupResolver(LineItemFormSchema(intl)),
    defaultValues,
  });

  // Columns management
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'columns',
  });

  // using useWatch caused flickering as Ivan discovered before, using watch solves it
  // seems useWatch is at least one render cycle behind watch - RHF bug?
  // this is _still_ out of sync with `fields`. This whole thing is horses#!t
  const watchColumns = watch('columns', []);

  const watchColumnEngineOutputId = editing
    ? watch(`columns.${editing.index}.engineOutputId`)
    : null;

  const watchEngineOutputId = watch('engineOutputId');

  // trigger cross-validation when `engineOutputId`s change
  useEffect(() => {
    if (watchColumnEngineOutputId !== '' && editing) {
      trigger(`columns.${editing.index}.engineOutputId`);
    }
    if (watchEngineOutputId !== '') {
      trigger('engineOutputId');
    }
  }, [editing, trigger, watchColumnEngineOutputId, watchEngineOutputId]);

  const handleEnterEditMode = useCallback(
    (index: number) => {
      if (watchColumns[index]) {
        // entering edit mode for existing column
        setEditing({ index, originalValue: cloneDeep(watchColumns[index]) });
      } else {
        // entering editing for new column
        setEditing({ index });
      }
    },
    [watchColumns]
  );

  const handleColumnAdd = useCallback(() => {
    append(headerFieldFormDefaultValues(engineSchema));
    setEditing({ index: watchColumns.length });
  }, [append, engineSchema, watchColumns.length]);

  // validate first
  const handleColumnConfirm = useCallback(
    (index: number) => {
      trigger(`columns.${index}`).then(validationResult => {
        if (validationResult) {
          setEditing(null);
          trigger('engineOutputId');
        }
      });
    },
    [trigger]
  );

  // reset to original values
  const handleColumnCancel = useCallback(() => {
    if (editing) {
      resetField(`columns.${editing.index}`, {
        defaultValue: cloneDeep(editing.originalValue),
      });
    }
    trigger('engineOutputId');
    setEditing(null);
  }, [editing, resetField, trigger]);

  // removed transition when deleting column as that caused problems with watched value
  // somehow the removing would have to be done when the transition exited
  // for that we'd have to know whether submit or delete or cancel have been fired?
  // it's a problem because fieldArray is uncontrolled and so after `remove` + rerender
  // since the form is still mounted (transitioning) it gets added back to values
  // would be nice to find a good solution for this case
  const handleColumnDelete = useCallback(
    (index: number) => {
      remove(index);
      setEditing(null);
      trigger('engineOutputId');
    },
    [remove, trigger]
  );

  const handleCancelOrDelete = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const handleDragStart = ({ active }: DragStartEvent) => {
    setDraggedField(
      fields.find(field => {
        return field.id === active.id;
      }) ?? null
    );
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeFieldIndex = fields.findIndex(item => item.id === active.id);

      const overFieldIndex = fields.findIndex(item => item.id === over?.id);

      move(activeFieldIndex, overFieldIndex);
    }

    setDraggedField(null);
  };

  return (
    <form
      onSubmit={handleSubmit(values => onSubmit(values, index))}
      // to disable browser "helpers" with native input validation -_-
      noValidate
      id="line-item-form"
    >
      <Card sx={{ p: 5 }} elevation={2}>
        <Stack spacing={2} alignItems="center">
          {submissionError && (
            <FormHelperText error>
              {intl.formatMessage({ id: 'components.lineItemForm.error' })}
            </FormHelperText>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextFieldControl
                ControllerProps={{ control, name: 'label' }}
                placeholder={intl.formatMessage({
                  id: 'components.datapointFieldControl.placeholders.label',
                })}
                label={intl.formatMessage({
                  id: 'components.datapointFieldControl.labels.label',
                })}
                required
                disabled={disabled}
                // to not lose tabIndex when mounting form
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldControl
                ControllerProps={{
                  control,
                  name: 'engineOutputId',
                }}
                placeholder={intl.formatMessage({
                  id: 'components.lineItemForm.placeholders.engineOutputId',
                })}
                label={intl.formatMessage({
                  id: 'components.lineItemForm.labels.engineOutputId',
                })}
                required
                autoComplete="off"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                ControllerProps={{
                  control,
                  name: 'description',
                }}
                placeholder={intl.formatMessage({
                  id: 'components.lineItemForm.placeholders.description',
                })}
                label={intl.formatMessage({
                  id: 'components.lineItemForm.labels.description',
                })}
                multiline
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <SingleCheckboxControl
                ControllerProps={{ control, name: 'freeForm' }}
                FieldLabelProps={{ layout: 'none' }}
                label={intl.formatMessage({
                  id: 'components.lineItemForm.labels.freeForm',
                })}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldLabel
                label={intl.formatMessage({
                  id: 'components.lineItemForm.labels.columns',
                })}
                endAdornment={
                  <Chip
                    variant="filled"
                    color="secondary"
                    size="tiny"
                    label={fields.length}
                  />
                }
              >
                <Stack>
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    modifiers={[
                      restrictToVerticalAxis,
                      restrictToParentElement,
                    ]}
                  >
                    <SortableContext
                      items={fields.map(item => item.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {fields.map((field, i) => (
                        <SortableWrapper
                          id={field.id}
                          key={field.id}
                          render={dragHandleProps => (
                            <TableColumnItem
                              dragHandleProps={dragHandleProps}
                              field={field}
                              index={i}
                              engineSchema={engineSchema}
                              editing={editing}
                              watchColumns={watchColumns}
                              handleColumnConfirm={handleColumnConfirm}
                              handleColumnCancel={handleColumnCancel}
                              handleColumnDelete={handleColumnDelete}
                              handleEnterEditMode={handleEnterEditMode}
                              disabled={disabled}
                              control={control}
                            />
                          )}
                        />
                      ))}
                    </SortableContext>
                    <DragOverlay>
                      {draggedField && (
                        <LineItemColumnCard
                          columnModel={draggedField}
                          dragHandleProps={null}
                          isDragging
                          disabled
                        />
                      )}
                    </DragOverlay>
                  </DndContext>
                </Stack>
                <Button
                  color="secondary"
                  startIcon={<Add />}
                  sx={{ alignSelf: 'flex-start' }}
                  onClick={handleColumnAdd}
                  disabled={disabled || editing !== null}
                >
                  {intl.formatMessage({
                    id: 'components.lineItemForm.buttons.addColumn',
                  })}
                </Button>
              </FieldLabel>
            </Grid>
          </Grid>
          <Stack
            // Trick to keep proper tabIndexes, Submit first, then Delete
            direction="row-reverse"
            justifyContent="flex-start"
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={
                disabled || isSubmitting || !isValid || editing !== null
              }
              startIcon={<Check />}
            >
              {intl.formatMessage({
                id: 'components.lineItemForm.buttons.submit',
              })}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              disabled={disabled || isSubmitting}
              onClick={handleCancelOrDelete}
            >
              {intl.formatMessage({
                id: 'components.lineItemForm.buttons.cancel',
              })}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </form>
  );
};

export default LineItemForm;
