import { getIDFromUrl } from '@rossum/api-client';
import {
  ChevronRight as ChevronRightIcon,
  Edit,
  FolderOpen as FolderOpenIcon,
} from '@rossum/ui/icons';
import { IconBrain } from '@rossum/ui/icons/tabler';
import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { get, truncate } from 'lodash';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink, RouteChildrenProps } from 'react-router-dom';
import { PageLayoutV2 } from '../../components/PageLayoutV2/PageLayoutV2';
import { useDedicatedEngine } from '../../features/legacy-engines/hooks/useDedicatedEngine';
import { useGenericEngine } from '../../features/legacy-engines/hooks/useGenericEngine';
import { BasicSettingsHeader } from '../../features/queue-settings/headers/BasicSettingsHeader';
import { EmailsHeader } from '../../features/queue-settings/headers/EmailsHeader';
import { auroraEngineEnabledSelector } from '../../redux/modules/organizationGroup/selectors';
import { getDetailActivePage } from '../../redux/modules/router/helpers';
import { findWorkspaceByQueue } from '../../redux/modules/workspaces/selector';
import { QueueWithInboxObject } from '../../types/queue';
import { State } from '../../types/state';
import { shouldDisplayAuroraChipByName } from '../../ui/aurora/AuroraChip';
import { AuroraIcon } from '../../ui/aurora/Icons';
import { automationEnginesPath } from '../Automation/helpers';
import { StatCard } from '../Settings/components/StatCard';
import QueueStats from '../Settings/containers/QueueStats';
import { EmailAddress } from './components/EmailAddress';

type Props = {
  selectedQueue?: QueueWithInboxObject | null;
  children: ReactNode;
} & RouteChildrenProps;

const truncationLimit = 30;

export const WorkspaceQueueBreadcrumbs = ({
  workspace,
  queue,
  children,
}: {
  workspace?: string;
  queue?: string;
  children?: ReactNode;
}) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
      sx={{ pr: 1, minWidth: 0 }}
    >
      <FolderOpenIcon sx={{ fontSize: 32, color: 'primary.main' }} />
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        minHeight={36}
        sx={{ minWidth: 0 }}
      >
        <Typography
          variant="h5"
          data-cy="wq-breadcrumbs-text"
          fontWeight={500}
          sx={{ minWidth: 0 }}
        >
          <Stack
            direction="row"
            alignItems="center"
            divider={
              <ChevronRightIcon
                sx={{
                  fontSize: 22,
                  mx: 0.5,
                  color: theme => theme.palette.text.primary,
                }}
              />
            }
          >
            {workspace && (
              <Box
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  color: theme => theme.palette.text.primary,
                }}
              >
                {workspace}
              </Box>
            )}
            {queue && (
              <Box
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  color: theme => theme.palette.text.primary,
                }}
              >
                {queue}
              </Box>
            )}
          </Stack>
        </Typography>
        {children}
      </Stack>
    </Stack>
  );
};

const Queue = ({ children, selectedQueue, match, location }: Props) => {
  const intl = useIntl();

  const genericEngineId = selectedQueue?.genericEngine
    ? getIDFromUrl(selectedQueue.genericEngine)
    : null;
  const { data: genericEngine } = useGenericEngine(genericEngineId);

  const dedicatedEngineId = selectedQueue?.dedicatedEngine
    ? getIDFromUrl(selectedQueue.dedicatedEngine)
    : null;
  const { data: dedicatedEngine } = useDedicatedEngine(dedicatedEngineId);
  const engine = dedicatedEngine ?? genericEngine;

  const activePage = getDetailActivePage(location.pathname);
  const workspace = useSelector(
    (state: State) =>
      selectedQueue &&
      findWorkspaceByQueue(state.workspaces.list, selectedQueue)
  );
  const description = engine ? engine.description : '-';

  const truncatedDescription = truncate(description, {
    length: truncationLimit,
    omission: '… ',
  });

  const inboxAddress = selectedQueue?.inboxObject?.email;

  const engineName = engine ? engine.name : '-';

  const auroraEngineEnabled = useSelector(auroraEngineEnabledSelector);

  const shouldDisplayAuroraChip = shouldDisplayAuroraChipByName(
    engineName,
    auroraEngineEnabled
  );

  return (
    <PageLayoutV2
      renderHeader={params =>
        activePage === 'basic' ? (
          <BasicSettingsHeader
            scrollableDivRef={params.scrollableDivRef}
            queueName={selectedQueue?.name ?? ''}
            emailAddress={inboxAddress}
            parentPath={match?.url ?? ''}
          />
        ) : activePage === 'emails' ? (
          <EmailsHeader
            parentPath={match?.url ?? ''}
            queueName={selectedQueue?.name ?? ''}
            scrollableDivRef={params.scrollableDivRef}
          />
        ) : null
      }
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 4 }}
      >
        <Stack spacing={1} sx={{ minWidth: 0 }}>
          <WorkspaceQueueBreadcrumbs
            workspace={workspace?.name}
            queue={selectedQueue?.name}
          />
          {inboxAddress && <EmailAddress inboxAddress={inboxAddress} />}
        </Stack>

        {activePage === 'fields' && match && (
          <Button
            component={RouterLink}
            to={{
              pathname: `${match.url}/schema`,
              state: get(location, 'state'),
            }}
            variant="contained"
            startIcon={<Edit />}
            sx={{
              '&:hover': {
                color: 'inherit',
              },
            }}
            data-cy="edit-json-schema"
          >
            {intl.formatMessage({
              id: 'containers.settings.queues.queue.editSchema',
            })}
          </Button>
        )}
      </Stack>
      {activePage === 'settings' || activePage === 'basic' ? (
        <Grid container spacing={2} sx={{ my: 3 }}>
          <Grid item xs={4}>
            <StatCard
              icon={
                shouldDisplayAuroraChip ? (
                  <AuroraIcon color="primary" />
                ) : (
                  <SvgIcon
                    component={IconBrain}
                    sx={{ fill: 'none' }}
                    color="primary"
                  />
                )
              }
              title={engineName}
              titleDataCy="stats-value-engine"
              description={
                <Typography variant="caption" color="text.disabled">
                  <Tooltip
                    placement="top"
                    enterDelay={500}
                    title={
                      truncatedDescription !== description ? description : ''
                    }
                    arrow
                  >
                    <span>{truncatedDescription}</span>
                  </Tooltip>
                  <Link
                    component={RouterLink}
                    to={automationEnginesPath()}
                    sx={{
                      ml: 0.5,
                      color: 'inherit',
                      textDecorationColor: 'inherit',
                      '&:hover': {
                        color: 'inherit',
                        textDecoration: 'none',
                      },
                    }}
                  >
                    {intl.formatMessage({
                      id: 'containers.settings.queues.engine.linkMore',
                    })}
                  </Link>
                </Typography>
              }
            />
          </Grid>

          {selectedQueue ? <QueueStats selectedQueue={selectedQueue} /> : null}
        </Grid>
      ) : null}
      {children}
    </PageLayoutV2>
  );
};

export default Queue;
