// Generated by ts-to-zod
import { z } from 'zod';
import {
  schemaRuleSchema,
  schemaRuleActionSchema,
} from '../../../rules/models/schemaRule.schema';
import { schemaSectionSchema } from '../../../schemas/models/schemaSection.schema';

export const suggestTriggerConditionSummaryPayloadSchema = z.object({
  userQuery: z.string().optional(),
  schemaContent: z.array(schemaSectionSchema),
  schemaRule: schemaRuleSchema.partial(),
});

export const suggestTriggerConditionSummaryResultSchema = z.object({
  description: z.string().optional(),
  enabled: z.boolean().optional(),
  triggerCondition: z.string().optional(),
  triggerConditionSummary: z.string().optional(),
  name: z.string().optional(),
  ruleActions: z.array(schemaRuleActionSchema.partial()).optional(),
});

export const suggestTriggerConditionSummaryResponseSchema = z.object({
  results: z.array(suggestTriggerConditionSummaryResultSchema),
});
