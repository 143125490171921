import { NavigateNextRounded } from '@rossum/ui/icons';
import {
  alpha,
  IconButton,
  Paper,
  Stack,
  StackProps,
} from '@rossum/ui/material';
import { LocationDescriptor } from 'history';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const TILE_HEIGHT = 56;

type ItemTileProps = StackProps & {
  to: LocationDescriptor<unknown>;
  quickActions?: ReactNode;
  children: ReactNode;
};

export const ItemTile = ({
  to,
  quickActions,
  children,
  ...rest
}: ItemTileProps) => {
  return (
    <Stack
      {...rest}
      component={Paper}
      direction="row"
      alignItems="stretch"
      position="relative"
      gap={0.5}
      px={1}
      sx={{
        '&:hover, &:focus-within': {
          backgroundColor: t => t.palette.action.selected,
          '.ItemTile-navigate': {
            opacity: 1,
          },
        },
        borderRadius: 1,
      }}
    >
      <Stack
        direction="row"
        sx={{
          flex: 1,
          height: TILE_HEIGHT,
          minWidth: 0,
        }}
      >
        {children}
        <Stack direction="row" alignItems="center" spacing={2}>
          {quickActions ?? null}
          <IconButton
            className="ItemTile-navigate"
            key="navigate"
            component={Link}
            to={to}
            sx={{
              opacity: 0,
              transition: t =>
                t.transitions.create('opacity', {
                  duration: t.transitions.duration.short,
                }),
              color: theme => theme.palette.text.primary,
              '&:hover': {
                color: theme => theme.palette.text.primary,
                textDecoration: 'none',
              },
            }}
          >
            <NavigateNextRounded
              fontSize="small"
              sx={{ color: t => alpha(t.palette.action.active, 0.56) }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
