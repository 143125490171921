import { Stack, Typography } from '@rossum/ui/material';
import { KeyboardShortcutChip } from './KeyboardShortcutButton';

// TODO: Systematize this component (colors etc.)
type KeyboardShortcutProps = { keyName?: string; description: string };

// TODO: Platform dependent mappings etc.
const keyNameLabelMap: { [k: string]: string } = {
  tab: 'Tab',
  enter: 'Enter',
  delete: 'Delete',
  escape: 'Esc',
  altOption: 'Alt/Option',
  altOptionSpace: 'Alt/Option + Space',
  shiftTab: 'Shift + Tab',
  ctrlShiftA: 'Ctrl + Shift + A',
  ctrlZ: 'Ctrl/Cmd + Z',
  ctrlShiftZ: 'Ctrl/Cmd + Shift + Z',
};

export const KeyboardShortcut = ({
  keyName,
  description,
}: KeyboardShortcutProps) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      component="span"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="inherit">{description}</Typography>
      {keyName && <KeyboardShortcutChip value={keyNameLabelMap[keyName]} />}
    </Stack>
  );
};
