import { Circle } from '@rossum/ui/icons';
import { Stack, SxProps, Theme, Typography } from '@rossum/ui/material';
import { FC } from 'react';
import { z, ZodObject, ZodRawShape } from 'zod';

type InputHeaderComponentProps<T> = {
  value: T;
};

type OutputHeaderComponentProps = {
  value?: unknown;
};

export const createHeader =
  <T extends ZodObject<ZodRawShape>>(
    Header: FC<InputHeaderComponentProps<z.TypeOf<T>>>,
    schema: T
  ) =>
  ({ value }: OutputHeaderComponentProps) => {
    const result = schema.safeParse(value ?? {});
    return result.success ? <Header value={result.data} /> : null;
  };

export const ActionHeader = ({
  entries,
}: {
  entries: { key: string; value: string; sx?: SxProps<Theme> }[];
}) => (
  <Stack
    direction="row"
    spacing={1}
    alignItems="center"
    divider={<Circle sx={{ width: 5, height: 5 }} />}
  >
    {entries.map(({ key, sx, value }) =>
      value ? (
        <Typography variant="caption" color="text.secondary" key={key} sx={sx}>
          {value}
        </Typography>
      ) : null
    )}
  </Stack>
);
