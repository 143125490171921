import { IAnnotation } from 'react-ace';
import { GeneratePayloadPayload, rossumStore } from '@rossum/api-client/hooks';
import { useQueryClient } from '@tanstack/react-query';
import equal from 'fast-deep-equal/es6/react';
import { get, invoke } from 'lodash';
import InfoIcon from 'mdi-react/InfoCircleIcon';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactAce from 'react-ace/lib/ace';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useGeneratePayload } from '../../../../business/hooks/generatePayload';
import { usePatchHook } from '../../../../business/hooks/usePatchHook';
import {
  QUERY_KEY_HOOK_TEST,
  useTestFunction,
} from '../../../../business/hooks/useTestFunction';
import Editor from '../../../../components/UI/Editor/components/Editor';
import { useTemporaryMessage } from '../../../../components/UI/Editor/components/hooks';
import TopMenu from '../../../../components/UI/Editor/components/TopMenu';
import PoweredBy from '../../../../components/UI/PoweredBy';
import UserPanel from '../../../../components/UserPanel';
import { absoluteApiUrl } from '../../../../constants/config';
import { useLeaveSafelyDialog } from '../../../../features/field-manager/field-manager/field-detail/hooks/useLeaveSafelyDialog';
import { selectedExtensionSelector } from '../../../../redux/modules/extensions/selectors';
import { HelmetComponent } from '../../../../routes/HelmetComponent';
import { ExtensionFunction } from '../../../../types/extensions';
import { State } from '../../../../types/state';
import {
  useJsonParse,
  usePrettyJsonString,
} from '../../../../utils/hooks/useJsonParse';
import {
  getEventTypesFromEvents,
  getIcon,
  visibleWebhookEvents,
} from '../../../Extensions/lib/helpers';
import { runtimesConfig } from '../../config';
import { formatCode, isPublicFunction } from '../../helpers';
import EditorFooter from './EditorFooter';
import styles from './style.module.sass';
import { EventNameOptions } from './types';

type StateProps = {
  selectedExtension: ExtensionFunction;
};

type Props = StateProps;

const eventNameOptions = [
  ...visibleWebhookEvents.map(event => event.name),
  'mySavedInput' as const,
];

// XXX haha sorry for the terrible name
const makeGeneratePayloadPayload = (
  objects: Partial<Record<EventNameOptions, string>>,
  event: EventNameOptions
) => {
  if (event === 'invocation') {
    return {
      event,
      action: 'scheduled',
    } as const satisfies GeneratePayloadPayload;
  }

  // This can do the right thing with either a plain id, frontend URL or API URL
  const objectRef = objects[event] || '';
  if (!objectRef) {
    // "Empty action" signifies we can't build a valid payload for this event now
    return { event, action: '' } as const satisfies GeneratePayloadPayload;
  }
  const objectId = objectRef.split('/').pop();

  if (event === 'annotation_status' || event === 'annotation_content') {
    return {
      event,
      action: event === 'annotation_status' ? 'changed' : 'user_update',
      annotation: `${absoluteApiUrl}/annotations/${objectId}`,
      status: 'to_review',
      previousStatus: 'importing',
    } as const satisfies GeneratePayloadPayload;
  }
  if (event === 'email') {
    return {
      event,
      action: 'received',
      email: `${absoluteApiUrl}/emails/${objectId}`,
    } as const satisfies GeneratePayloadPayload;
  }
  if (event === 'upload') {
    return {
      event,
      action: 'created',
      upload: `${absoluteApiUrl}/uploads/${objectId}`,
    } as const satisfies GeneratePayloadPayload;
  }
  return { event, action: '' } as const satisfies GeneratePayloadPayload;
};

const ExtensionEditor = ({ selectedExtension }: Props) => {
  const { mutate } = usePatchHook();
  const editor = useRef<ReactAce | null>(null);
  const inputRef = useRef<ReactAce | null>(null);
  const code = get(selectedExtension, 'config.code', '');
  const runtime = get(selectedExtension, ['config', 'runtime']);

  const events = get(selectedExtension, 'events');
  const assignedFunctionEventNames = getEventTypesFromEvents(events);

  const { leaveSafelyDialog, setDialogState, dialogState } =
    useLeaveSafelyDialog();

  const history = useHistory();

  const savedInput = get(selectedExtension, 'test.savedInput', '');

  const defaultInput = savedInput
    ? 'mySavedInput'
    : assignedFunctionEventNames[0];

  useEffect(() => {
    if (
      selectedExtension.config.runtime === 'nodejs22.x' ||
      selectedExtension.config.runtime === 'nodejs18.x'
    )
      invoke(editor, 'current.editor.session.$worker.call', 'changeOptions', [
        {
          esversion: 11,
          esnext: false,
        },
      ]);
  }, [editor, selectedExtension.config.runtime]);

  // Resizing prop
  const [footerResizing, setFooterResizing] = useState<boolean>(false);

  // Code editor state
  const [temporaryValue, setTemporaryValue] = useState<string>(code);
  const [isValid, setIsValid] = useState<boolean>(true);

  // Input editor state
  const [functionInputs, setFunctionInputs] = useState<{
    [key: string]: string;
  }>({});

  // text value of input editor
  const [inputValue, setInputValue] = useState<string>(
    functionInputs[defaultInput]
  );

  // parsed value of input editor if valid, null otherwise, is memoized against arguments
  const [parsedInputValue, parsingStatus] = useJsonParse<unknown>(
    inputValue,
    null
  );

  // prettified value of input editor if valid
  const [prettyInputValue, prettyStatus] = usePrettyJsonString(inputValue);

  const [isInputValid, setIsInputValid] = useState<boolean>(true);

  useEffect(() => {
    if (parsingStatus === 'error') {
      setIsInputValid(false);
    } else {
      setIsInputValid(true);
    }
  }, [parsingStatus]);

  const [inputErrors, setInputErrors] = useState<Array<IAnnotation>>([]);

  const { temporaryMessage, setTemporaryReverted, setTemporarySaved } =
    useTemporaryMessage();

  // Input editor Events
  const [currentEvent, setCurrentEvent] =
    useState<EventNameOptions>(defaultInput);
  const [currentObject, setCurrentObject] = useState<
    Partial<Record<EventNameOptions, string>>
  >({});
  const [showInputLoading, setShowInputLoading] = useState<boolean>(true);
  const [editorSaving, setEditorSaving] = useState<Record<string, boolean>>({
    isTopMenuSaving: false,
    isBoxMenuSaving: false,
  });

  const queryClient = useQueryClient();

  const generatePayloadPayload = useMemo<GeneratePayloadPayload>(() => {
    return makeGeneratePayloadPayload(currentObject, currentEvent);
  }, [currentObject, currentEvent]);

  // load sample payload
  const {
    // query disabled by default, need to run this function to execute it
    refetch: runGeneratePayloadQuery,
    data: generatedPayload,
    status: generatePayloadStatus,
  } = useGeneratePayload(get(selectedExtension, 'id'), generatePayloadPayload);

  // test function state
  const {
    // query disabled by default, need to run this function to execute it
    refetch: runTestFunctionQuery,
    data: testFunctionData,
    status: testFunctionStatus,
    isFetching: isFunctionRunning,
    // TODO: TBD - shouldn't we be getting id from route?
  } = useTestFunction(get(selectedExtension, 'id'), {
    config: {
      runtime,
      code: temporaryValue,
    },
    payload: parsedInputValue,
  });

  const runTestFunction = () => {
    if (parsingStatus === 'success') {
      runTestFunctionQuery();
    }
  };

  // simple string comparison is enough
  const isInputChanged = functionInputs[currentEvent] !== inputValue;

  const canSave = isInputChanged && !inputErrors.length && !isFunctionRunning;

  useEffect(() => {
    setCurrentEvent(defaultInput);
  }, [defaultInput]);

  useEffect(() => {
    if (
      generatePayloadPayload.event !== 'mySavedInput' &&
      generatePayloadPayload.action
    ) {
      // do not run if object is missing
      runGeneratePayloadQuery();
      setShowInputLoading(true);
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatePayloadPayload]);

  useEffect(() => {
    const generatedPayloadIsValid =
      generatePayloadPayload.event === currentEvent &&
      generatePayloadPayload.action &&
      generatePayloadStatus === 'success' &&
      generatedPayload;

    setFunctionInputs({
      mySavedInput: savedInput,
      ...(currentEvent !== 'mySavedInput'
        ? {
            [currentEvent]: generatedPayloadIsValid
              ? JSON.stringify(generatedPayload, undefined, 2)
              : '',
          }
        : {}),
    });
    setShowInputLoading(false);
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    savedInput,
    generatePayloadPayload,
    generatePayloadStatus,
    generatedPayload,
    currentEvent,
  ]);

  useEffect(() => {
    setInputValue(functionInputs[currentEvent] || '');
  }, [currentEvent, functionInputs]);

  useEffect(() => {
    setTemporaryValue(code);
  }, [code]);

  useEffect(() => {
    if (editor.current) {
      setTimeout(() => {
        invoke(editor, ['current', 'editor', 'resize']);
      }, 200);
    }
  }, [footerResizing]);

  useEffect(() => {
    // cancel retries for test function when code or input are changed
    // it's automatically cancelled when this component is unmounted
    queryClient.cancelQueries({
      queryKey: [QUERY_KEY_HOOK_TEST],
    });
  }, [queryClient, temporaryValue, inputValue]);

  const isChanged = temporaryValue !== code;
  const formattedCode = formatCode(temporaryValue);
  const isPending = get(selectedExtension, 'status') === 'pending';

  const resetCode = () => {
    setTemporaryReverted();
    setTemporaryValue(code);
  };

  const saveCode = () => {
    setEditorSaving({ isTopMenuSaving: true });
    setTemporarySaved();
    mutate(
      {
        hookId: selectedExtension.id,
        payload: {
          config: {
            code: temporaryValue,
            runtime,
          },
          type: selectedExtension.type,
        },
        meta: { withMessage: false },
      },
      {
        onSettled: () => {
          setEditorSaving({
            isTopMenuSaving: false,
          });
        },
      }
    );
  };

  const onChange = (value: string) => setInputValue(value);

  const onPrettify = () =>
    prettyStatus === 'success' && setInputValue(prettyInputValue);

  const onSave = () => {
    if (canSave) {
      setEditorSaving({ isBoxMenuSaving: true });
      mutate(
        {
          hookId: selectedExtension.id,
          payload: {
            test: { savedInput: inputValue },
            type: selectedExtension.type,
          },
          meta: { withMessage: true },
        },
        {
          onSettled: () => {
            setEditorSaving({
              isBoxMenuSaving: false,
            });
          },
        }
      );
    }
  };

  // eslint-disable-next-line react/hook-use-state
  const [readOnlyMessageVisible, displayReadOnlyMessage] =
    useState<boolean>(false);

  const readOnly = selectedExtension.extensionSource === rossumStore;

  const savingFailed = selectedExtension.status === 'failed';

  const location = useLocation();

  return (
    <div className={styles.Wrapper} data-page-title="extension-editor">
      <HelmetComponent
        dynamicName={selectedExtension.name}
        translationKey="features.routes.pageTitles.extensions.code"
      />
      <TopMenu
        onNavigateBack={() =>
          history.push({
            pathname: `/settings/extensions/${selectedExtension.id}`,
            state: location.state,
          })
        }
        canNavigateBack
        onPrettify={() => setTemporaryValue(formattedCode)}
        canPrettify={
          formattedCode !== temporaryValue &&
          runtimesConfig[runtime].canPrettify
        }
        onSave={saveCode}
        canSave={!isPending && (isChanged || savingFailed) && isValid}
        onReset={resetCode}
        canReset={!isPending && isChanged}
        title={
          <div className={styles.TitleWithIcon}>
            {getIcon(
              selectedExtension.type,
              isPublicFunction(selectedExtension)
                ? selectedExtension.config.runtime
                : undefined,
              {
                size: 22,
              }
            )}
            {get(selectedExtension, 'name')}
          </div>
        }
        isChanged={isChanged}
        dark
        isValid={isValid}
        savingFailed={savingFailed}
        right={
          <>
            <PoweredBy />
            <UserPanel />
          </>
        }
        editorType="function"
        isPending={isPending}
        temporaryMessage={temporaryMessage}
        isSaving={editorSaving.isTopMenuSaving}
      />
      <Editor
        name="extensions-editor"
        onChange={value => setTemporaryValue(value)}
        onFocus={() => {
          if (readOnly) {
            displayReadOnlyMessage(true);
          }
        }}
        onBlur={() => {
          if (readOnly) {
            displayReadOnlyMessage(false);
          }
        }}
        value={temporaryValue}
        forwardRef={editor}
        mode={runtimesConfig[runtime].editorMode}
        onValidate={annotations =>
          setIsValid(!annotations.some(({ type }) => type === 'error'))
        }
        readOnly={readOnly}
      >
        {readOnlyMessageVisible && (
          <div className={styles.ReadOnlyMessage}>
            <InfoIcon size={18} />
            <FormattedMessage id="components.editor.storeReadOnly" />
          </div>
        )}
      </Editor>
      <EditorFooter
        assignedFunctionEventNames={assignedFunctionEventNames}
        canRun={
          !isFunctionRunning &&
          !isPending &&
          isInputValid &&
          !equal(parsedInputValue, {})
        }
        canSave={canSave}
        onSave={onSave}
        isSaving={editorSaving.isBoxMenuSaving}
        currentFunctionOutput={testFunctionData}
        currentObject={currentObject[currentEvent] || ''}
        setCurrentObject={val =>
          setCurrentObject({ ...currentObject, [currentEvent]: val })
        }
        onLoad={() => {
          if (isInputChanged) {
            runGeneratePayloadQuery();
            setShowInputLoading(true);
          }
        }}
        editorFooter
        functionEvents={eventNameOptions}
        showInputLoading={showInputLoading}
        setShowInputLoading={setShowInputLoading}
        currentEvent={currentEvent}
        setCurrentEvent={setCurrentEvent}
        setFooterResizing={setFooterResizing}
        inputErrors={inputErrors}
        inputRef={inputRef}
        inputTmpValue={inputValue}
        isInputValid={isInputValid}
        isRunning={isFunctionRunning}
        onChange={onChange}
        onPrettify={onPrettify}
        runtime={runtime}
        savedInput={savedInput}
        setInputErrors={setInputErrors}
        setIsInputValid={setIsInputValid}
        testFunctionError={testFunctionStatus === 'error'}
        testFunction={runTestFunction}
      />
      {leaveSafelyDialog}
      <Prompt
        message={location => {
          if (dialogState) {
            setDialogState(null);
            return true;
          }

          setDialogState({
            key: 'notSavedChanges',
            onConfirm: () => history.replace(location),
          });

          return false;
        }}
        when={isChanged || isInputChanged}
      />
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  selectedExtension: selectedExtensionSelector(state) as ExtensionFunction,
});

const ExtensionEditorRoute = (props: Props) => {
  const { replace } = useHistory();
  const extensionHasEditor = props.selectedExtension?.type === 'function';

  useEffect(() => {
    if (props.selectedExtension && !extensionHasEditor)
      replace({
        pathname: `/settings/extensions/${props.selectedExtension.id}`,
      });
  }, [replace, extensionHasEditor, props.selectedExtension]);

  return extensionHasEditor ? <ExtensionEditor {...props} /> : null;
};

export default connect<StateProps, null, Record<string, never>, State>(
  mapStateToProps
)(ExtensionEditorRoute);
