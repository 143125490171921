import { IconSearch } from '@rossum/ui/icons/tabler';
import { IconButton, SvgIcon } from '@rossum/ui/material';
import { NonAdminRestrictor } from '../Restrictors';

type SearchButtonProps = {
  onClick: () => void;
};

export const SearchButton = ({ onClick }: SearchButtonProps) => {
  return (
    <NonAdminRestrictor>
      <IconButton size="medium" color="secondary" onClick={onClick}>
        <SvgIcon fontSize="small">
          <IconSearch />
        </SvgIcon>
      </IconButton>
    </NonAdminRestrictor>
  );
};
