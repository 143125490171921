import { endpoints, getIDFromUrl, loadEtag, Url } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useApiClient } from '../../lib/apiClient';

export const SCHEMA_QUERY_KEY = 'schema';

const getSchemaWithEtag =
  (api: ReturnType<typeof useApiClient>, schemaUrl: Url | undefined) => () =>
    api
      .fullRequest(endpoints.schemas.get(getIDFromUrl(schemaUrl!)))
      .then(loadEtag);

export type SchemaWithEtag = Awaited<
  ReturnType<ReturnType<typeof getSchemaWithEtag>>
>;

export const useSchema = <TReturnedData = SchemaWithEtag>(
  schemaUrl: Url | undefined,
  queryOptions: Omit<
    UseQueryOptions<
      SchemaWithEtag,
      ElisClientError,
      TReturnedData,
      readonly [typeof SCHEMA_QUERY_KEY, string | undefined]
    >,
    'queryKey' | 'queryFn' | 'enabled'
  > = {}
) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [SCHEMA_QUERY_KEY, schemaUrl] as const,
    queryFn: getSchemaWithEtag(api, schemaUrl),
    enabled: schemaUrl !== undefined,
    ...queryOptions,
  });
};
