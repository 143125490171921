import { SchemaRule, SchemaRuleAction } from '../../../rules/models/schemaRule';
import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  suggestTriggerConditionPayloadSchema,
  suggestTriggerConditionResponseSchema,
} from './suggestTriggerCondition.schema';

export type SuggestTriggerConditionPayload = {
  userQuery: string;
  schemaContent: SchemaSection[];
  schemaRule: Partial<SchemaRule>;
};

export type SuggestTriggerConditionResult = {
  triggerCondition?: string;
  triggerConditionSummary?: string;
};

export type SuggestTriggerConditionResponse = {
  results: Array<SuggestTriggerConditionResult>;
};

export const suggestTriggerCondition = (
  payload: SuggestTriggerConditionPayload
) =>
  ({
    endpoint: `internal/rules/suggest_trigger_condition`,
    method: 'POST',
    responseSchema: suggestTriggerConditionResponseSchema,
    payloadSchema: suggestTriggerConditionPayloadSchema,
    payload,
  }) as const;
