import { getIDFromUrl } from '@rossum/api-client';
import { SchemaAction } from '@rossum/api-client/schemas';
import { DriveFileMoveOutlined } from '@rossum/ui/icons';
import { Checkbox, FormControlLabel, Skeleton } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { useWorkspacesWithQueues } from '../../queues/hooks/useWorkspacesWithQueues';
import { QueueSelect } from '../../queues/select-queue/QueueSelect';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const moveDocumentActionPayloadSchema = z.object({
  queue_id: z.number().nullable().default(null),
  reimport: z.boolean().default(false),
});

const MoveDocumentActionForm = createForm(
  ({ value, onChange }) => {
    const { isLoading, queues } = useWorkspacesWithQueues({
      enableQueries: true,
    });

    const stableValue = useMemo(
      () => queues?.find(queue => value.queue_id === queue.id) ?? null,
      [queues, value]
    );

    const intl = useIntl();

    return isLoading ? (
      <Skeleton height={40} />
    ) : (
      <>
        <QueueSelect
          value={stableValue?.url ?? null}
          onChange={queueUrl =>
            onChange({
              ...value,
              queue_id: queueUrl ? getIDFromUrl(queueUrl) : null,
            })
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={value.reimport === true}
              onClick={() => onChange({ ...value, reimport: !value.reimport })}
              size="small"
              edge="start"
            />
          }
          label={intl.formatMessage({
            id: 'features.queueSettings.rules.actions.moveDocument.reimport.label',
          })}
        />
      </>
    );
  },

  moveDocumentActionPayloadSchema
);

const MoveDocumentActionHeader = createHeader(
  ({ value }) => (
    <ActionHeader
      entries={[
        {
          key: 'queue_id',
          value: `${value.queue_id ?? ''}`,
          sx: {
            fontFamily: 'monospace',
          },
        },
      ]}
    />
  ),
  moveDocumentActionPayloadSchema
);
export const moveDocumentAction = {
  icon: DriveFileMoveOutlined,
  resolveAction: (a: SchemaAction) => a.type === 'change_queue',
  serialized: { type: 'change_queue' },
  intlKey: 'moveDocument' as const,
  groupIntlKey: 'documentActions' as const,
  form: MoveDocumentActionForm,
  header: MoveDocumentActionHeader,
};
