import { Page } from '@rossum/api-client/pages';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Rectangle2DCoordinates } from '../../features/annotation-view/document-canvas/utils/geometry';
import {
  useCanvasSelectionActions,
  useDocumentStore,
} from '../../features/annotation-view/document-store/DocumentStore';
import { useDocumentContext } from '../../features/annotation-view/DocumentContext';
import {
  createDatapointWithPosition,
  recountDatapointPosition,
  selectDatapoint,
  updatePosition,
} from '../../redux/modules/datapoints/actions';
import { findDatapointById } from '../../redux/modules/datapoints/navigation/findDatapointIndex';
import {
  currentDatapointSelector,
  currentMultivalueDatapointSelector,
  datapointsSelector,
} from '../../redux/modules/datapoints/selector';
import {
  recountSuggestedOperationPositionAction,
  updateSuggestedOperationPositionAction,
} from '../../redux/modules/datapoints/suggestedOperations/actions';
import { isSimpleDatapoint } from '../../redux/modules/datapoints/typedHelpers';
import { isFieldSelectable } from '../../redux/modules/schema/helpers';
import { SimpleDatapointDataST } from '../../types/datapoints';
import { useEventCallback } from '../../utils/hooks/useEventCallback';

export const useBoundingBoxCallbacks = (page: Page) => {
  const dispatch = useDispatch();

  const { setCanvasActionInProgress } = useDocumentContext();

  const selectedDatapointIds = useDocumentStore(state => state.selectedBboxes);
  const { removeFromSelectedBboxes, clearSelectedBboxes, addToSelectedBboxes } =
    useCanvasSelectionActions();

  const currentMultivalueDatapoint = useSelector(
    currentMultivalueDatapointSelector
  );
  const currentDatapoint = useSelector(currentDatapointSelector);
  const allDatapoints = useSelector(datapointsSelector);

  const handleSuggestedOperationMoved = useCallback(
    (operationId: number, position: Rectangle2DCoordinates) => {
      dispatch(
        updateSuggestedOperationPositionAction(operationId, {
          content: { position },
        })
      );

      dispatch(recountSuggestedOperationPositionAction(operationId));
    },
    [dispatch]
  );

  // resizing bbox
  const handleSuggestedOperationResized = useCallback(
    (operationId: number, position: Rectangle2DCoordinates) => {
      dispatch(
        updateSuggestedOperationPositionAction(operationId, {
          content: { position },
        })
      );

      dispatch(recountSuggestedOperationPositionAction(operationId));
    },
    [dispatch]
  );

  // resizing bbox
  const handleBoundingBoxResized = useCallback(
    (datapointId: number, newPosition: Rectangle2DCoordinates) => {
      const datapoint = findDatapointById(allDatapoints, datapointId);

      if (datapoint && isSimpleDatapoint(datapoint)) {
        dispatch(
          updatePosition(
            { index: datapoint.meta.index, page },
            { content: { position: newPosition } }
          )
        );
        dispatch(
          recountDatapointPosition(datapoint.meta.index, {
            oldValue: datapoint.content?.value ?? '',
            reason: 'resize-bbox',
          })
        );
      }
      setCanvasActionInProgress(false);
    },
    [allDatapoints, dispatch, page, setCanvasActionInProgress]
  );

  const handleBoundingBoxMoved = useCallback(
    (datapointId: number, newPosition: Rectangle2DCoordinates) => {
      const datapoint = findDatapointById(allDatapoints, datapointId);

      if (datapoint && isSimpleDatapoint(datapoint)) {
        dispatch(
          updatePosition(
            { index: datapoint.meta.index, page },
            { content: { position: newPosition } }
          )
        );
        dispatch(
          recountDatapointPosition(datapoint.meta.index, {
            oldValue: datapoint.content?.value ?? '',
            reason: 'move-bbox',
          })
        );
      }

      setCanvasActionInProgress(false);
    },
    [allDatapoints, dispatch, page, setCanvasActionInProgress]
  );

  // Sucks this depends on currentDatapoint value, this means every keystroke will
  // rerender all SuggestionBoxes. Can this be avoided?
  const handleSuggestedBoxClicked = useEventCallback(
    (position: Rectangle2DCoordinates) => {
      // Any datapoint is selected
      if (currentDatapoint) {
        // Creating new datapoint in simple multivalue if parent is selected
        if (currentMultivalueDatapoint?.meta.isSimpleMultivalue) {
          if (
            (currentDatapoint as SimpleDatapointDataST)?.content?.value ||
            currentMultivalueDatapoint?.id === currentDatapoint?.id
          ) {
            dispatch(
              createDatapointWithPosition(
                currentMultivalueDatapoint.meta.index,
                {
                  page,
                  position,
                }
              )
            );
          } else if (
            // Ideally, this situation shouldn't occur. However, it's possible for a user to open a document
            // via url containing a datapointPath that points to a non-editable datapoint. In such cases, if the datapoint
            // has no value, we should prevent the handler from filling in the value by clicking on the suggested bounding box.
            isFieldSelectable(currentDatapoint.schema)
          ) {
            dispatch(
              updatePosition(
                { index: currentDatapoint.meta.index, page },
                { content: { position } }
              )
            );
            dispatch(
              recountDatapointPosition(currentDatapoint.meta.index, {
                oldValue: (currentDatapoint as SimpleDatapointDataST)?.content
                  ?.value,
                reason: 'suggested-bbox',
              })
            );
          }
        } else if (
          isSimpleDatapoint(currentDatapoint) &&
          // Ideally, this situation shouldn't occur. However, it's possible for a user to open a document
          // via url containing a datapointPath that points to a non-editable datapoint. In such cases, if the datapoint
          // has no value, we should prevent the handler from filling in the value by clicking on the suggested bounding box.
          isFieldSelectable(currentDatapoint.schema)
        ) {
          dispatch(
            updatePosition(
              { index: currentDatapoint.meta.index, page },
              { content: { position } }
            )
          );
          dispatch(
            recountDatapointPosition(currentDatapoint.meta.index, {
              oldValue: currentDatapoint.content?.value,
              reason: 'suggested-bbox',
            })
          );
        }
      }
    }
  );

  const handleSelectBbox = useCallback(
    (e: React.MouseEvent<SVGRectElement, MouseEvent>, datapointId: number) => {
      if (e.shiftKey) {
        if (selectedDatapointIds.includes(datapointId)) {
          removeFromSelectedBboxes([datapointId]);
        } else {
          if (
            currentDatapoint?.id &&
            currentDatapoint?.category === 'datapoint'
          ) {
            addToSelectedBboxes([datapointId, currentDatapoint?.id]);
          }

          addToSelectedBboxes([datapointId]);
        }
      } else {
        dispatch(selectDatapoint([datapointId]));
        clearSelectedBboxes();
      }
    },
    [
      addToSelectedBboxes,
      clearSelectedBboxes,
      currentDatapoint?.category,
      currentDatapoint?.id,
      dispatch,
      removeFromSelectedBboxes,
      selectedDatapointIds,
    ]
  );

  return {
    handleSuggestedOperationMoved,
    handleSuggestedOperationResized,
    handleBoundingBoxMoved,
    handleBoundingBoxResized,
    handleSuggestedBoxClicked,
    handleSelectBbox,
  };
};
