import { Annotation } from '@rossum/api-client/annotations';
import { Document } from '@rossum/api-client/documents';
import { GridColDef } from '@rossum/ui/x-data-grid-pro';
import { Dictionary, fromPairs } from 'lodash';
import { useMemo } from 'react';
import { useCalculatePreview } from '../hooks/useCalculatePreview';
import { useCalculateRulesPreview } from '../hooks/useCalculateRulesPreview';
import { RowModel } from './helpers';
import { transformDataToRowsFormula } from './transformDataToRowsFormula';
import { transformDataToRowsRules } from './transformDataToRowsRule';

type UseRowsParams = {
  pageSize: number;
  annotations: Annotation[] | undefined;
  documents: Document[] | undefined;
  columns: Array<GridColDef<RowModel>>;
  evaluationQueriesFormula?: ReturnType<typeof useCalculatePreview>;
  evaluationQueriesRules?: ReturnType<typeof useCalculateRulesPreview>;
};

type TransformDataToRowsParams = {
  columns: Array<GridColDef<RowModel>>;
  annotations: Annotation[];
  documentsMap: Dictionary<string>;
  evaluationQueriesFormula: ReturnType<typeof useCalculatePreview>;
  evaluationQueriesRules: ReturnType<typeof useCalculateRulesPreview>;
};

export const transformDataToRows = (
  params: TransformDataToRowsParams
): Array<RowModel> => {
  if (
    params.evaluationQueriesFormula &&
    params.evaluationQueriesFormula.length
  ) {
    return transformDataToRowsFormula(params);
  }
  if (params.evaluationQueriesRules && params.evaluationQueriesRules.length) {
    return transformDataToRowsRules(params);
  }

  return [];
};

export const useRows = ({
  columns,
  annotations,
  documents,
  evaluationQueriesFormula,
  evaluationQueriesRules,
}: UseRowsParams): Array<RowModel> => {
  const documentsMap: Dictionary<string> = useMemo(
    () =>
      fromPairs(
        documents?.map(({ url, originalFileName }) => [
          url,
          originalFileName ?? '',
        ]) ?? []
      ),
    [documents]
  );

  return annotations
    ? transformDataToRows({
        columns,
        annotations,
        documentsMap,
        evaluationQueriesFormula: evaluationQueriesFormula ?? [],
        evaluationQueriesRules: evaluationQueriesRules ?? [],
      })
    : [];
};
