import { FunctionRuntime } from '@rossum/api-client/hooks';
import { ExpandLess, ExpandMore, Warning } from '@rossum/ui/icons';
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import clsx from 'clsx';
import { format, isAfter } from 'date-fns';
import { filter, get } from 'lodash';
import { Control, FieldValues, useController } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import TextFieldControl from '../../../../../components/ReactHookForm/controls/TextFieldControl';
import { DEV_FEATURES_ENABLED } from '../../../../../constants/config';
import { docsLinks } from '../../../../../constants/values';
import { dateFnsLocales } from '../../../../../i18n';
import { link } from '../../../../../lib/formaterValues';
import { useLocalStorageBooleanFlag } from '../../../../../lib/hooks';
import { EXTENSIONS_JSON_CONFIG_EXPANDED } from '../../../../../redux/modules/localStorage/actions';
import { userIsSupportSelector } from '../../../../../redux/modules/user/selectors';
import { Url } from '../../../../../types/basic';
import {
  Extension,
  sideloadOptions,
  SideloadValues,
} from '../../../../../types/extensions';
import { UserList } from '../../../../../types/user';
import {
  runtimesConfig,
  runtimeTypesConfig,
} from '../../../../Extension/config';
import { isPublicFunction } from '../../../../Extension/helpers';
import FormLabel from '../../../../User/components/FormLabel';
import UsersDropdown from '../../../components/UsersDropdown';
import { useJSONfield } from '../../../lib/useJSONfield';
import DebugConfigAppInput from '../../ConfigApp/components/DebugConfigAppInput';
import ConfigAppButton from '../../ConfigApp/ConfigAppButton';
import { getExtensionConfigAppUrl } from '../../ConfigApp/helpers';
import styles from '../style.module.sass';
import DeprecatedRuntimeAlert from './DeprecatedRuntimeAlert';
import JsonInput from './JsonInput';
import RuntimeChangeAlert from './RuntimeChangeAlert';

type Props<T extends FieldValues> = {
  allOrgUsers: UserList;
  filteredUsers: UserList;
  settingsJSONField: ReturnType<typeof useJSONfield>;
  secretsJSONField: ReturnType<typeof useJSONfield>;
  searchUsersValue: string;
  setFilteredUsers: (_users: UserList) => void;
  setSearchUsersValue: (_search: string) => void;
  setShowAdvancedSettings: (_show: boolean) => void;
  showAdvancedSettings: boolean;
  control: Control<T>;
  isFromStore?: boolean;
  readMoreUrl?: string | null;
  name?: string;
  selectedExtension?: Extension;
  showSecretsSection?: boolean;
};

const SETTINGS_INPUT_HEIGHT = 320;
const SECRETS_INPUT_HEIGHT = 121;

const AdvancedSettings = <T extends FieldValues>({
  allOrgUsers,
  filteredUsers,
  settingsJSONField,
  secretsJSONField,
  searchUsersValue,
  setFilteredUsers,
  setSearchUsersValue,
  setShowAdvancedSettings,
  showAdvancedSettings,
  control,
  isFromStore,
  readMoreUrl,
  name,
  selectedExtension,
  showSecretsSection,
}: Props<T>) => {
  // WORKAROUND
  // When I tried to add this as a constraint to the generic parameter:
  // T extends { sideload: any[], tokenOwner: any[] } it doesn't work
  const controlTyped = control as unknown as Control<{
    sideload: SideloadValues[];
    tokenOwner: string | null;
    runtime: FunctionRuntime | undefined;
    payloadLoggingEnabled: boolean;
  }>;

  const {
    field: { onChange: onChangeSideload, value: sideloadValue },
  } = useController({ name: 'sideload', control: controlTyped });

  const {
    field: { value: tokenOwner, onChange: onTokenOwnerChange },
  } = useController({ name: 'tokenOwner', control: controlTyped });

  const {
    field: { value: runtime },
  } = useController({ name: 'runtime', control: controlTyped });

  const {
    field: { value: payloadLoggingEnabled, onChange: onPayloadLoggingChange },
  } = useController({
    name: 'payloadLoggingEnabled',
    control: controlTyped,
  });

  const userIsSupport = useSelector(userIsSupportSelector);
  const intl = useIntl();
  const locale = get(dateFnsLocales, intl.locale);

  const configAppUrl = getExtensionConfigAppUrl(selectedExtension);
  const [jsonConfigExpanded, setJsonConfigExpanded] =
    useLocalStorageBooleanFlag(EXTENSIONS_JSON_CONFIG_EXPANDED, false);

  const shouldRenderJsonFields = !configAppUrl || jsonConfigExpanded;

  const settingsLabel = intl.formatMessage({
    id: 'containers.settings.extensions.createExtension.configuration.label',
  });

  const isExtensionPublicFunction =
    selectedExtension && isPublicFunction(selectedExtension);
  const selectedRuntimeType =
    (isExtensionPublicFunction &&
      runtimesConfig[selectedExtension.config.runtime].type) ??
    null;

  const functionRuntimeConfig =
    isExtensionPublicFunction &&
    runtimesConfig[selectedExtension.config.runtime];

  const selectedRuntimeDeprecationDate =
    (functionRuntimeConfig &&
      'deprecationDate' in functionRuntimeConfig &&
      functionRuntimeConfig.deprecationDate) ??
    undefined;

  return (
    <Box sx={{ mt: 2 }}>
      {!isFromStore && (
        <Button
          color="secondary"
          size="small"
          startIcon={showAdvancedSettings ? <ExpandLess /> : <ExpandMore />}
          data-cy="extensions-show-advanced-settings"
          onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
        >
          <FormattedMessage id="containers.settings.extensions.createExtension.button.showAdvanced" />
        </Button>
      )}
      {(isFromStore || showAdvancedSettings) && (
        <div>
          {!isFromStore && (
            <>
              <div className={styles.FormLabel}>
                <FormLabel>
                  <FormattedMessage id="containers.settings.extensions.createExtension.additionalMetadata.label" />
                </FormLabel>
              </div>
              <div className={styles.SubLabel}>
                <FormattedMessage
                  id="containers.settings.extensions.createExtension.additionalMetadata.subLabel"
                  values={{ link: link(docsLinks.webhookVsConnector) }}
                />
              </div>
              {sideloadOptions.map(option => (
                <div className={styles.SideloadToggle} key={option}>
                  <Switch
                    size="small"
                    data-cy="extensions-advanced-sideload-toggle"
                    checked={sideloadValue.includes(option)}
                    onChange={() => {
                      const newSideload = sideloadValue.includes(option)
                        ? [...filter(sideloadValue, opt => opt !== option)]
                        : [...sideloadValue, option];
                      return onChangeSideload(newSideload);
                    }}
                  />
                  <span className={styles.SideloadToggleText}>
                    <FormattedMessage
                      id={`containers.settings.extensions.createExtension.additionalMetadata.${option}`}
                    />
                  </span>
                </div>
              ))}
            </>
          )}
          {isExtensionPublicFunction &&
            selectedRuntimeType &&
            runtimeTypesConfig[selectedRuntimeType].options.length > 1 && (
              <Box sx={{ mb: 4 }}>
                <div>
                  <div className={styles.FormLabel}>
                    <FormattedMessage id="containers.settings.extensions.function.runtime.label" />
                  </div>
                  <div className={styles.SubLabel}>
                    <FormattedMessage id="containers.settings.extensions.function.runtime.subLabel" />
                  </div>
                </div>

                <Stack spacing={2}>
                  <TextFieldControl
                    ControllerProps={{
                      control: controlTyped,
                      name: 'runtime',
                    }}
                    SelectProps={{ IconComponent: ExpandMore }}
                    select
                  >
                    {runtimeTypesConfig[selectedRuntimeType].options.map(
                      runtimeVersion => {
                        const runtimeVersionConfig =
                          runtimesConfig[runtimeVersion];
                        const runtimeDeprecationDate =
                          'deprecationDate' in runtimeVersionConfig &&
                          runtimeVersionConfig.deprecationDate;
                        const optionDisabled =
                          !!runtimeDeprecationDate &&
                          isAfter(new Date(Date.now()), runtimeDeprecationDate);

                        return (
                          <MenuItem
                            key={runtimeVersion}
                            value={runtimeVersion}
                            disabled={optionDisabled}
                          >
                            <Stack
                              spacing={1}
                              alignItems="center"
                              direction="row"
                            >
                              <span>{runtimeVersion}</span>
                              {!!runtimeDeprecationDate && (
                                <Tooltip
                                  // do not allow to click on the tooltip when the option is disabled
                                  disableInteractive={optionDisabled}
                                  title={intl.formatMessage(
                                    {
                                      id: 'containers.settings.extensions.function.runtime.deprecatedWarning.tooltip',
                                    },
                                    {
                                      deprecatedDate: format(
                                        runtimeDeprecationDate,
                                        'PP',
                                        { locale }
                                      ),
                                    }
                                  )}
                                >
                                  <Warning
                                    sx={{
                                      fontSize: 16,
                                      // open tooltip eventhough the MenuItem is disabled
                                      pointerEvents: 'all',
                                    }}
                                    onClick={e => {
                                      // don't trigger the MenuItem onClick for disabled options
                                      if (optionDisabled) {
                                        e.stopPropagation();
                                      }
                                    }}
                                    color="warning"
                                  />
                                </Tooltip>
                              )}
                            </Stack>
                          </MenuItem>
                        );
                      }
                    )}
                  </TextFieldControl>
                  {selectedExtension.config.runtime !== runtime && (
                    <RuntimeChangeAlert />
                  )}
                  {!!selectedRuntimeDeprecationDate && (
                    <DeprecatedRuntimeAlert
                      selectedRuntime={selectedExtension.config.runtime}
                      runtimeDeprecationDate={selectedRuntimeDeprecationDate}
                    />
                  )}
                </Stack>
              </Box>
            )}
          {/* Allow to select tokenOwner for support access even for Store extensions */}
          {((isFromStore && userIsSupport) || !isFromStore) && (
            <>
              <div className={styles.FormLabel}>
                <FormLabel>
                  <FormattedMessage id="containers.settings.extensions.createExtension.tokenOwner.label" />
                </FormLabel>
              </div>
              <div className={styles.LabelWithOptional}>
                <div className={clsx(styles.SubLabel, styles.SubLabelLimited)}>
                  <FormattedMessage
                    id="containers.settings.extensions.createExtension.tokenOwner.subLabel"
                    values={{ link: link(docsLinks.tokenOwner) }}
                  />
                </div>
                <span className={styles.OptionalWithSubLabel}>
                  <FormattedMessage id="containers.settings.extensions.createExtension.tokenOwner.fieldDescription" />
                </span>
              </div>
              <UsersDropdown
                allOrgUsers={allOrgUsers}
                onBlur={() => setSearchUsersValue('')}
                selectedUser={tokenOwner as Url}
                setSelectedUser={onTokenOwnerChange}
                filteredUsers={filteredUsers}
                setFilteredUsers={setFilteredUsers}
                setSearchValue={setSearchUsersValue}
                searchValue={searchUsersValue}
              />
            </>
          )}
          <div className={styles.FormLabel}>
            <FormLabel>
              {intl.formatMessage({
                id: 'containers.settings.extensions.createExtension.payloadLoggingEnabled.title',
              })}
            </FormLabel>
          </div>
          <div className={styles.SubLabel}>
            <FormLabel>
              {intl.formatMessage({
                id: 'containers.settings.extensions.createExtension.payloadLoggingEnabled.description',
              })}
            </FormLabel>
          </div>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Switch
              size="small"
              data-cy="payload-logging-enabled"
              checked={payloadLoggingEnabled}
              onChange={(_, value) => onPayloadLoggingChange(value)}
            />
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'containers.settings.extensions.createExtension.payloadLoggingEnabled.label',
              })}
            </Typography>
          </Stack>
          {!!configAppUrl && (
            <>
              <Stack
                mt={4}
                mb={4}
                direction="row"
                justifyContent="space-between"
              >
                <ConfigAppButton />
                <Button
                  color="secondary"
                  size="small"
                  endIcon={jsonConfigExpanded ? <ExpandLess /> : <ExpandMore />}
                  onClick={() => setJsonConfigExpanded(!jsonConfigExpanded)}
                >
                  {intl.formatMessage({
                    id: jsonConfigExpanded
                      ? 'containers.settings.extensions.configApp.hideJson'
                      : 'containers.settings.extensions.configApp.displayJson',
                  })}
                </Button>
              </Stack>
              {DEV_FEATURES_ENABLED && selectedExtension && (
                <DebugConfigAppInput extension={selectedExtension} />
              )}
            </>
          )}

          {shouldRenderJsonFields && (
            <>
              <div className={styles.FormLabel}>
                <FormLabel>{settingsLabel}</FormLabel>
              </div>
              <div className={styles.SubLabel}>
                <FormattedMessage
                  id={
                    showSecretsSection
                      ? 'containers.settings.extensions.createExtension.configuration.subLabelNoSecrets'
                      : 'containers.settings.extensions.createExtension.configuration.subLabel'
                  }
                  values={{
                    link: link(
                      isFromStore && readMoreUrl
                        ? readMoreUrl
                        : docsLinks.hookConfiguration
                    ),
                  }}
                />
              </div>
              <JsonInput
                name="extensions-settings-input"
                height={SETTINGS_INPUT_HEIGHT}
                jsonFieldProps={settingsJSONField}
                title={name ? [name, settingsLabel].join(' ') : settingsLabel}
                expandable
              />
              {showSecretsSection && (
                <>
                  <div className={styles.FormLabel}>
                    <FormLabel>
                      <FormattedMessage id="containers.settings.extensions.createExtension.secrets.label" />
                    </FormLabel>
                  </div>
                  <div className={styles.SubLabel}>
                    <FormattedMessage
                      id="containers.settings.extensions.createExtension.secrets.subLabel"
                      values={{
                        link: link(docsLinks.hookSecrets),
                      }}
                    />
                  </div>
                  <JsonInput
                    name="extensions-secrets-input"
                    height={SECRETS_INPUT_HEIGHT}
                    jsonFieldProps={secretsJSONField}
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
    </Box>
  );
};

export default AdvancedSettings;
