import { domainSettings } from './domainSettings';
import { generateRejectionEmail } from './generateRejectionEmail';
import { requestDemo } from './requestDemo';
import { requestExtension } from './requestExtension';
import { evaluateFormulas } from './schemas/evaluateFormulas';
import { formulasInfo } from './schemas/formulasInfo';
import { suggestFormula } from './schemas/suggestFormula';
import { formulaSummary } from './schemas/formulaSummary';
import { suggestRule } from './rules/suggestRule';
import { evaluateRules } from './rules/evaluateRules';
import { suggestTriggerCondition } from './rules/suggestTriggerCondition';
import { suggestTriggerConditionSummary } from './rules/suggestTriggerConditionSummary';
export { type RequestDemoPayload } from './requestDemo';
export { type GenerateRejectionPayload } from './generateRejectionEmail';
export { type RequestExtensionPayload } from './requestExtension';
export { type DomainSettingsSsoProvider } from './domainSettings';
export { type SuggestFormulaResponse } from './schemas/suggestFormula';
export { type SuggestTriggerConditionResponse } from './rules/suggestTriggerCondition';
export { type SuggestTriggerConditionResult } from './rules/suggestTriggerCondition';
export { type SuggestRuleResponse } from './rules/suggestRule';
export {
  type AutomationBlocker,
  type EvaluateFormulasResponse,
} from './schemas/evaluateFormulas';
export { type EvaluateRulesResponse } from './rules/evaluateRules';

const schemasEndpoints = {
  evaluateFormulas,
  formulasInfo,
  suggestFormula,
  formulaSummary,
};

const rulesEndpoints = {
  suggestRule,
  suggestTriggerCondition,
  suggestTriggerConditionSummary,
  evaluateRules,
};

/**
 * @noSchema
 */
type SchemasEndpoints = typeof schemasEndpoints;

const internalSchemas: {
  schemas: SchemasEndpoints;
} = {
  schemas: {
    ...schemasEndpoints,
  },
};

/**
 * @noSchema
 */
type RulesEndpoints = typeof rulesEndpoints;

const internalRules: {
  rules: RulesEndpoints;
} = {
  rules: { ...rulesEndpoints },
};

export const internal = {
  requestDemo,
  requestExtension,
  domainSettings,
  generateRejectionEmail,
  ...internalSchemas,
  ...internalRules,
};
