import { endpoints, ID } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';
import { RULES_QUERY_KEY } from './useListRules';

export const useDeleteRule = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([RULES_QUERY_KEY]);
    },
    mutationFn: ({ deletedRuleId }: { deletedRuleId: ID }) =>
      api.request(endpoints.rules.delete(deletedRuleId)),
  });
};
