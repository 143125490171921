import { ID } from '../../utils/codecUtils';
import { schemaRuleSchema } from '../models/schemaRule.schema';

export const get = (ruleId: ID) => {
  return {
    endpoint: `/rules/${ruleId}`,
    method: 'GET',
    responseSchema: schemaRuleSchema,
  } as const;
};
