import { IconKeyFilled, IconUserFilled } from '@rossum/ui/icons/tabler';
import Header from '../../../components/Sidebar/Header';
import SidebarWrapper from '../../../components/Sidebar/Sidebar';
import SidebarNavLink from '../../../components/Sidebar/SidebarNavLink';

const Sidebar = () => (
  <SidebarWrapper>
    <Header title="containers.personalInfo.title" />
    <SidebarNavLink
      icon={<IconUserFilled />}
      text="containers.personalInfo.personalInformation"
      to="/account/personalInfo"
      replace
      dataCy="my-profile-personal-settings"
    />
    <SidebarNavLink
      icon={<IconKeyFilled />}
      text="containers.personalInfo.changePassword"
      to="/account/changePassword"
      replace
      dataCy="my-profile-change-password"
    />
  </SidebarWrapper>
);

export default Sidebar;
