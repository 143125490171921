import { ID } from '../../utils/codecUtils';
import { SchemaRule } from '../models/schemaRule';
import { schemaRuleSchema } from '../models/schemaRule.schema';
import { rulePatchPayloadSchema } from './patch.schema';

export type RulePatchPayload = Partial<SchemaRule>;

export const patch = (ruleId: ID, payload: RulePatchPayload) => {
  return {
    endpoint: `/rules/${ruleId}`,
    method: 'PATCH',
    responseSchema: schemaRuleSchema,
    payloadSchema: rulePatchPayloadSchema,
    payload,
  } as const;
};
