import { Check } from '@rossum/ui/icons';
import {
  Button,
  CircularProgress,
  Slide,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  acceptSuggestedOperationsAction,
  declineSuggestedOperationsAction,
} from '../../../redux/modules/datapoints/suggestedOperations/actions';

export const SuggestedOperationsActions = ({
  tuplesCount,
  suggestedOperationsOptions,
  suggestingOperations,
  datapointPath,
}: {
  tuplesCount: number;
  suggestedOperationsOptions: {
    rowsCount: number;
    schemaIds: string[];
  };
  suggestingOperations: boolean;
  datapointPath: number[];
}) => {
  const dispatch = useDispatch();

  return (
    <Slide
      in={!!suggestedOperationsOptions.rowsCount}
      direction="up"
      unmountOnExit
    >
      <Stack
        direction="column"
        spacing={1}
        sx={{
          position: 'absolute',
          bottom: 'calc(100% + 20px)',
          backgroundColor: theme => theme.palette.background.paper,
          px: 2,
          py: 1,
          borderRadius: 2,
        }}
      >
        <Typography variant="body2">
          <FormattedMessage
            id="containers.footer.suggestedOperations"
            values={{
              operationsCount: suggestedOperationsOptions.rowsCount,
              rowsCount: tuplesCount,
            }}
          />
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => dispatch(declineSuggestedOperationsAction())}
            disabled={suggestingOperations}
          >
            <FormattedMessage id="containers.footer.decline" />
          </Button>
          <Button
            variant="contained"
            size="small"
            startIcon={
              suggestingOperations ? (
                <CircularProgress color="inherit" size={14} />
              ) : (
                <Check />
              )
            }
            onClick={() =>
              dispatch(acceptSuggestedOperationsAction({ datapointPath }))
            }
            disabled={suggestingOperations}
          >
            <FormattedMessage id="containers.footer.apply" />
          </Button>
        </Stack>
      </Stack>
    </Slide>
  );
};
