import { endpoints, Url } from '@rossum/api-client';
import { SchemaRule } from '@rossum/api-client/schemas';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';
import { RULES_QUERY_KEY } from './useListRules';

export const useCreateRule = (schemaUrl: Url) => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([RULES_QUERY_KEY]);
    },
    mutationFn: (rule: Partial<SchemaRule>) =>
      api.request(
        endpoints.rules.create({
          ...rule,
          schema: schemaUrl,
        })
      ),
  });
};
