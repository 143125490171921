import { Modal, ModalTitle } from '@rossum/rossum-ui/Modal';
import { Button, CircularProgress } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { modalWidth } from '../../../../constants/values';
import styles from '../styles.module.sass';

type Props = {
  canSave: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  isLoading?: boolean;
};

/** @deprecated use LeavingDialog */
const LeavingModal = ({
  canSave,
  onCancel,
  onClose,
  onSubmit,
  open,
  isLoading,
}: Props) => (
  <Modal isOpen={open} width={modalWidth} exitAnimation="ZoomOut">
    <ModalTitle
      text={
        <FormattedMessage id="components.modal.editor.leavingModal.title" />
      }
      onCancel={onClose}
    />

    <div className={styles.LeavingModalDescription}>
      <FormattedMessage id="components.modal.editor.leavingModal.text" />
      {!canSave && (
        <div className={styles.LeavingModalCantSave}>
          <FormattedMessage id="components.modal.editor.leavingModal.cantSave" />
        </div>
      )}
    </div>
    <div className={styles.ModalControls}>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onCancel}
        disabled={isLoading}
      >
        <FormattedMessage id="components.modal.editor.leavingModal.cancel" />
      </Button>
      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={!canSave || isLoading}
        startIcon={isLoading ? <CircularProgress size={18} /> : null}
      >
        <FormattedMessage id="components.modal.editor.leavingModal.confirm" />
      </Button>
    </div>
  </Modal>
);

export default LeavingModal;
