import { endpoints } from '@rossum/api-client';
import { Organization } from '@rossum/api-client/organization';
import { Queue } from '@rossum/api-client/queues';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  SchemaWithEtag,
  useSchema,
} from '../../../../business/schema/useSchema';
import { api } from '../../../../lib/apiClient';
import { getFlattenSchemaFields } from '../../../schemas/getFlattenSchemaFields';
import {
  QUERY_KEY_ORGANIZATION,
  useFetchOrganization,
} from '../../hooks/useFetchOrganization';

type Props = {
  orgId: number;
  queueId: number;
};
export const QUERY_KEY_USE_QUEUE_TABLE = 'queue-table-config';

const emptyTable = { columns: [] };

const getFlattenSchemaFieldsMap = (schema: SchemaWithEtag) =>
  schema.content ? getFlattenSchemaFields(schema.content) : {};

export const useTableConfig = ({ orgId, queueId }: Props) => {
  const isQueueLevel = !!queueId;

  const { data: organization, isInitialLoading: isOrgTableLoading } =
    useFetchOrganization({
      enabled: !isQueueLevel,
      orgId,
    });

  const { data: queue, isFetching: isQueueTableLoading } = useQuery({
    queryKey: [QUERY_KEY_USE_QUEUE_TABLE, queueId],
    queryFn: () => api.request(endpoints.queues.get(queueId)),
    enabled: isQueueLevel,
  });

  const { data: schemaFieldsMap, isFetching: isSchemaFetching } = useSchema(
    queue?.schema,
    { select: getFlattenSchemaFieldsMap }
  );

  const withScoreThreshold = queue?.settings.annotationListTable?.columns.map(
    column => {
      if (
        column.columnType === 'schema' &&
        schemaFieldsMap &&
        schemaFieldsMap[column.schemaId]
      ) {
        const field = schemaFieldsMap[column.schemaId]?.field;
        const scoreThreshold =
          field && 'scoreThreshold' in field
            ? field.scoreThreshold
            : queue?.defaultScoreThreshold;

        return {
          ...column,
          scoreThreshold,
        };
      }

      return column;
    }
  );

  const queueTable = queue?.settings.annotationListTable
    ? {
        ...queue?.settings.annotationListTable,
        columns: withScoreThreshold ?? [],
      }
    : emptyTable;
  const orgTable = organization?.settings.annotationListTable ?? emptyTable;

  return {
    tableConfig: isQueueLevel ? queueTable : orgTable,
    isLoading: isQueueTableLoading || isSchemaFetching || isOrgTableLoading,
  };
};

export const useSetTableConfig = () => {
  const queryClient = useQueryClient();

  const setOrganizationTable = useCallback(
    (
      callback: (response: Organization | undefined) => Organization | undefined
    ) =>
      queryClient.setQueriesData<Organization | undefined>(
        [QUERY_KEY_ORGANIZATION],
        response => callback(response)
      ),
    [queryClient]
  );

  const setQueueTable = useCallback(
    (callback: (response: Queue | undefined) => Queue | undefined) =>
      queryClient.setQueriesData<Queue | undefined>(
        [QUERY_KEY_USE_QUEUE_TABLE],
        response => callback(response)
      ),
    [queryClient]
  );

  return { setOrganizationTable, setQueueTable };
};
