import { SchemaSection } from '@rossum/api-client/schemas';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CopilotDialog } from '../../../ui/copilot-dialog/CopilotDialog';
import {
  narrowRuleSuggestionResultToTemplate,
  RuleTemplate,
  useSuggestRule,
} from '../hooks/useSuggestRule';

type InitialCopilotProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (response: RuleTemplate) => void;
  schemaContent: SchemaSection[];
};

export const InitialCopilot = ({
  open,
  onClose,
  onSuccess,
  schemaContent,
}: InitialCopilotProps) => {
  const intl = useIntl();
  const { mutate, isLoading, isError, data } = useSuggestRule();

  const onSubmit = useCallback(
    (search: string) => {
      if (search) {
        mutate(
          {
            search,
            schemaContent,
          },
          {
            onSuccess: data => {
              const parsedData = narrowRuleSuggestionResultToTemplate(data);

              const response = parsedData[0];

              if (response) {
                onSuccess(response);
              }
            },
          }
        );
      }
    },
    [mutate, onSuccess, schemaContent]
  );

  return (
    <CopilotDialog
      title={intl.formatMessage({
        id: 'features.queueSettings.rules.initialCopilot.title',
      })}
      subtitle={intl.formatMessage({
        id: 'features.queueSettings.rules.initialCopilot.subtitle',
      })}
      errorText={intl.formatMessage({
        id: 'features.queueSettings.rules.initialCopilot.error',
      })}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      isError={isError || data?.results.length === 0}
      placeholders={([1, 2, 3, 4, 5, 6] as const).map(i =>
        intl.formatMessage({
          id: `features.queueSettings.rules.initialCopilot.placeholders.${i}`,
        })
      )}
      closeText={intl.formatMessage({
        id: 'features.queueSettings.rules.initialCopilot.close',
      })}
      confirmText={intl.formatMessage({
        id: 'features.queueSettings.rules.initialCopilot.confirm',
      })}
    />
  );
};
