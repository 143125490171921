import { Engine } from '@rossum/api-client/engines';
import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AutomationBreadcrumbs } from '../../../containers/Automation/AutomationBreadcrumbs';
import { automationEnginesPath } from '../../../containers/Automation/helpers';
import {
  restrictedEngineCreationEnabledSelector,
  restrictedEngineEditEnabledSelector,
} from '../../../redux/modules/organizationGroup/selectors';
import CopyToClipboardButton from '../../../ui/copy-to-clipboard/CopyToClipboardButton';
import { DeleteConfirmationDialog } from '../../../ui/delete-confirmation-dialog/DeleteConfirmationDialog';
import { Header, HeaderProps } from '../../../ui/header/Header';
import DuplicateEngineDialog from '../components/engine-duplicate/DuplicateEngineDialog';
import { ENGINE_FORM_ID } from '../constants';
import { useDeleteEngine } from '../hooks/useDeleteEngine.ts';
import { useQueuesForEngine } from '../hooks/useQueuesForEngine';
import { basePath } from '../paths';

type EngineDetailHeaderProps = { engine: Engine; isSaving: boolean } & Pick<
  HeaderProps,
  'scrollableDivRef'
>;

export const EngineDetailHeader = ({
  engine,
  isSaving,
  ...rest
}: EngineDetailHeaderProps) => {
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const isRestrictedEngineCreationEnabled = useSelector(
    restrictedEngineCreationEnabledSelector
  );

  const isRestrictedEngineEditEnabled = useSelector(
    restrictedEngineEditEnabledSelector
  );

  const intl = useIntl();
  const { mutate: deleteEngine, isLoading: isDeletingEngine } =
    useDeleteEngine();

  const { data } = useQueuesForEngine({
    engine: engine?.id,
  });

  const totalCount = data?.pagination?.total ?? 0;
  const deleteDisabled = totalCount !== undefined && totalCount > 0;

  const history = useHistory();

  return (
    <Header
      {...rest}
      breadcrumbs={
        <AutomationBreadcrumbs
          breadcrumbs={[
            {
              label: intl.formatMessage({
                id: 'components.appBar.menu.automation.aiEngines',
              }),
              to: automationEnginesPath(),
            },
            {
              label: engine.name,
            },
          ]}
        />
      }
      title={engine.name}
      description={
        <Stack spacing={3}>
          <span>{engine.description}</span>
          <Stack direction="row" alignItems="center" gap={1}>
            <span>
              {intl.formatMessage({
                id: 'features.engines.header.description.engineId',
              })}
              {': '}
            </span>
            <Typography variant="body2" color="text.primary">
              {engine.agendaId}
            </Typography>
            <CopyToClipboardButton
              content={`${engine.agendaId}`}
              iconColor="secondary.main"
            />
          </Stack>
        </Stack>
      }
      buttons={
        isRestrictedEngineEditEnabled
          ? []
          : [
              <Fragment key="delete">
                <Tooltip
                  title={
                    deleteDisabled
                      ? intl.formatMessage({
                          id: 'features.engines.header.buttons.delete.disabledTooltip',
                        })
                      : ''
                  }
                >
                  <span>
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={deleteDisabled || isDeletingEngine}
                      data-cy="delete-button"
                      onClick={() => setDeleteDialogOpen(true)}
                      startIcon={
                        isDeletingEngine && (
                          <CircularProgress color="inherit" size={20} />
                        )
                      }
                    >
                      {intl.formatMessage({
                        id: 'features.engines.header.buttons.delete',
                      })}
                    </Button>
                  </span>
                </Tooltip>
                <DeleteConfirmationDialog
                  title={intl.formatMessage({
                    id: `features.engines.dialog.deleteEngine.title`,
                  })}
                  description={intl.formatMessage({
                    id: `features.engines.dialog.deleteEngine.text`,
                  })}
                  open={deleteDialogOpen}
                  onCancel={() => setDeleteDialogOpen(false)}
                  onConfirm={() => {
                    setDeleteDialogOpen(false);
                    deleteEngine(engine.id, {
                      onSuccess: () => history.push(basePath),
                    });
                  }}
                />
              </Fragment>,
              <Fragment key="duplicate">
                <Tooltip
                  title={
                    isRestrictedEngineCreationEnabled
                      ? intl.formatMessage({
                          id: 'features.engines.creation.disabled',
                        })
                      : ''
                  }
                >
                  <span>
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={isRestrictedEngineCreationEnabled}
                      onClick={() => setDuplicateDialogOpen(true)}
                      data-cy="duplicate-engine-button"
                    >
                      {intl.formatMessage({
                        id: 'features.engines.header.buttons.duplicate',
                      })}
                    </Button>
                  </span>
                </Tooltip>
                <DuplicateEngineDialog
                  engine={engine}
                  open={duplicateDialogOpen}
                  closeDialog={() => setDuplicateDialogOpen(false)}
                />
              </Fragment>,
              <Button
                key="save_engine"
                variant="contained"
                type="submit"
                form={ENGINE_FORM_ID}
                startIcon={
                  isSaving && <CircularProgress color="inherit" size={20} />
                }
                disabled={isSaving}
                data-cy="submit-form"
              >
                {intl.formatMessage({
                  id: 'features.engines.header.buttons.save',
                })}
              </Button>,
            ]
      }
      onBackButtonClicked={() => history.push(basePath)}
    />
  );
};
