import { assertNever } from '../../../../lib/typeUtils';
import {
  AnyDatapointDataST,
  MultivalueDatapointDataST,
  SectionDatapointDataST,
  SimpleDatapointDataST,
  TupleDatapointDataFromSelector,
  TupleDatapointDataST,
} from '../../../../types/datapoints';
import { State } from '../../../../types/state';
import { isFieldSelectable } from '../../schema/helpers';
import { schemaMapSelector } from '../../schema/schemaMapSelector';
import { ResolvedDatapointPath } from './resolvedDatapointPath';

export type NavigationStop =
  | {
      kind: 'simple-datapoint-in-sidebar';
      path: [SectionDatapointDataST, SimpleDatapointDataST];
    }
  | {
      kind: 'simple-multivalue';
      path: [SectionDatapointDataST, MultivalueDatapointDataST];
    }
  | {
      kind: 'simple-multivalue-add-value';
      path: [SectionDatapointDataST, MultivalueDatapointDataST];
    }
  | {
      kind: 'simple-multivalue-child';
      path: [
        SectionDatapointDataST,
        MultivalueDatapointDataST,
        SimpleDatapointDataST,
      ];
    }
  | {
      kind: 'table-multivalue';
      path: [SectionDatapointDataST, MultivalueDatapointDataST];
    }
  | {
      kind: 'table-multivalue-add-value';
      path: [SectionDatapointDataST, MultivalueDatapointDataST];
    }
  | {
      kind: 'table-multivalue-child';
      path: [
        SectionDatapointDataST,
        MultivalueDatapointDataST,
        TupleDatapointDataST,
        SimpleDatapointDataST,
      ];
    }
  | {
      kind: 'none';
    };

export type NavigationStopNotNone = Exclude<NavigationStop, { kind: 'none' }>;

type SimpleMultivalueNavigationStop = Extract<
  NavigationStop,
  {
    kind:
      | 'simple-multivalue'
      | 'simple-multivalue-add-value'
      | 'simple-multivalue-child';
  }
>;

type TableeMultivalueNavigationStop = Extract<
  NavigationStop,
  {
    kind:
      | 'table-multivalue'
      | 'table-multivalue-add-value'
      | 'table-multivalue-child';
  }
>;
type MultivalueNavigationStop =
  | SimpleMultivalueNavigationStop
  | TableeMultivalueNavigationStop;

export const isMultivalue = (
  path: NavigationStop
): path is MultivalueNavigationStop => {
  return path.kind !== 'simple-datapoint-in-sidebar' && path.kind !== 'none';
};

export const isSimpleMultivalue = (
  path: NavigationStop
): path is SimpleMultivalueNavigationStop => {
  return (
    path.kind === 'simple-multivalue' ||
    path.kind === 'simple-multivalue-child' ||
    path.kind === 'simple-multivalue-add-value'
  );
};

export const toNavigationStop = (
  path: ResolvedDatapointPath | null,
  addValue = false
): NavigationStop => {
  if (!path) return { kind: 'none' };

  if (path.length === 4) {
    return { kind: 'table-multivalue-child', path };
  }

  if (path.length === 3) {
    const last = path[2];

    if (last.category === 'tuple') {
      // Tuple is not considered a navigation stop
      return { kind: 'none' };
    }
    return { kind: 'simple-multivalue-child', path: [path[0], path[1], last] };
  }

  if (path.length === 2) {
    const last = path[1];

    if (last.category === 'datapoint') {
      return { kind: 'simple-datapoint-in-sidebar', path: [path[0], last] };
    }

    if (last.meta.isSimpleMultivalue) {
      return {
        kind: addValue ? 'simple-multivalue-add-value' : 'simple-multivalue',
        path: [path[0], last],
      };
    }
    return {
      kind: addValue ? 'table-multivalue-add-value' : 'table-multivalue',
      path: [path[0], last],
    };
  }

  // Section is not considered a navigation stop
  return { kind: 'none' };
};

export function getSidebarDatapoint<T extends NavigationStopNotNone>(
  stop: T
): SimpleDatapointDataST | MultivalueDatapointDataST;
export function getSidebarDatapoint<T extends NavigationStop>(
  stop: T
): SimpleDatapointDataST | MultivalueDatapointDataST | null;
export function getSidebarDatapoint(
  stop: NavigationStop | NavigationStopNotNone
) {
  return 'path' in stop ? stop.path[1] : null;
}

export function getSelectedDatapoint<T extends NavigationStopNotNone>(
  stop: T
): SimpleDatapointDataST | MultivalueDatapointDataST;
export function getSelectedDatapoint<T extends NavigationStop>(
  stop: T
): SimpleDatapointDataST | MultivalueDatapointDataST | null;
export function getSelectedDatapoint(
  stop: NavigationStop | NavigationStopNotNone
) {
  if (!('path' in stop)) return null;

  // Writing it this way instead of path[path.lenght - 1] allow TS
  // to properly infer types.
  if (stop.path.length === 4) return stop.path[3];
  if (stop.path.length === 3) return stop.path[2];
  if (stop.path.length === 2) return stop.path[1];

  assertNever(stop.path);
  return null;
}

/**
 * Button datapoints are tricky - usually you want to skip them when navigating,
 * however you can still select them, by navigating in the grid with arrows
 * (or by using their datapointPath directly).
 */
export const isButtonDatapoint = (stop: NavigationStop) => {
  const sidebarDatapoint = getSelectedDatapoint(stop);

  return (
    sidebarDatapoint &&
    sidebarDatapoint.category === 'datapoint' &&
    sidebarDatapoint.content === null
  );
};

export const isSkippedAddValue = (stop: NavigationStop, state: State) => {
  if (stop.kind === 'table-multivalue-add-value') {
    // There's no point in stopping twice at an empty multivalue, so we just skip "add value" in this case.
    // The add value button will be focused anyway, see implementation in shouldFocusAddValueSelector
    return stop.path[1].children.length === 0;
  }

  if (stop.kind === 'simple-multivalue-add-value') {
    const hasNoChildren = stop.path[1].children.length === 0;
    if (hasNoChildren) {
      return true;
    }

    const schemas = schemaMapSelector(state);
    const childrenSchemaId = stop.path[1].schema?.children?.[0];
    const childrenSchema = childrenSchemaId
      ? schemas.get(childrenSchemaId)
      : undefined;
    const isNonSelectable = !isFieldSelectable(childrenSchema);

    // In case "Add value" button is hidden in the UI based on selectable condition,
    // we should skip stopping on it.
    return isNonSelectable;
  }

  return false;
};

export const findNextColumnInTuple = ({
  datapoints,
  currentTuple,
  startIndex,
  isForward,
}: {
  datapoints: AnyDatapointDataST[];
  currentTuple: TupleDatapointDataFromSelector;
  startIndex: number;
  isForward: boolean;
}): number | undefined => {
  const newIndex = isForward ? startIndex + 1 : startIndex - 1;
  const nextDatapoint = currentTuple.children[newIndex];

  const nextDatapointSchema = nextDatapoint?.index
    ? datapoints[nextDatapoint?.index]?.schema
    : undefined;

  const isSelectable = isFieldSelectable(nextDatapointSchema);

  return isSelectable
    ? nextDatapoint?.id
    : findNextColumnInTuple({
        datapoints,
        currentTuple,
        startIndex: newIndex,
        isForward,
      });
};
