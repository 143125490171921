import { SchemaAction } from '@rossum/api-client/schemas';
import {
  Autocomplete,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
} from '@rossum/ui/material';
import { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLabelsEnabled } from '../../labels/hooks/useLabelsEnabled';
import { knownActionGroups, visibleActions } from '../actions';

type ActionAutocompleteProps = {
  value: SchemaAction | undefined;
  onChange: (s: SchemaAction | undefined) => void;
};

export const ActionAutocomplete = ({
  value,
  onChange,
}: ActionAutocompleteProps) => {
  const labelsEnabled = useLabelsEnabled();
  const intl = useIntl();

  const stableValue = useMemo(
    () =>
      value
        ? visibleActions.find(action => action.resolveAction(value)) ?? null
        : null,
    [value]
  );

  const stableOptions = useMemo(
    () =>
      visibleActions.filter(a => a.groupIntlKey !== 'labels' || labelsEnabled),
    [labelsEnabled]
  );

  return (
    <Autocomplete
      value={stableValue}
      onChange={(_, value) => onChange(value?.serialized)}
      disablePortal
      getOptionLabel={opt =>
        intl.formatMessage({
          id: `features.queueSettings.rules.actions.${opt.intlKey}.name`,
        })
      }
      options={stableOptions}
      renderOption={(props, { intlKey, icon: Icon }) => (
        <ListItem {...props}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText>
            {intl.formatMessage({
              id: `features.queueSettings.rules.actions.${intlKey}.name`,
            })}
          </ListItemText>
        </ListItem>
      )}
      groupBy={action => action.groupIntlKey}
      renderGroup={({ group, children, key }) => {
        const knownGroup = knownActionGroups.find(g => g === group);
        return knownGroup ? (
          <Fragment key={key}>
            <ListSubheader
              disableSticky
              sx={{
                borderTop: theme => `1px solid ${theme.palette.divider}`,
                mt: 1,
                '&:first-of-type': { borderTop: 0, mt: 0 },
              }}
            >
              {intl.formatMessage({
                id: `features.queueSettings.rules.actionGroup.${knownGroup}`,
              })}
            </ListSubheader>
            {children}
          </Fragment>
        ) : null;
      }}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          label={intl.formatMessage({
            id: `features.queueSettings.rules.action`,
          })}
        />
      )}
      disableClearable={value !== null}
    />
  );
};
