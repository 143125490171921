import {
  BasicColumn,
  IconColumn,
  LevelColumn,
  TooltipColumn,
} from './components/Columns';

export const LOG_PADDING_SPACING = 2;
export const LOG_ARROW_WIDTH_SPACING = 3;

export const logsTabs = ['request', 'response', 'detail'] as const;
export type LogTab = (typeof logsTabs)[number];

export const statusMap = {
  '2xx': ['200', '201', '202', '203', '204', '205', '206', '207', '208', '226'],
  '4xx': [
    '400',
    '401',
    '402',
    '403',
    '404',
    '405',
    '406',
    '407',
    '408',
    '409',
    '410',
    '411',
    '412',
    '413',
    '414',
    '415',
    '416',
    '417',
    '418',
    '421',
    '422',
    '423',
    '424',
    '425',
    '426',
    '428',
    '429',
    '431',
    '451',
  ],
  '5xx': [
    '500',
    '501',
    '502',
    '503',
    '504',
    '505',
    '506',
    '507',
    '508',
    '510',
    '511',
  ],
} as const;

export const logsColumns = [
  {
    name: 'timestamp',
    width: '23%',
    maxWidth: 270,
    Wrapper: BasicColumn,
  },
  {
    name: 'logLevel',
    width: '8%',
    maxWidth: 85,
    Wrapper: LevelColumn,
  },
  {
    name: 'extension',
    width: '20%',
    maxWidth: 250,
    Wrapper: IconColumn,
  },
  {
    name: 'event',
    width: '19%',
    maxWidth: 320,
    Wrapper: BasicColumn,
  },
  {
    name: 'message',
    width: 'auto',
    maxWidth: '30%',
    Wrapper: TooltipColumn,
  },
] as const;

export type LogContentType = (typeof logsTabs)[number];
