import { SuggestTriggerConditionResult } from '@rossum/api-client/internal';
import { SchemaRule, SchemaSection } from '@rossum/api-client/schemas';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CopilotDialog } from '../../../ui/copilot-dialog/CopilotDialog';
import {
  narrowTriggerConditionSuggestionResultToTemplate,
  useSuggestTriggerCondition,
} from '../hooks/useSuggestTriggerCondition';

type TriggerCopilotProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (response: SuggestTriggerConditionResult) => void;
  schemaContent: SchemaSection[];
  schemaRule: Partial<SchemaRule>;
};

export const TriggerCopilot = ({
  open,
  onClose,
  onSuccess,
  schemaContent,
  schemaRule,
}: TriggerCopilotProps) => {
  const intl = useIntl();
  const { mutate, isLoading, isError, data } = useSuggestTriggerCondition();

  const onSubmit = useCallback(
    (search: string) => {
      if (search) {
        mutate(
          {
            search,
            schemaRule,
            schemaContent,
          },
          {
            onSuccess: data => {
              const parsedData =
                narrowTriggerConditionSuggestionResultToTemplate(data);

              const response = parsedData[0];

              if (response) {
                onSuccess(response);
              }
            },
          }
        );
      }
    },
    [mutate, onSuccess, schemaContent, schemaRule]
  );

  return (
    <CopilotDialog
      title={intl.formatMessage({
        id: 'features.queueSettings.rules.triggerCopilot.title',
      })}
      subtitle={intl.formatMessage({
        id: 'features.queueSettings.rules.triggerCopilot.subtitle',
      })}
      errorText={intl.formatMessage({
        id: 'features.queueSettings.rules.triggerCopilot.error',
      })}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      isError={isError || data?.results.length === 0}
      placeholders={([1, 2, 3] as const).map(i =>
        intl.formatMessage({
          id: `features.queueSettings.rules.triggerCopilot.placeholders.${i}`,
        })
      )}
      closeText={intl.formatMessage({
        id: 'features.queueSettings.rules.triggerCopilot.close',
      })}
      confirmText={intl.formatMessage({
        id: 'features.queueSettings.rules.triggerCopilot.confirm',
      })}
    />
  );
};
