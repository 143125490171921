import { SchemaAction } from '@rossum/api-client/schemas';
import { IconCircleDashedCheck } from '@rossum/ui/icons/tabler';
import { Autocomplete, TextField } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { getSchemaFields } from '../../formulas/FormulaEditor/helpers';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const addValidationSourceActionPayloadSchema = z.object({
  schema_ids: z.array(z.string()).default([]),
});

const AddValidationSourceActionForm = createForm(
  ({ value, onChange, schemaContent }) => {
    const intl = useIntl();
    const schemaFieldOptions = useMemo(
      () => getSchemaFields(schemaContent),
      [schemaContent]
    );
    const schemaField = useMemo(
      () => schemaFieldOptions.filter(f => value.schema_ids.includes(f.id)),
      [schemaFieldOptions, value.schema_ids]
    );

    return (
      <Autocomplete
        multiple
        size="small"
        options={schemaFieldOptions}
        getOptionLabel={option => `${option.label} (${option.id})`}
        value={schemaField}
        onChange={(_e, vs) => {
          onChange({ ...value, schema_ids: vs.map(v => v.id) });
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'features.queueSettings.rules.actions.addValidationSource.form.fields',
            })}
            placeholder={intl.formatMessage({
              id: 'features.queueSettings.rules.actions.addValidationSource.form.chooseFields',
            })}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    );
  },
  addValidationSourceActionPayloadSchema
);

const AddValidationSourceActionHeader = createHeader(
  ({ value }) => (
    <ActionHeader
      entries={[
        {
          key: 'schema_ids',
          value: value.schema_ids.join(', '),
        },
      ]}
    />
  ),
  addValidationSourceActionPayloadSchema
);

export const addValidationSourceAction = {
  icon: IconCircleDashedCheck,
  intlKey: 'addValidationSourceAction' as const,
  resolveAction: (a: SchemaAction) => a.type === 'validation_source',
  serialized: { type: 'validation_source' },
  groupIntlKey: 'addValidationSourceAction' as const,
  form: AddValidationSourceActionForm,
  header: AddValidationSourceActionHeader,
};
