import { AddCircleOutline, RemoveCircleOutline } from '@rossum/ui/icons';
import {
  alpha,
  Button,
  IconButton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ADVANCED_COMPONENT_WIDTH } from '../../../../document-list-base/mql/constants';
import { FilterOperator } from '../../../../document-list-base/mql/operators';
import {
  AdvancedFilterComponentProps,
  SimpleFilterItem,
} from '../../../../document-list-base/mql/types';
import ActionButtons from '../ActionButtons';
import { getDefaultFilterRulesState } from '../FilterChip/utils';
import {
  isOperatorEmpty,
  resolveValueBasedOnBooleanOperator,
} from '../Operators/utils';
import { InputSelector } from './InputSelector';
import { SelectOperators } from './SelectOperators';

export const AdvancedFilterComponent = ({
  filterItem,
  applyFilter,
  onClose,
}: AdvancedFilterComponentProps) => {
  const intl = useIntl();
  const { column } = filterItem;

  const [filterRules, setFilterRules] = useState(
    getDefaultFilterRulesState(filterItem)
  );

  const selectedOperatorValues = filterRules.map(
    ({ operator }) => operator.value
  );

  const areAllOperatorsSelected =
    filterRules.length === column.operators.length;

  const doesEmptyValueExist = filterRules.some(
    ({ value, operator }) => !value && !isOperatorEmpty(operator)
  );

  const handleOperatorChange = (
    newOperator: FilterOperator,
    previousOperator: FilterOperator
  ) => {
    const updatedContext = filterRules.map(item => {
      const shouldBeUpdated = item.operator.value === previousOperator.value;
      return {
        ...item,
        operator: shouldBeUpdated ? newOperator : item.operator,
        value:
          shouldBeUpdated && isOperatorEmpty(newOperator)
            ? resolveValueBasedOnBooleanOperator(newOperator)
            : item.value,
      };
    });

    setFilterRules(updatedContext);
  };

  const handleFilterChange = (
    filterContext: SimpleFilterItem['filterContext']
  ) => {
    const updatedContext = filterRules.map(item => ({
      ...item,
      value:
        item.operator.value === filterContext.operator.value
          ? filterContext.value
          : item.value,
    }));
    setFilterRules(updatedContext);
  };

  const addFilterRule = () => {
    const operatorCandidate = column.operators.filter(
      op => !selectedOperatorValues.includes(op.value)
    )[0];

    if (operatorCandidate)
      setFilterRules(prev => prev.concat({ operator: operatorCandidate }));
  };

  const shouldFocusIndex = filterRules[
    filterRules.length === 2 ? 'findIndex' : 'findLastIndex'
  ](filter => !filter?.value);

  return (
    <Stack
      p={2}
      width={ADVANCED_COMPONENT_WIDTH}
      spacing={2}
      component="form"
      onSubmit={e => {
        e.preventDefault();
        applyFilter(filterRules);
        onClose();
      }}
    >
      {column.headerName && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {column.headerName}
        </Typography>
      )}
      <Stack spacing={2}>
        {filterRules.map((filterContext, index) => {
          return (
            <Stack
              key={filterContext.operator.value}
              direction="row"
              spacing={1}
              alignSelf="flex-end"
            >
              {index > 0 ? (
                <Typography variant="body2" sx={{ alignSelf: 'center' }}>
                  {intl.formatMessage({
                    id: 'containers.filtering.filterRuleSeparator.and',
                  })}
                </Typography>
              ) : null}
              <SelectOperators
                operators={column.operators}
                selectedOperators={selectedOperatorValues}
                operator={filterContext.operator}
                onOperatorChange={operator =>
                  handleOperatorChange(operator, filterContext.operator)
                }
                headerName={column.headerName || ''}
              />
              <InputSelector
                handleFilterChange={handleFilterChange}
                column={column}
                filterContext={filterContext}
                autoFocus={shouldFocusIndex === index}
              />
              <IconButton
                color="secondary"
                sx={{
                  alignSelf: 'center',
                  color: 'text.disabled',
                  '&:hover': {
                    backgroundColor: theme =>
                      alpha(theme.palette.text.disabled, 0.08),
                  },
                }}
                disabled={filterRules.length === 1}
                onClick={() =>
                  setFilterRules(prev =>
                    prev.filter(
                      prevContext =>
                        prevContext.operator.value !==
                        filterContext.operator.value
                    )
                  )
                }
              >
                <RemoveCircleOutline fontSize="small" />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>
      <ActionButtons
        onCancel={onClose}
        isSubmitDisabled={doesEmptyValueExist}
        secondaryButton={
          <Button
            variant="text"
            color="secondary"
            startIcon={<AddCircleOutline />}
            sx={{ textWrap: 'nowrap' }}
            onClick={addFilterRule}
            disabled={areAllOperatorsSelected}
            data-cy="all-documents-advanced-filter-add-rule-button"
          >
            {intl.formatMessage({
              id: 'containers.filtering.buttons.addFilterRule',
            })}
          </Button>
        }
        dataCyKey={`advanced-${column.field}`}
      />
    </Stack>
  );
};
