import { Url } from '@rossum/api-client';
import { Autocomplete, TextField } from '@rossum/ui/material';
import { forwardRef, useMemo, useState } from 'react';
import { useRequestUnpaginatedLabels } from '../hooks/useRequestLabels';

type LabelsAutocompleteProps = {
  value: Url[];
  onChange: (values: Url[]) => void;
  label: string;
  placeholder: string;
};

export const LabelsAutocomplete = forwardRef(
  ({ value, onChange, label, placeholder }: LabelsAutocompleteProps, ref) => {
    const [inputValue, setInputValue] = useState<string>('');

    const { isSuccess, data } = useRequestUnpaginatedLabels();

    const stableValue = useMemo(
      () => data?.filter(label => value.includes(label.url)) ?? [],
      [data, value]
    );

    return isSuccess ? (
      <Autocomplete
        multiple
        ref={ref}
        size="small"
        options={data}
        getOptionLabel={option => `${option.name}`}
        value={stableValue}
        onChange={(_e, value) => {
          onChange(value.map(v => v.url));
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
          />
        )}
        inputValue={inputValue}
        onInputChange={(_e, newInputValue) => {
          setInputValue(newInputValue);
        }}
      />
    ) : null;
  }
);
