import { Box, Paper, Stack, StackProps } from '@rossum/ui/material';

const DEFAULT_HEIGHT = 52;

type BasicTileProps = {
  children: React.ReactNode;
} & StackProps;

export const BasicTile = ({ children, ...props }: BasicTileProps) => {
  return (
    <Box
      sx={{
        color: 'inherit',
      }}
    >
      <Stack
        component={Paper}
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        minHeight={DEFAULT_HEIGHT}
        sx={{
          px: 2,
          py: 1,
          borderRadius: 1,
        }}
        {...props}
      >
        {children}
      </Stack>
    </Box>
  );
};
