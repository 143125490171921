import { ArrowDropDown, ArrowDropUp } from '@rossum/ui/icons';
import {
  FormControl,
  Menu,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { getOperatorLocalisationKey } from '../../../../document-list-base/mql/helpers';
import { FilterOperator } from '../../../../document-list-base/mql/operators';
import { OperatorsProps } from '../Operators';

const SELECT_WIDTH = 170;

type Props = OperatorsProps & {
  selectedOperators: Array<FilterOperator['value']>;
};

export const SelectOperators = ({
  operators,
  operator,
  onOperatorChange,
  selectedOperators,
  headerName,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const intl = useIntl();

  const label = intl.formatMessage({
    id: 'containers.filtering.labels.condition',
  });

  const areAllOperatorsSelected = operators.length === selectedOperators.length;

  const filteredOperators = operators.filter(
    op => !selectedOperators.includes(op.value)
  );

  const getOperatorValue = () => {
    const operatorKey = getOperatorLocalisationKey(operator);
    return operatorKey
      ? intl.formatMessage({
          id: `containers.filtering.operators.${operatorKey}`,
        })
      : null;
  };
  const isOpen = !!anchorEl;

  const resolvedArrowIcon = isOpen ? <ArrowDropUp /> : <ArrowDropDown />;

  return (
    <FormControl
      sx={{
        width: SELECT_WIDTH,
      }}
    >
      <TextField
        onClick={e => setAnchorEl(e.currentTarget)}
        disabled={areAllOperatorsSelected}
        variant="outlined"
        label={label}
        value={getOperatorValue()}
        type="button"
        sx={{
          cursor: 'pointer',
          '& fieldset.Mui-disabled': {
            borderColor: 'inherit',
          },
        }}
        InputProps={{
          endAdornment: areAllOperatorsSelected ? null : resolvedArrowIcon,
          inputProps: {
            sx: {
              lineHeight: 'normal',
              textAlign: 'left',
              '&.Mui-disabled': {
                WebkitTextFillColor: (t: Theme) => t.palette.text.primary,
              },
            },
          },
        }}
        size="small"
      />
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            elevation: 12,
          },
        }}
      >
        {filteredOperators.map(op => {
          const operatorKey = getOperatorLocalisationKey(op);
          return operatorKey ? (
            <MenuItem
              key={op.value}
              value={op.value}
              onClick={() => {
                setAnchorEl(null);
                onOperatorChange(op);
              }}
              sx={{ width: SELECT_WIDTH }}
              data-cy={`all-documents-operator-${op.value}-for-${headerName}`}
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: `containers.filtering.operators.${operatorKey}`,
                })}
              </Typography>
            </MenuItem>
          ) : null;
        })}
      </Menu>
    </FormControl>
  );
};
