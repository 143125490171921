import { tooltip } from './../../../../apps/elis/src/lib/formaterValues/index';
import { ThemeOptions } from '@mui/material';

// https://www.figma.com/file/AaBwZVqcfaoe476bCiLqmJ/Rossum-Design-System-%E2%80%94-Dark?node-id=6785%3A42814
export const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#1E6EE5',
      light: '#61A0FF',
      dark: '#0B5BD2',
    },
    secondary: {
      main: '#8D99B0',
      light: '#CAD1DA',
      dark: '#8895AC',
    },
    success: {
      main: '#10B40D',
      light: '#12D60F',
      dark: '#0E980B',
    },
    info: {
      main: '#0BACD0',
      light: '#12C9F3',
      dark: '#087C96',
    },
    warning: {
      main: '#FF7A0D',
      light: '#FF8F33',
      dark: '#DB6300',
    },
    error: {
      main: '#F33321',
      light: '#FB5646',
      dark: '#C61B0B',
    },
    aurora: {
      main: '#8811E3',
      light: '#EF9CFD',
      dark: '#720EBE',
      contrastText: '#FFFFFF',
    },
    action: {
      active: '#A6B1C1',
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(202, 209, 218, 0.14)',
      disabled: 'rgba(75, 89, 108, 0.8)',
      disabledBackground: 'rgba(75, 89, 108, 0.4)',
      focus: 'rgba(166, 177, 193, 0.5)',
    },
    other: {
      tooltip: '#435061',
    },
    divider: 'rgba(166, 177, 193, 0.12)',
    // TODO: Finally define surfaces behavior
    // there should be consistency between these two colors!
    background: {
      default: '#0D1117',
      // trial and error to roughly match elevations to our current shades
      // would be nice to also customise shadows in theme 'shadows' property
      paper: '#161D27',
    },
  },
};
