import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DeleteConfirmationDialog } from '../../../../ui/delete-confirmation-dialog/DeleteConfirmationDialog';

type DialogState = {
  key: 'deleteSchemaSection' | 'deleteSchemaField';
  onConfirm: () => void;
};

export const useConfirmationDialog = () => {
  const [dialogState, setDialogState] = useState<DialogState | null>(null);

  const intl = useIntl();

  const dialog = dialogState ? (
    <DeleteConfirmationDialog
      title={intl.formatMessage({
        id: `features.queueSettings.dialog.${dialogState.key}.title`,
      })}
      description={intl.formatMessage({
        id: `features.queueSettings.dialog.${dialogState.key}.text`,
      })}
      onCancel={() => setDialogState(null)}
      onConfirm={dialogState.onConfirm}
      open={!!dialogState}
    />
  ) : null;

  return { dialog, dialogState, setDialogState };
};
