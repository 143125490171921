/* eslint-disable no-console */
export const initCSPReportingObserver = (sentry: {
  captureMessage: (message: string, captureContext?: unknown) => void;
}) => {
  if (!('ReportingObserver' in window)) {
    return undefined;
  }

  const handleCSPViolation = (report: Report) => {
    sentry.captureMessage('CSP Violation Detected', {
      extra: { report },
    });
  };

  return new ReportingObserver(
    reports => {
      reports.forEach(report => {
        if (report.type === 'csp-violation') {
          handleCSPViolation(report);
        }
      });
    },
    { types: ['csp-violation'], buffered: true }
  );
};
