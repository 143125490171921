import { Components, Theme } from '../../material';

export const muiDialog = (_theme: Theme): Components['MuiDialog'] => ({
  defaultProps: {
    PaperProps: {
      elevation: 2,
    },
  },
  styleOverrides: {},
});
