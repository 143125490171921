import { Constraints } from '../../../../types/schema';

export const isFieldRequired = (
  constraints: Constraints | undefined,
  type: string | undefined
) => {
  if (type === 'button') {
    return false;
  }

  return constraints?.required ?? true;
};
