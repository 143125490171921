import { endpoints } from '@rossum/api-client';
import {
  SuggestTriggerConditionResponse,
  SuggestTriggerConditionResult,
} from '@rossum/api-client/internal';
import { SchemaRule, SchemaSection } from '@rossum/api-client/schemas';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { throwError } from '../../../redux/modules/messages/actions';

export const narrowTriggerConditionSuggestionResultToTemplate = (
  data: SuggestTriggerConditionResponse
): SuggestTriggerConditionResult[] =>
  data.results.map(r => ({
    triggerCondition: r.triggerCondition ?? '',
    triggerConditionSummary: r.triggerConditionSummary ?? '',
  }));

export const useSuggestTriggerCondition = () => {
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: ({
      search,
      schemaRule,
      schemaContent,
    }: {
      search: string;
      schemaContent: SchemaSection[];
      schemaRule: Partial<SchemaRule>;
    }) =>
      api.request(
        endpoints.internal.rules.suggestTriggerCondition({
          userQuery: search,
          schemaContent,
          schemaRule,
        })
      ),
    onError: () => {
      dispatch(throwError('clientError'));
    },
  });

  return mutation;
};
