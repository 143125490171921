import { lighten, SxProps, Theme } from '@rossum/ui/material';
import {
  fieldHighlightMatchToken,
  functionHighlightMatchToken,
} from './constants';

export const formulaEditorStyles: SxProps<Theme> = {
  // default overrides
  '.ace_placeholder': {
    transform: 'scale(1)',
    paddingLeft: '0 !important',
  },

  // custom highlights
  [`
    .ace_${fieldHighlightMatchToken},
  `]: {
    color: 'info.main',
  },
  [`.ace_${functionHighlightMatchToken}`]: { color: 'turquoise' },

  // field styling
  border: t => `1px solid ${t.palette.other.muted}`,
  borderRadius: 2,
  overflow: 'hidden',

  // gutters styles if gutters enabled
  '.ace_gutter': {
    backgroundColor: 'transparent',
    '.ace_gutter-cell': {
      color: t => `${t.palette.text.disabled}`,
    },
  },
};

// since autocomplet component is rendered outside of the component tree
// we need to overwrite ace editor classnames globally
export const getGlobalStylesOverrides = (theme: Theme) => ({
  '.ace_dark.ace_editor.ace_autocomplete': {
    '.ace_marker-layer .ace_active-line': {
      backgroundColor: theme.palette.action.hover,
    },
    '.ace_line-hover': {
      // there is a bug because ACE editor stacks multiple line-hover elements
      // over each other and using semi-transparent color results in multiply effect,
      // thus using calculated opaque color
      background: lighten(
        theme.palette.background.paper,
        theme.palette.action.selectedOpacity
      ),
      borderColor: theme.palette.action.selected,
    },
    '.ace_completion-highlight': {
      color: theme.palette.secondary.main,
    },
  },
});
