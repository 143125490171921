import { ID } from '../../utils/codecUtils';
import { listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Organization } from '../models/organization';
import { organizationSchema } from '../models/organization.schema';

export const list = () => {
  return {
    endpoint: `/organizations`,
    method: 'GET',
    responseSchema: listResponse(organizationSchema),
  } as const;
};
