import { endpoints } from '@rossum/api-client';
import { Schema } from '@rossum/api-client/schemas';
import { useQuery } from '@tanstack/react-query';
import * as R from 'remeda';
import { z } from 'zod';
import { api } from '../../../lib/apiClient';

const conditionErrorSchema = z.object({
  content: z.array(
    z.object({
      children: z.object({
        0: z.object({
          formula: z.array(z.string()),
        }),
      }),
    })
  ),
});

const createFakeSchemaWithCondition = (formula: string): Partial<Schema> => ({
  content: [
    {
      id: 'rule_section',
      category: 'section',
      label: 'rule section',
      children: [
        {
          id: `condition`,
          category: 'datapoint',
          label: 'Formula condition',
          type: 'string',
          formula,
          uiConfiguration: { type: 'formula', edit: 'disabled' },
        },
      ],
    },
  ],
});

const VALIDATE_SCHEMA_KEY = 'validate-condition-schema';

export const useValidateTrigger = (condition?: string) => {
  const payload = condition
    ? createFakeSchemaWithCondition(condition)
    : undefined;

  return useQuery(
    [VALIDATE_SCHEMA_KEY, payload],
    () => {
      if (!payload) return null;

      return api.request(endpoints.schemas.validate(payload));
    },
    {
      enabled: !!payload,
      keepPreviousData: true,
      select: response => {
        if (R.isDeepEqual(response, {})) {
          return null;
        }
        const parsed = conditionErrorSchema.safeParse(response);

        const errorMessage = parsed.success
          ? parsed.data.content[0]?.children[0].formula[0]
          : null;

        return errorMessage;
      },
      onError: () => null,
    }
  );
};
