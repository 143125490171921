import { DecodingError, HttpError } from '@rossum/api-client/errors';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { AjaxError } from 'rxjs/ajax';
import { currentCodeVersion } from '../constants/config';

const getApiErrorStatusCode = (error: unknown) => {
  if (error instanceof AxiosError) {
    return error.response?.status ?? null;
  }

  if (error instanceof AjaxError) {
    return error.status;
  }

  if (error instanceof HttpError) {
    return error.code;
  }

  return null;
};

// Return request URL or at least endpoint in case of HttpError
const getApiErrorUrl = (error: unknown) => {
  if (error instanceof AxiosError) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return error.request?.responseUrl ?? null;
  }

  if (error instanceof AjaxError) {
    return error.request.url ?? null;
  }

  if (error instanceof HttpError) {
    return error.endpoint ?? null;
  }

  return null;
};

const ignoredEndpointPatterns = [/\/auth\//, /\/oauth\//];

export const isIgnoredApiEndpoint = (requestUrl: string | undefined) => {
  if (!requestUrl) {
    return false;
  }

  return ignoredEndpointPatterns.some(pattern => pattern.test(requestUrl));
};

// TODO: Slowly expand these? For now only log 400 and 500 errors
const loggedApiErrorCodes = [400, 500];

export const isIgnoredApiError = (error: unknown) => {
  // log all decoding errors
  if (error instanceof DecodingError) {
    return false;
  }

  // for API errors, only whitelisted errors are reported
  if (
    error instanceof AjaxError ||
    error instanceof HttpError ||
    error instanceof AxiosError
  ) {
    const code = getApiErrorStatusCode(error);

    // just in case, see what happens
    if (code === null) {
      return false;
    }

    return (
      // whitelisting error codes
      !loggedApiErrorCodes.includes(code) ||
      // not logging 400s for auth and oauth
      (code === 400 && isIgnoredApiEndpoint(getApiErrorUrl(error)))
    );
  }

  // other errors with no special handling get reported
  return false;
};

export const initSentry = () => {
  Sentry.init({
    enabled: window.env.REACT_APP_SENTRY_ENABLED,
    normalizeDepth: 6,
    dsn: window.env.REACT_APP_SENTRY_DSN ?? undefined,
    environment: window.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'unknown',
    release: currentCodeVersion,
    integrations: [Sentry.extraErrorDataIntegration()],
    // Breadcrumbs are events captured in an event trail, like UI clicks, XHR etc.
    beforeBreadcrumb: (breadcrumb, hint) => {
      // For logged API errors, try logging payloads and responses
      // There are limits to how much Sentry can ingest so with large payloads this will still be useless, can we think of a better way?
      if (
        breadcrumb.category === 'xhr' &&
        loggedApiErrorCodes.includes(breadcrumb.data?.status_code) &&
        !isIgnoredApiEndpoint(breadcrumb.data?.url)
      ) {
        const data = {
          // To make sure response is logged first in case payload is too large
          _response: {
            response: hint?.xhr?.response,
            responseText: hint?.xhr?.responseText,
          },
          payload: hint?.input,
        };

        return { ...breadcrumb, data };
      }

      return breadcrumb;
    },
    // TODO: Can be used to clean data, ignore errors etc
    beforeSend: (event, hint) => {
      const { originalException } = hint;

      if (isIgnoredApiError(originalException)) {
        return null;
      }

      // Add smartlook playUrl, can this be done more elegantly?
      if (window.smartlook) {
        return {
          ...event,
          contexts: {
            ...event.contexts,
            smartlook: { playUrl: window.smartlook?.playUrl },
          },
        };
      }

      return event;
    },
    ignoreErrors: [
      // https://trackjs.com/javascript-errors/object-not-found-matching-id-methodname-paramcount/
      /Object Not Found Matching Id/,
    ],
  });

  return Sentry;
};
